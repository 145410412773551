import React from 'react';
import { Background, Container, ContentContainer, Subtitle, Title, OrangeLink, Paragraph, Speaker, OrangeLinkInline } from '../DeepDive.styles.js';
import Header from '../../../Header';

const RustShuttleJul23 = () => {
    return <Background>
            <Header showFiltraConfig={false}/> 
            <Container>
                <ContentContainer>
                    <Title>Ivan Cernja: Shuttle</Title>
                    <Subtitle>The following is the transcript from my interview with Ivan Cernja, senior developer relations engineer at Shuttle. These interviews, along with the Rust Jobs Report, are part of our effort to educate about the state of the Rust job market. As always, you can check out our extensive list of rust jobs by setting up your profile on <OrangeLinkInline to={'/'}>filtra</OrangeLinkInline>. I really enjoyed this far-ranging conversation with Ivan, which covered topics like breaking into software engineering from a nontraditional background, working in devrel, Shuttle's batch learning program for Rust, and much more. I hope you'll enjoy the read! - Drew</Subtitle>
                    <Paragraph><Speaker>Drew: </Speaker>Hi Ivan, I’m excited to be talking to you today! There are a couple of things I’m especially excited to talk to you about, like the YCombinator style program you guys are putting on for Rust engineers. I’m also eager to hear about what you guys are doing with Web Assembly these days. But, before we get into any of that, I want to help our audience get a baseline understanding of what you and Shuttle are all about. So, let’s start with you. How did you get involved with Shuttle personally?</Paragraph>
                    <Paragraph><Speaker>Ivan: </Speaker>That’s always an interesting story. I started in tech about nine years ago in high school doing websites for local businesses, because I come from a region that’s very touristy where you’re either working as a waiter or you’re not working at all. I started doing websites for hairdressers and then moved on to other local businesses. Around the age of eighteen or nineteen, I got the opportunity to work as a junior project manager for an agency that worked with huge clients like Procter and Gamble and GSK. The next couple of years, I continued working for other agencies. I worked with a lot of cool projects, mostly doing mobile and web apps for companies like Marriott, Riley Publishing, etc.</Paragraph>
                    <Paragraph><Speaker>Ivan: </Speaker>Now for the juicier part. I got tired of agency work, so I moved into freelance product/project management. I did that for two years. That whole time, I always programmed on the side, mostly mobile stuff with Flutter. At some point, I decided I wasn’t a fan of what I was doing. Consultancy work is great, but I was never attached to a product. It was like being on an assembly line. I didn’t have any passion for it. So, that’s when I started to change things up a bit. I learned Rust, I learned about Shuttle, and I started contributing to Shuttle. The language was cool, because it doesn’t let you write bad code. The core team and the community around Shuttle was so fun. This all felt like an awakening of some sort. So, here’s the kicker. I decided to make a slide deck, I think it was ten pages, where I pitched myself to Shuttle, because I come from a bit different background than the typical DevRel. I made graphs about why it made sense, what I could bring to the table, and where we could get in a couple of months. We started talking, and I got to know the team. We all felt like it was a good fit. And, I’m still here. It was a good gamble, and it’s paid off. It’s always good to leave your comfort zone and try something new, especially while you’re young.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>I’m glad you included all that detail. There’s so much there. Has the Shuttle codebase always been open source?</Paragraph>
                    <Paragraph><Speaker>Ivan: </Speaker>Yeah, the codebase has been open source from the very beginning.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>What you shared about getting the job at Shuttle was interesting, because there are a lot of other people out there like you who come from a nontraditional background and are trying to break into engineering. And, Rust seems like a language that is especially hard to do that with. So, it was cool how you walked through everything you did to convince Shuttle to take you on.</Paragraph>
                    <Paragraph><Speaker>Ivan: </Speaker>Yes, and DevRel is a hard to define role. Especially since it’s so new. You’ll find big differences in the job descriptions for DevRel roles at different companies. Hiring for DevRel always includes a tradeoff between technical skills and communication skills. So, it’s important to find someone that can do both aspects of the role.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>I want to understand more about Shuttle. What’s the problem that Shuttle tries to solve and how does it address that problem?</Paragraph>
                    <Paragraph><Speaker>Ivan: </Speaker>There are really two problems we solve. One is the ease of setting up infrastructure. And, the other is providing Rust users specifically with an easy option for deploying their apps. Ultimately, the goal is to supercharge developer productivity.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>You guys have this concept of Infrastructure From Code. Can you describe what that is and maybe how it’s different from Infrastructure As Code?</Paragraph>
                    <Paragraph><Speaker>Ivan: </Speaker>So, there’s Infrastructure From Code and there’s Infrastructure As Code. When you’re doing Infrastructure As Code, you’re creating separate YAML files, or something similar, and defining all of your infrastructure there. We want to make things even simpler. With Infrastructure From Code, you don’t ever have to leave your main file to develop your project. You can ask for any infrastructure you need straight from your main file by passing an argument to the function. For example, you just declare in your function that you need a database, and you get a database. This is valuable for people who might not have experience provisioning infrastructure or don’t want to figure out using the AWS Console. Especially for people who are getting started, it’s cool to just add one annotation to your code and get exactly what you need to move forward.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>This is such an elegant way to abstract over the complexities of infrastructure. I imagine Shuttle doesn’t work with every possible piece of cloud infrastructure right now, but what does the road map look like for supporting more components going forward?</Paragraph>
                    <Paragraph><Speaker>Ivan: </Speaker>So, we are planning on going into beta really soon. As part of the beta, we’ll also be releasing a roadmap for moving forward. One of the items on the roadmap is supporting GCP and other cloud providers. The roadmap will have a lot of detail about these kinds of things, and when we release it we’ll be looking for a lot of user feedback. Many times in the past, we’ve been sure that a certain feature was going to be a banger, and then through gathering feedback we learned that wasn’t the case. So, a detailed roadmap is coming.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>So, why is Rust the language that Shuttle is starting with?</Paragraph>
                    <Paragraph><Speaker>Ivan: </Speaker>This was before my arrival, but the story goes along the lines of, “How can we make things much easier? How can we make it so that you basically don’t have to think about infrastructure?” I think at one point it was Rust vs. Go in the beginning. And, that’s when Rust macros came into play. This allowed us to create this Infrastructure From Code concept where there’s just a macro which then produces all the necessary code to get your infrastructure provisioned. So, Rust was a natural fit from that perspective and continues to be a good fit. And, the fact that Rust has been the most loved language for eight years in a row now helps the cause quite a bit. Then, there’s also just the things that make Rust great in general- safety, performance, etc.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>Is the stack all Rust or are there some other languages mixed in there at all?</Paragraph>
                    <Paragraph><Speaker>Ivan: </Speaker>I think if you open up our repo it says 97.7% Rust. We’re pretty much full-on Rust. And, I would assume that the other 2.3% of that repo is scripts and markdown files.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>Shuttle is unique in that it’s a product that is developer facing. What has it been like working on something that is for other developers?</Paragraph>
                    <Paragraph><Speaker>Ivan: </Speaker>Yeah, it’s definitely different, and this is something that has taken time to get used to. It’s more exciting for me to work on a developer-facing product, because the feedback loop is very different. With developers, you don’t need to do much work fishing out feedback. If there’s something you’re doing wrong, someone will tell you you’re doing it wrong. You can count on that. And, they will not spare any words. I especially enjoy building out the community around the product. It’s awesome to see people working together, giving feedback, and proposing features. When you get to the point where you and the users understand each other, it becomes very rewarding.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>Do you find that there are a lot of users who end up contributing something back to the codebase that they felt was missing?</Paragraph>
                    <Paragraph><Speaker>Ivan: </Speaker>Lately, we’ve been having an influx of people who want to contribute. It’s become really common to hear someone say, “Hey, I’m missing a connector for this database” or something like that, and then two days later there’s a PR for that connector ready on GitHub. We really appreciate those people. And, we’ve also tried to make sure that it is really easy to add new resource support to Shuttle. Usually it only takes a day or two to get a new integration coded up.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>One thing that our audience might be interested in would be to hear a bit about what it’s like to work at Shuttle. Are there things that you’ve really enjoyed about working there?</Paragraph>
                    <Paragraph><Speaker>Ivan: </Speaker>One thing that’s unique is that Shuttle is a startup. Where I came from in the agency world, there are a lot of procedures and processes. When I came here, I started doing my usual flow. But, I learned quickly that I was no longer working for a big company, and I needed to move fast and break things. There is no time to waste. That was a big wake up call. I have to throw things at the ceiling, see if they stick, and talk to users a lot. Noone expects you to nail everything on the first try, and as soon as you understand that things become easier. You’re no longer afraid to experiment. So, getting used to a lot of experiments was a big change for me. But, this gives a real sense of independence and accomplishment. Any time an experiment goes well it feels amazing, especially because this is our product, not something I’m contracted to do for someone else.</Paragraph>
                    <Paragraph><Speaker>Ivan: </Speaker>In terms of the team at Shuttle, it’s amazing. We are still a small team, but we are growing. We’re all remote, pretty much spread across Europe. We also have flexible work hours, which is something that can take a while to get used to. Flexible work hours are as good as you make them. You have to learn how to organize your day around flexible work hours, but as soon as you figure that out it becomes a blessing. Working with the other team members has been amazing. So, next Sunday, we’re all meeting up for the first time in London. We’re always joking that when you never see your colleagues you’re always guessing how tall someone is. So, it will be good to see how tall my coworkers are! We also put a lot of emphasis on culture fit. That makes it really easy to work with each other. I’ve never seen a team where new people onboard so quickly. We even share similar jokes and similar interests, like climbing, running, and cycling. I don’t like using the word family in a business setting, but it does sometimes really feel like a family. I could definitely spend a week with any of my coworkers and have a great time.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>I spent some time looking at the blog on the Shuttle website, and I saw a couple of new projects that seem really exciting. One of those is Shuttle Next. Can you give me a sense of what that is?</Paragraph>
                    <Paragraph><Speaker>Ivan: </Speaker>Shuttle Next is an experiment, and it’s very early-stage. The primary goal of Shuttle Next is to have a more batteries-included framework for backend development in Rust. And, there’s WASM involved there as well, because of the isolation that it offers. WASM modules have no notion of a file system, so the Shuttle Next project can only interact with the host system through resources explicitly declared. To give a sense of how early this is, we released like version 0.0.0.0.1 to get initial thoughts from our users. More development on Shuttle Next is going to happen in the coming months, because most of our efforts in the last couple of months have gone into making Shuttle more reliable and stable. As we go into beta, we want to host more production-level apps.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>I also wanted to make sure we talked about the Shuttle Batch program, because I think that would be really interesting to a lot of Rust engineers out there. Can you describe some of the history of that and what it will look like going forward?</Paragraph>
                    <Paragraph><Speaker>Ivan: </Speaker>Definitely. So, the batch was initially inspired by the YC batches. We wanted to create an environment where users could work together on projects that we define and get rewarded for doing it. It is an opportunity for people to get rewarded for their contributions and to learn. The learning part comes from the constant communication with our core team and a series of mentoring sessions with the core team. There’s also an element of competition there, because you get rewarded based on how much you get done. I think the biggest reward, and the one that our batch attendees love, is the opportunity to get considered for our paid open-source program, where you’re paid to be an open-source contributor.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>How does that program work?</Paragraph>
                    <Paragraph><Speaker>Ivan: </Speaker>It’s a part-time engagement. We sort out the details with the individual, but up until now it’s mostly served as a stepping-stone to becoming a full member of the Shuttle team. One of our engineers was hired this way from last year’s batch.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>Well, we did it! Thanks for taking the time Ivan.</Paragraph>
                    <Paragraph><Speaker>Ivan: </Speaker>No problem. I also wanted to share with your audience that we are creating the Launchpad Newsletter in collaboration with Stefan Baumgartner, who is the organizer of the biggest Rust meetup in Europe. So, this is an educational newsletter where, in every issue, you learn a different concept of Rust by building a real world app. For example, in today’s issue, we’re covering structs and enums, and by the end of the tutorial you have a deployed podcast app that lets you fetch podcasts and play them in your browser. So, we’re really excited about that.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>That is awesome. That sounds like a fun way to get up to speed with Rust, or even just to keep your skills sharp. Well, thanks again for taking the time to do this!</Paragraph>
                    <Paragraph><Speaker>Ivan: </Speaker>No problem!</Paragraph>
                </ContentContainer>
            </Container>
            <Container>
            <OrangeLink
                to={'/'} 
            >
                get rust jobs on filtra 
            </OrangeLink>
            </Container>
        </Background>
}

export default RustShuttleJul23 