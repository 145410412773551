import React, { useState, useEffect } from 'react';
import Header from '../Header';
import {
    Form,
    ButtonContainer,
    MedButton,
    BackgroundPlain,
} from '../../GlobalStyle';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../Loading';
import { ProfileImage, ProfileImageContainer } from './Profile.styles.js';
import ShortBasicInput from '../ShortBasicInput';
import api from '../../api';
import handleAPIError from '../../api/error';
import { useNavigate } from 'react-router-dom';
import PhotoDialog from '../PhotoDialog';
import SignupProgress from '../SignupProgess';

const Profile = () => {
    const navigate = useNavigate()
    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const id = user.sub.split('|')[1];
    const email = user.email;

    const PLACEHOLDER_IMAGE_URL = 'https://storage.googleapis.com/filtra-photos-prod/placeholder_image.png'
    const [isLoading, setIsLoading] = useState(false);
    const [showPhotoDialog, setShowPhotoDialog] = useState(false)
    const [exists, setExists] = useState(true);
    const [prefsExist, setPrefsExist] = useState(true);
    const [photoUrl, setPhotoUrl] = useState(PLACEHOLDER_IMAGE_URL);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [changedPhoto, setChangedPhoto] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const getPerson = async () => {
            setIsLoading(true);
            try {
                const claims = await getIdTokenClaims()
                if (claims['https://filtra.io/roles'].includes('Recruiter')) {
                    navigate('/recruiters/profile') 
                } else {
                    const token = await getAccessTokenSilently();
                    const response = await api.get(`/people/${id}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    setPhotoUrl(response.data.photoUrl);
                    setFirstName(response.data.firstName);
                    setLastName(response.data.lastName);
                }
            } catch (err) {
                if (err.response.data.status === 404) {
                    setExists(false);
                } else {
                    handleAPIError(err);
                }
            }
            setIsLoading(false);
        };
        getPerson();
        const getPreferences = async () => {
            setIsLoading(true);
            try {
                const token = await getAccessTokenSilently();
                await api.get(
                    `/people/${id}/filter/configuration`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
            } catch (err) {
                if (err.response.data.status === 404) {
                    setPrefsExist(false)
                } else {
                    handleAPIError(err);
                }
            }
            setIsLoading(false);
        };
        getPreferences();
    }, [id, getAccessTokenSilently, getIdTokenClaims, navigate]);

    useEffect(() => {
        // do nothing - just updates the photo without reloading config (which is outdated url)
        // wait for the user to save, if they don't the photo won't be updated (as expected)
    }, [changedPhoto]);

    const postPerson = async (body) => {
        setIsLoading(true);
        setErrors({});
        try {
            const token = await getAccessTokenSilently()
            await api.post('/people', body, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            setExists(true)
            navigate('/filterconfig')
        } catch (err) {
            const errorMsgs = handleAPIError(err);
            if (errorMsgs) setErrors(errorMsgs);
        }
        setIsLoading(false);
    };

    const patchPerson = async (body) => {
        setIsLoading(true);
        setErrors({});
        try {
            const token = await getAccessTokenSilently()
            await api.patch(`/people/${id}`, body, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        } catch (err) {
            const errorMsgs = handleAPIError(err);
            if (errorMsgs) setErrors(errorMsgs);
        }
        setIsLoading(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const person = { id, email, firstName, lastName, photoUrl };
        if (exists) {
            patchPerson(person);
        } else {
            postPerson(person);
        }
    };
    
    const updatePhotoUrl = (url) => {
        setPhotoUrl(url);
        setChangedPhoto(!changedPhoto);
        if (exists) {
            const person = { id, email, firstName, lastName, photoUrl: url };
            patchPerson(person)
        }
    };

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <BackgroundPlain>
                    <Header showFiltraConfig={exists} showJobs={prefsExist}/>
                    <Form onSubmit={handleSubmit}>
                        {exists ? null : 
                            <SignupProgress 
                                value={33} 
                                text={'page 2/3'}
                            />
                        }
                        <ProfileImageContainer
                            onClick={() => setShowPhotoDialog(true)} 
                        >
                            <ProfileImage src={photoUrl} key={photoUrl}/>
                        </ProfileImageContainer>
                        <ShortBasicInput
                            borderColor={'--orange'}
                            label={'first name'}
                            placeholder={'your first name'}
                            val={firstName}
                            onChange={setFirstName}
                            error={errors['firstName']}
                        />
                        <ShortBasicInput
                            borderColor={'--orange'}
                            label={'last name'}
                            placeholder={'your last name'}
                            val={lastName}
                            onChange={setLastName}
                            error={errors['lastName']}
                        />
                        <ShortBasicInput
                            borderColor={'--orange'}
                            type="email"
                            label={'email'}
                            placeholder={'your email'}
                            val={email}
                            readOnly={true}
                        />
                        <ButtonContainer>
                            <MedButton>save</MedButton>
                        </ButtonContainer>
                    </Form>
                </BackgroundPlain>
            )}
            {showPhotoDialog ? 
                <PhotoDialog 
                    closer={setShowPhotoDialog}
                    setPhotoUrl={updatePhotoUrl}
                    identifier={id}
                    placeholderPhotoUrl={PLACEHOLDER_IMAGE_URL}
                    photoUrl={photoUrl}
                    exists={exists}
                />
            : null}
        </>
    );
};

export default withAuthenticationRequired(Profile, {
    onRedirecting: () => <Loading />,
});
