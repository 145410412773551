import React, { useEffect, useState } from 'react';
import Header from '../Header';
import {
    BackgroundPlain,
} from '../../GlobalStyle';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import api from '../../api';
import Loading from '../Loading';
import { useNavigate } from 'react-router-dom';
import handleAPIError from '../../api/error';
import { ApplyLink, ColHeader, H2, Table, TableData } from './JobRecs.styles';

const JobRecs = () => {
    const navigate = useNavigate()
    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const id = user.sub.split('|')[1];
    const [isLoading, setIsLoading] = useState(false);
    const [jobList, setJobList] = useState([]);

    useEffect(() => {
        const getJobList = async () => {
            setIsLoading(true);
            try {
                const claims = await getIdTokenClaims()
                if (claims['https://filtra.io/roles'].includes('Recruiter')) {
                    navigate('/recruiters/profile') 
                } else {
                    const token = await getAccessTokenSilently();
                    const response = await api.get(`/people/${id}/job-recs`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    setJobList(response.data)
                }
            } catch (err) {
                handleAPIError(err);
            }
            setIsLoading(false);
        };
        getJobList();
    }, [id, getAccessTokenSilently, getIdTokenClaims, navigate]);

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <BackgroundPlain>
                    <Header showFiltraConfig={true} showJobs={true}/>
                    {jobList.length > 0 ?
                    <>
                        <Table>
                            <thead>
                                <tr>
                                    <ColHeader>job title</ColHeader>
                                    <ColHeader>company</ColHeader>
                                    <ColHeader>link</ColHeader>
                                </tr>
                            </thead>
                            <tbody>
                                {jobList.map((job, index) => <tr key={index}>
                                    <TableData>{ job.title.toLowerCase() }</TableData>
                                    <TableData>{ job.company.toLowerCase() }</TableData>
                                    <TableData>
                                        <ApplyLink target='_blank' href={job.href}>view</ApplyLink>
                                    </TableData>
                                </tr>)}
                            </tbody>
                        </Table>
                    </>
                    : <H2>There aren't any jobs matching your skill preferences at this time.</H2>}
                </BackgroundPlain>
            )}
        </>
    );
};

export default withAuthenticationRequired(JobRecs, {
    onRedirecting: () => <Loading />,
});
