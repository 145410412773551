import styled from 'styled-components';

export const LogoImage = styled.img`
    aspect-ratio: 1;
    object-fit: cover;
    display: block;
    width: 100%;
    margin-top: 0.05px;
`

export const LogoImageContainer = styled.div`
    border: var(--border);
    background-color: var(--black);
    margin: 0 0 1.5rem 0;
    cursor: pointer;
`