import React, { useState, useEffect } from 'react';
import { BackgroundPlain } from '../../GlobalStyle';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import Loading from '../Loading';
import RecruiterHeader from '../RecruiterHeader';
import { useNavigate } from 'react-router-dom';
import handleAPIError from '../../api/error';
import api from '../../api';
import { GridContainer, ResultsContainer } from '../SearchResultsTable/SearchResultsTable.styles';
import SearchResult from '../SearchResultsTable/SearchResult';

const RecruiterContacted = () => {
    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0()
    const recruiterId = user.sub.split('|')[1];
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [results, setResults] = useState({});

    useEffect(() => {
        const getResults = async () => {
            setIsLoading(true);
            try {
                const token = await getAccessTokenSilently();
                const response = await api.get(
                    `/recruiters/contacted/${recruiterId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setResults(response.data)
            } catch (err) {
                handleAPIError(err);
            }
            setIsLoading(false);
        }
        getResults();
    }, [recruiterId, getIdTokenClaims, getAccessTokenSilently, navigate]);

    useEffect(() => {
        const checkRole = async () => {
            setIsLoading(true);
            try {
                const claims = await getIdTokenClaims()
                if (!claims['https://filtra.io/roles'].includes('Recruiter')) {
                    navigate('/profile') 
                } 
            } catch (err) {
                handleAPIError(err);
            }
            setIsLoading(false);
        }
        checkRole();
    }, [getIdTokenClaims, navigate]);

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <BackgroundPlain>
                    <RecruiterHeader userExists={true}/>
                    <ResultsContainer>
                        <GridContainer>
                            {results?.people?.map(result => <SearchResult key={result.id} result={result} />)}
                        </GridContainer>
                    </ResultsContainer>
                </BackgroundPlain>
            )}
        </>
    );
};

export default withAuthenticationRequired(RecruiterContacted, {
    onRedirecting: () => <Loading />,
});
