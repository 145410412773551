import React from 'react';

import NumJobsPng from './numjobs.png';
import NumCompanies from './numcompanies.png';
import LargeHirers from './largehirers.png';
import OtherHirers from './otherhirers.png';
import LargeIndustries from './largeindustries.png';
import OtherIndustries from './otherindustries.png';

import RustReport from '../RustReport';


const RustReportNov22 = () => {
    return <RustReport 
        title={'Rust Jobs Report: November 2022'}
        subtitle={'Welcome to the November 2022 Rust Jobs Report. We maintain an index of companies hiring for Rust roles. Filtra has received an exciting level of support from the Rust community. As a thank you, we have elected to share this data publicly.'}
        titleNote={'To add your or another company to the index, please email filtra@filtra.io with the subject line "Rust Index"'}
        openPositionsPng={NumJobsPng}
        openPositionsDesc={"We found 124 available Rust positions in the month of November."}
        numCompaniesPng={NumCompanies}
        numCompaniesDesc={'The jobs we found this month were spread across 37 companies.'}
        largeHirersPng={LargeHirers}
        largeHirersDesc={"With behemoths like Amazon out of the way due to hiring freezes, smaller but still well-known Rust users like Mozilla and Cloudflare are leading the way."}
        topTenCompanies={[
            'Mozilla',
            'Cloudflare',
            'Heliax',
            'Materialize',
            'Discord',
            'Parity',
            'Deep Fence',
            'DeepSign GmbH',
            'Fly',
            'Solana',
        ]}
        otherHirersPng={OtherHirers}
        otherHirersDesc={"Many companies appear to be dabbling with Rust, currently offering just a few jobs."}
        topTenIndustriesPng={LargeIndustries}
        topTenIndustriesDesc={"Rust jobs tend to be concentrated in a few industries where the language's security and performance guarantees shine."}
        topTenIndustries={[
            'security',
            'cloud/infrastructure',
            'crypto',
            'browser',
            'messaging/notifications',
            'iot/robotics/automotive',
            'data science',
            'databases',
            'monitoring/reliability',
            'systems/hardware',
        ]}
        otherIndustriesPng={OtherIndustries}
        otherIndustriesDesc={'We expect Rust to extend into more industries such as martech and productivity as the ecosystem matures and tooling becomes more productive for mundane CRUD-type tasks.'}
    />
}

export default RustReportNov22 