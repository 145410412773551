import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background: var(--black);
    border: var(--smallBorder);
    padding: 1.5rem 0 0.5rem 0;
    margin: 0 0 1.5rem 0;
    align-items: flex-end;
`

export const Input = styled.input`
    height: 1.5em;
    aspect-ratio: 1;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    background: var(--white);
    margin: 0 0.5rem 0 0;
    font-size: 1em;
    font-weight: bold;
    &:before {
        content: " ";
        display: block;
        text-align: center;
        color: var(--black);
        font-family: 'Noto Sans Mono', monospace;
    }
    &:checked {
        &:before {
            content: "x";
        }
    };
    &:hover {
        background: var(--grey-500);
        &:before {
            color: var(--white);
        }
    }
    &:focus {
        background: var(--grey-500);
        &:before {
            color: var(--white);
        }
    }
`

export const Label = styled.label`
    color: var(--white);
    font-family: 'Noto Sans';
    font-weight: 500;
    font-size: 1em;
    margin: 0.25em 1em 0.25em 0.25em;
`

export const LabelError = styled.div.attrs(props => ({
    fontSize: props.fontSize || '0.8em',
}))`
    color: var(--orange);
    font-weight: 500;
    font-family: 'Noto Sans';
    font-size: ${props => props.fontSize};
    align-self: start;
    margin-left: 1em;
    margin-bottom: 1em;
`

export const OptionRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 1em 1em 0;
    width: 90%;
`
