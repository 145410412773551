import React from 'react';

import NumJobsPng from './numjobs.png';
import NumCompanies from './numcompanies.png';
import LargeHirers from './largehirers.png';
import OtherHirers from './otherhirers.png';
import LargeIndustries from './largeindustries.png';
import OtherIndustries from './otherindustries.png';
import Levels from './levels.png';

import RustReport from '../RustReport';


const RustReportMar23 = () => {
    return <RustReport 
        title={'Rust Jobs Report: March 2023'}
        subtitle={'Welcome to the March 2023 Rust Jobs Report. We maintain an index of companies hiring for Rust roles. Filtra has received an exciting level of support from the Rust community. As a thank you, we have elected to share this data publicly.'}
        titleNote={'To add your or another company to the index, please email filtra@filtra.io with the subject line "Rust Index"'}
        openPositionsPng={NumJobsPng}
        openPositionsDesc={"Thanks to community participation, we were able to slightly increase the dataset this month."}
        numCompaniesPng={NumCompanies}
        numCompaniesDesc={'The increase in the dataset is mostly due to the addition of 17 new hiring companies to the index.'}
        largeHirersPng={LargeHirers}
        largeHirersDesc={"While Cloudflare continues to be the largest hirer, they significantly reduced the number of openings in March."}
        topTenCompanies={[
            'Cloudflare',
            'Ditto',
            'Embark',
            'Crypto.com',
            'DataDog',
            'Fly',
            'Fortanix',
            'Syndica',
            'Heliax',
            'Workato',
        ]}
        otherHirersPng={OtherHirers}
        otherHirersDesc={"The list of other hirers continues to grow. If you don't see your company here, let us know at filtra@filtra.io."}
        topTenIndustriesPng={LargeIndustries}
        topTenIndustriesDesc={"With Cloudflare's reduced hiring, the crypto category ascended to the number one spot this month."}
        topTenIndustries={[
            'crypto',
            'cloud/infrastructure',
            'security',
            'monitoring/reliability',
            'data science',
            'gaming',
            'fintech',
            'systems/hardware',
            'messaging/notifications',
            'productivity',
        ]}
        otherIndustriesPng={OtherIndustries}
        otherIndustriesDesc={''}
        seniorityPng={Levels}
        seniorityDesc={"While opportunities abound at the mid and senior levels, junior positions are few and far between."}
    />
}

export default RustReportMar23 