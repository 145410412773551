import styled from 'styled-components';

export const Separator = styled.div`
    display: none;
    height: 3px;
    background-color: var(${(props) => props.borderColor}); 
`

export const Input = styled.input`
    color: var(--black);
    background-color: var(--white);
    font-family: 'Noto Sans';
    font-weight: 600;
    font-size: 1em;
    padding: 0.25em;
    outline: 0;
    border: 0;
    margin: 0;
    flex-grow: 1;
    &:focus {
        color: var(--white);
        background-color: var(--grey-600);
        font-weight: 500;
        ::placeholder {
            color: var(--grey-200);
        }
    }
    &:hover {
        ${({ readOnly }) => !readOnly && `
            font-weight: 500;
            color: var(--white);
            background-color: var(--grey-600);
            ::placeholder {
                color: var(--grey-200);
            }
        `}
    }
`

export const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`

export const Label = styled.label`
    color: var(--white);
    font-weight: 500;
    font-family: 'Noto Sans';
    font-size: 1em;
    margin: 0.25em;
`

export const LabelError = styled.div.attrs(props => ({
    fontSize: props.fontSize || '0.8em',
}))`
    color: var(--orange);
    font-weight: 500;
    font-family: 'Noto Sans';
    font-size: ${props => props.fontSize};
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background: var(--black);
    border: var(--medBorder);
    margin: 0 0 1.25rem 0;
    padding: 1px;
`
