import styled from 'styled-components';

export const ColHeader = styled.th`
    color: var(--white);
    background-color: var(--black);
    padding: 0.5rem;
    font-family: 'Noto Sans';
    font-size: 1em;
    font-weight: 600;
    text-align: left;
`

export const TableData = styled.td`
    color: var(--black);
    font-family: 'Noto Sans';
    font-weight: 600;
    border: var(--smallBorder);
    padding: 0.5rem;
    background-color: var(--white);
`

export const ApplyLink = styled.a`
    color: var(--green);
    &:visited {
        color: var(--grey-300);
    }
`

export const Table = styled.table`
    border-spacing: 0.5rem 1rem;
    margin: 5rem 0 3rem 0;
`

export const H2 = styled.h2`
  color: var(--black);
  font-family: 'Noto Sans';
  font-size: 1em;
  font-weight: 600;
  font-weight: normal;
  margin: 5rem 1rem 0 1rem;
  hyphens: auto;
  text-align: left;
`