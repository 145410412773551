import styled from "styled-components"
import { Link } from 'react-router-dom';

export const ResultsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 80vw;
    min-width: 350px;
    @media (min-width: 600px) {
        width: 60vw;
    }
    @media (min-width: 1000px) {
        width: 25vw;
    }
        margin: 7rem 0 3rem 0;
`

export const PageButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const ContactLink = styled(Link)`
    align-self: flex-end;
    color: var(--orange);
    font-family: 'Noto Sans Mono', monospace; 
    font-size: 1.25em;
    margin: 0.5em 0.5em 0.5em 0.5em;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`

export const Caret = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.65rem;
    height: 1.25em;
` 

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
`

export const ResultName = styled.div`
    position: relative;
    border: var(--medBorder);
    padding: 0.5rem;
    background-color: var(--white);
    color: var(--black);
    /* font-family: 'Noto Sans Mono', monospace; */
    font-family: 'Noto Sans';
    font-size: 1.25em;
    font-weight: 600;
    grid-column: span 2;
    cursor: pointer;
`

export const ResultInfo = styled.div`
    display: flex;
    flex-direction: column;
    border: var(--medBorder);
    padding: 0.5rem;
    background-color: var(--white);
    color: var(--black);
    /* font-family: 'Noto Sans Mono', monospace; */
    font-family: 'Noto Sans';
    font-size: 1em;
    font-weight: 600;
    grid-column: span 2;
`

export const ResultSimilarity = styled.span`
    position: absolute;
    right: 45px;
`
