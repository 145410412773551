import React from 'react';

import RustReportV2Ad from '../RustReportV2Ad';

const RustReportDec23 = () => {
    const NUM_JOBS = 617 
    const NUM_COMPANIES = 91
    const LARGE_HIRERS = [
        ['Amazon', 91],
        ['Microsoft', 68],
        ['Cloudflare', 55],
        ['Apple', 40],
        ['SpaceX', 35],
        ['DataDog', 30],
        ['Fortanix', 20],
        ['Canonical', 19],
        ['IBM', 18],
        ['Meta', 16],
    ]
    const OTHER_HIRERS = [
        ['Crypto.com', 12],
        ['Syndica', 9],
        ['Embark', 9],
        ['Worldcoin', 8],
        ['Qdrant', 7],
        ['Matter Labs', 7],
        ['Figma', 6],
        ['Discord', 5],
        ['KeyRock', 5],
        ['Stockly', 5],
        ['Volume Finance', 5],
        ['HelsingAI', 5],
        ['Deliveroo', 5],
        ['Ardan Labs', 5],
        ['Akamai', 4],
        ['OneSignal', 4],
        ['Atlassian', 4],
        ['Matic', 4],
        ['Zama', 4],
        ['Okta', 4],
        ['Google ', 3],
        ['HealPay', 3],
        ['Replit', 3],
        ['Smarkets', 3],
        ['Liquid Analytics', 3],
        ['PingCAP', 3],
        ['InfinyOn', 3],
        ['OVH', 3],
        ['1Password', 3],
        ['Deep Fence', 3],
        ['Trivago', 3],
        ['Gamaspace', 3],
        ['Sentry', 3],
        ['Rapid7', 3],
        ['SmartThings', 3],
        ['HyperExponential', 3],
        ['Railway', 2],
        ['IOTA Foundation', 2],
        ['Galois', 2],
        ['Zoo', 2],
        ['OpenAI', 2],
        ['Qovery', 2],
        ['arm', 2],
        ['Svix', 2],
        ['Scaleway', 2],
        ['Starry', 2],
        ['Star Lab', 2],
        ['Ledger', 2],
        ['Toyota Connected', 2],
        ['Ditto', 2],
        ['Samsung', 2],
        ['Vercel ', 1],
        ['Mozilla', 1],
        ['Turso', 1],
        ['Chorus One', 1],
        ['EdgeDB', 1],
        ['Clever Cloud', 1],
        ['Open Cosmos', 1],
        ['FP Complete', 1],
        ['TrueLayer', 1],
        ['AppSignal', 1],
        ['Qwiet AI', 1],
        ['Data Direct Networks', 1],
        ['Meilisearch', 1],
        ['Aleph Alpha', 1],
        ['Nuclia', 1],
        ['10X Genomics', 1],
        ['Signal', 1],
        ['shuttle', 1],
        ['KION', 1],
        ['Astropad', 1],
        ['Activision', 1],
        ['Threema', 1],
        ['Heliax', 1],
        ['Tabnine', 1],
        ['UXStream', 1],
        ['WalletConnect', 1],
        ['Lit Protocol', 1],
        ['Grafbase', 1],
        ['DSCVR', 1],
        ['Tonari', 1],
    ]
    const LARGE_INDUSTRIES = [
        ['cloud/infrastructure', 166],
        ['productivity', 80],
        ['systems/hardware', 64],
        ['crypto', 54],
        ['security', 45],
        ['aerospace', 39],
        ['monitoring/reliability', 34],
        ['consultancy', 26],
        ['data science', 25],
        ['social media', 16],
    ]
    const OTHER_INDUSTRIES = [
        ['marketplace', 13],
        ['messaging/notifications', 11],
        ['iot/robotics/automotive', 10],
        ['gaming', 10],
        ['fintech', 10],
        ['databases', 5],
        ['browser', 4],
        ['animation', 2],
        ['networking', 2],
        ['health/biotech', 1],
    ]
    const LEVELS = [
        ['junior', 28],
        ['mid', 325],
        ['senior', 264],
    ]
    return <RustReportV2Ad
        title={'Rust Jobs Report: December 2023'}
        subtitle={'Welcome to the December 2023 Rust Jobs Report by filtra. Please enjoy our overview of the Rust job market.'}
        titleNote={'To add your or another company to the filtra Rust jobs index, please email filtra@filtra.io with the subject line "Rust Index"'}
        openPositions={NUM_JOBS}
        openPositionsDesc={'We can\'t keep up! The total number of positions leapt again in December to a record high of 617.'}
        numCompanies={NUM_COMPANIES}
        numCompaniesDesc={'The total number of hirers actually dropped slightly in December. So, the overall growth in hiring was more in the top ten than in the long tail of small hirers.'}
        largeHirers={LARGE_HIRERS}
        largeHirersDesc={'December saw growth in the number of job postings within the top ten. And, previously unlisted IBM came out of nowhere with 18 jobs!'}
        otherHirers={OTHER_HIRERS}
        otherHirersDesc={"Thanks to community submissions, the index of hirers continues to grow. If you know of a company that should be on this list, don't hesitate to point it out: filtra@filtra.io"}
        topTenIndustries={LARGE_INDUSTRIES}
        topTenIndustriesDesc={'The top ten industries remained relatively stable in December. Note the surprise appearance of the consultancy category due to IBM\'s large consulting business.'}
        otherIndustries={OTHER_INDUSTRIES}
        otherIndustriesDesc={'One thing that hasn\'t changed much in the Rust job market is the list of industries applying the technology. It would appear that the language has now made inroads in almost all applicable sectors.'}
        seniority={LEVELS}
        seniorityDesc={"The proportion of entry-level Rust jobs as a share of the total Rust job market hasn't change much. However, early-career engineers can find a silver lining in the fact that the market as a whole is growing. So, the absolute number of starter jobs is in-fact growing."}
    />
}

export default RustReportDec23 