import React from 'react';
import { Container, InfoText, Text, TextBar } from './SignupProgress.styles.js';

const SignupProgress = ({ value, text }) => {
    return <Container>
        <Text>almost done setting up!</Text>
        <progress
            value={value}
            max={100} 
        />
        <TextBar>
            <InfoText>{ text }</InfoText>
            <InfoText>{ value + '%' }</InfoText>
        </TextBar> 
    </Container>
}

export default SignupProgress;