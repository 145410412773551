import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { SmallButton } from '../../GlobalStyle';

const LogoutButton = () => {
  const { logout } = useAuth0();
  return (
    <SmallButton
      onClick={() =>
        logout({
          returnTo: window.location.origin,
        })
      }
    >
      logout 
    </SmallButton>
  );
};

export default LogoutButton;