import React, { useState, useEffect } from 'react';
import {
    BackgroundPlain, 
} from '../../GlobalStyle';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import Loading from '../Loading';
import RecruiterHeader from '../RecruiterHeader';
import { useNavigate } from 'react-router-dom';
import handleAPIError from '../../api/error';
import { AddCompanyBar, CompaniesContainer } from './Companies.styles';
import CompanyOverview from '../CompanyOverview';
import { v4 as uuidv4 } from 'uuid';
import api from '../../api';
import { CompanyContainer } from './Companies.styles';
import { DeleteButton } from './Companies.styles';

const Companies = () => {
    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0()
    const recruiterId = user.sub.split('|')[1];
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const [companies, setCompanies] = useState([])

    useEffect(() => {
        const getCompanies = async () => {
            setIsLoading(true);
            try {
                const claims = await getIdTokenClaims()
                if (!claims['https://filtra.io/roles'].includes('Recruiter')) {
                    navigate('/profile') 
                } else {
                    const token = await getAccessTokenSilently();
                    const response = await api.get(
                        `/recruiters/${recruiterId}/companies`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    setCompanies(response.data)
                }
            } catch (err) {
                handleAPIError(err);
            }
            setIsLoading(false);
        }
        getCompanies();
    }, [recruiterId, getIdTokenClaims, getAccessTokenSilently, navigate]);

    const deleteCompany = async (companyId) => {
        setIsLoading(true);
        const isSure = window.confirm(`You are about to permanently delete this company and all associated jobs. Click OK to confirm.`)
        if (isSure) {
            try {
                const token = await getAccessTokenSilently()
                await api.delete(`/recruiters/${recruiterId}/companies/${companyId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                let newCompanies = companies.filter(company => company.id !== companyId)
                setCompanies(newCompanies) 
            } catch (err) {
                handleAPIError(err);
            }
        }
        setIsLoading(false);
    };

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <BackgroundPlain>
                    <RecruiterHeader userExists={true}/>
                    <CompaniesContainer>
                        {companies.map((company) => 
                            <CompanyContainer key={company.id}>
                                <DeleteButton
                                onClick={() => {
                                    deleteCompany(company.id)
                                }}  
                                >x</DeleteButton>
                                <CompanyOverview company={company}/>
                            </CompanyContainer>

                        )}
                        <AddCompanyBar
                            onClick={() => {
                                const newCompanyId = uuidv4()
                                navigate(`/recruiters/companies/${newCompanyId}`)
                            }} 
                        >+</AddCompanyBar>
                    </CompaniesContainer>
                </BackgroundPlain>
            )}
        </>
    );
};

export default withAuthenticationRequired(Companies, {
    onRedirecting: () => <Loading />,
});
