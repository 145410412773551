import React, { useState } from 'react';
import { Caret, ContactLink, ResultInfo, ResultName, ResultSimilarity } from './SearchResultsTable.styles';
import DropDownCaret from '../../assets/expand-arrow.png';
import CollapseCaret from '../../assets/collapse-arrow.png';

const SearchResult = ({ result, showContactButton, companyId, jobId }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <ResultName
        onClick={() => setIsOpen(!isOpen)}
      >
        {(result.hasBeenContacted ? '*' : '')
          + result.firstName + ' ' + result.lastName
        }
        { result.similarity && !isNaN(result.similarity) &&
          <ResultSimilarity>
            {Number(result.similarity * 100).toFixed(0)}%
          </ResultSimilarity>
        }
        {isOpen
          ? <Caret src={CollapseCaret} />
          : <Caret src={DropDownCaret} />
        }
        <br/>
        <span style={{ fontSize: '0.75em' }}>
          {(result.contactedInfo?.company ? `${result.contactedInfo.company.name}` : '')
          + (result.contactedInfo?.job ? ` - ${result.contactedInfo.job.title}` : '')}
        </span>
      </ResultName>
      {isOpen ?
        <ResultInfo>
          Skills:
          {result.skills.map(skill => <span key={skill.title}>{'- ' + skill.title + ' - ' + skill.skillLevel}</span>)}
          <br></br>
          Preferences:
          <span>
            {result.workType.map(pref => '- ' + pref + ' ')}
          </span>
          <span>
            {result.workPlace.map(pref => '- ' + pref + ' ')}
          </span>
          <br></br>
          {(result.workPlace.includes('inPerson') || result.workPlace.includes('hybrid')) ?
            <>
              Locations:
              {result.locations.map(location => <span key={location.name}>{'- ' + location.name}</span>)}
            </>
            : null}
          <br></br>
          {result.hasBeenContacted || result.contactedInfo ? 
            <>
              *Contact History:
              <br/>
              {`- last contacted on ${(new Date(result.lastContactedAt)).toLocaleDateString()}`}
              <br/>
              {`- contacted ${result.numContacts} times`}
              <br/>
              {`- ${result.numContacts < 2 ? 2 - result.numContacts : 0} followups left`}
              {result.numContacts >= 2 ?
                <>
                </>
              : null}
            </>
            : null}
          { showContactButton && !result.hasBeenContacted ?
            <ContactLink to={`/filters/${result.id}${(companyId && jobId) ? `?c=${companyId}&j=${jobId}` : ''}`}>contact</ContactLink>
          : showContactButton && result.hasBeenContacted && (result.numContacts < 2) ?
            <ContactLink to={`/filters/${result.id}${(companyId && jobId) ? `?c=${companyId}&j=${jobId}` : ''}`}>followup</ContactLink>
          : null}
        </ResultInfo>
        : null}
    </>
  )
}

export default SearchResult;
