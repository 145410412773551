import { useEffect, useState } from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import {
    Span,
    Container,
    Input,
    RangeInput,
    DeleteButton,
    Ul,
    Li,
    InputContainer,
    OnBlurDiv,
    SpecialInputContainer,
} from './PanelDiscrete.styles';

const PanelDiscrete = ({
    index,
    val,
    vals,
    discreteRange,
    placeHolder,
    fieldNames,
    onDeletePanel,
    onChange,
    showRadius,
}) => {
    const [searchStr, setSearchStr] = useState(val[fieldNames[0]]);

    useEffect(() => {
        setSearchStr(val[fieldNames[0]])
    }, [val, fieldNames])

    const locationAlreadySelected = (location) => {
        let result = false 
        vals.forEach((locVal, idx) => {
            if ((location.lat === locVal.lat) && 
                (location.lng === locVal.lng) &&
                (index !== idx)) {
                result = true
            }
        })
        return result
    } 

    const handleSelect = async (value) => {
        try {
            const results = await geocodeByAddress(value)
            const latLng = await getLatLng(results[0])

            const name = results[0].formatted_address.toLowerCase()
            const lat = latLng.lat
            const lng = latLng.lng
            let obj = {};
            obj[fieldNames[0]] = name
            obj[fieldNames[1]] = val[fieldNames[1]];
            obj[fieldNames[2]] = Math.round(lat * 1e8) / 1e8
            obj[fieldNames[3]] = Math.round(lng * 1e8) / 1e8
            if (locationAlreadySelected(obj)) {
                window.alert('You have already selected this location.')
                vals.splice(index, 1)
                const newVals = vals.map((x) => x)
                onChange(newVals)
            } else {
                const newVals = vals.map((x) => x);
                newVals[index] = obj;
                onChange(newVals);
            }
        } catch (e) {
            alert('We had trouble processing this location. Please ensure you selected a city or choose a different, nearby location.')
        }
    }

    return (
        <Container>
            <DeleteButton
                type="button"
                onClick={() => {
                    onDeletePanel(index);
                }}
            >
                x
            </DeleteButton>
            <InputContainer>
                <OnBlurDiv
                    onBlur={() => {
                        setSearchStr(val[fieldNames[0]])
                    }}
                >
                <PlacesAutocomplete
                    value={searchStr}
                    onChange={setSearchStr}
                    onSelect={handleSelect}
                    searchOptions={{ 
                        types: ['(cities)'],
                    }}
                >
                    {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                    }) => (
                        <>
                            <SpecialInputContainer>
                                <Input
                                    type={'select'}
                                    placeholder={placeHolder[fieldNames[0]]}
                                    {...getInputProps()}
                                />
                            </SpecialInputContainer>
                            <>
                                {suggestions.length > 0 && (
                                    <Ul>
                                        {loading ? <Li>...loading</Li>: null}
                                        {suggestions.map((suggestion) => {
                                            return (
                                                <Li
                                                    key={suggestion.description}
                                                    {...getSuggestionItemProps(suggestion)}
                                                >
                                                    {suggestion.description}
                                                </Li>
                                            );
                                        })}
                                        <Li></Li>
                                    </Ul>
                                )}
                            </>
                        </>
                    )}
                </PlacesAutocomplete>
                </OnBlurDiv>
            </InputContainer>
            {showRadius ? 
                <>
                    <RangeInput
                        type="range"
                        min="0"
                        max={discreteRange}
                        step="5"
                        value={val[fieldNames[1]]}
                        onChange={({ target }) => {
                            let obj = {};
                            obj[fieldNames[0]] = val[fieldNames[0]];
                            obj[fieldNames[1]] = parseInt(target.value);
                            obj[fieldNames[2]] = val[fieldNames[2]] 
                            obj[fieldNames[3]] = val[fieldNames[3]] 
                            const newVals = vals.map((x) => x);
                            newVals[index] = obj;
                            onChange(newVals);
                        }}
                    />
                    <Span>{val[fieldNames[1]]} mi.</Span>
                </> 
            : null}
        </Container>
    );
};

export default PanelDiscrete;