import React, { useState } from 'react';
import api from '../../api';
import {
    Span,
    Container,
    Input,
    RangeInput,
    DeleteButton,
    Ul,
    Li,
    InputContainer,
} from './PanelEnum.styles';
import { useDetectClickOutside } from 'react-detect-click-outside';

const PanelEnum = ({
    val,
    index,
    vals,
    enumOptions,
    placeHolder,
    fieldNames,
    onDeletePanel,
    onChange,
    panelType,
}) => {
    const [suggestions, setSuggestions] = useState([]);
    const ref = useDetectClickOutside({ onTriggered: () => { 
        handleSelectSuggestion(0, suggestions[0])
    }});

    const getSkillByPrefix = async (string) => {
        if (string === '') return [];
        try {
            const res = await api.get(`/skills?string=${encodeURIComponent(string)}`);
            return res.data;
        } catch (err) {
            alert(err.response.data.detail);
        }
    };

    const getSuggestions = async (searchValue) => {
        if (panelType === 'skill') {
            const result = await getSkillByPrefix(searchValue);
            result.unshift({ title: searchValue }); // add typedin skill as first option
            setSuggestions(result);
        }
    };

    const skillSuggestionDoesNotExist = (skill) => {
        let result = true 
        suggestions.slice(1, suggestions.length).forEach(suggestion => {
            if (suggestion.title === skill.title) {
                result = false 
            }
        })
        return result
    }

    const skillAlreadySelected = (skill) => {
        let result = false 
        vals.forEach((skillVal, idx) => {
            if ((skill.title === skillVal.title) && 
                (index !== idx)) {
                result = true
            }
        })
        return result
    }

    const handleSelectSuggestion = (suggIndex, suggestion) => {
        let addSkill = true
        suggestion.title = suggestion.title.toLowerCase().trim()
        if (skillAlreadySelected(suggestion)) {
            window.alert(`You have already added ${suggestion.title}.`) 
            addSkill = false
        } else {
            if (suggIndex === 0 && skillSuggestionDoesNotExist(suggestion)) {
                addSkill = window.confirm(`Congrats! You're the first to add ${suggestion.title}. If you have not mispelled or used a synonym for an existing skill, click OK to continue.`)
            }
        }
        if (addSkill) {
            let obj = { ...val };
            obj[fieldNames[0]] = suggestion[fieldNames[0]];

            const newVals = vals.map((x) => x);
            newVals[index] = obj;
            onChange(newVals);

            setSuggestions([]); // clearSuggestions
        } else {
            vals.splice(index, 1)
            const newVals = vals.map((x) => x)
            onChange(newVals)
        }
    };

    return (
        <Container>
            <InputContainer>
                <DeleteButton
                    type="button"
                    onClick={() => {
                        onDeletePanel(index);
                    }}
                >
                    x
                </DeleteButton>
                <Input
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault()
                            handleSelectSuggestion(0, suggestions[0])
                        }
                    }}
                    value={val[fieldNames[0]]}
                    placeholder={placeHolder}
                    onChange={({ target }) => {
                        getSuggestions(target.value.toLowerCase());
                        let obj = {};
                        obj[fieldNames[0]] = target.value;
                        obj[fieldNames[1]] = val[fieldNames[1]];
                        const newVals = vals.map((x) => x);
                        newVals[index] = obj;
                        onChange(newVals);
                    }}
                />
                <div>
                    {suggestions.length > 0 && (
                        <Ul ref={ref}>
                            {suggestions.map((suggestion, index) => {
                                        return <Li
                                            style={{color: index === 0 ? '#828c80ff' : '#2f332eff'}}
                                            key={index}
                                            onClick={() =>
                                                handleSelectSuggestion(index, suggestion)
                                            }
                                        >
                                            {suggestion.title}
                                        </Li>
                            })}
                        </Ul>
                    )}
                </div>
            </InputContainer>
            <RangeInput
                type="range"
                min="0"
                max={enumOptions.length - 1}
                value={enumOptions.indexOf(val[fieldNames[1]])}
                onChange={({ target }) => {
                    let obj = {};
                    obj[fieldNames[0]] = val[fieldNames[0]];
                    obj[fieldNames[1]] = enumOptions[target.value];
                    const newVals = vals.map((x) => x);
                    newVals[index] = obj;
                    onChange(newVals);
                }}
            />
            <Span>{val[fieldNames[1]]}</Span>
        </Container>
    );
};

export default PanelEnum;
