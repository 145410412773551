import React from 'react';

import NumJobsPng from './numjobs.png';
import NumCompanies from './numcompanies.png';
import LargeHirers from './largehirers.png';
import OtherHirers from './otherhirers.png';
import LargeIndustries from './largeindustries.png';
import OtherIndustries from './otherindustries.png';
import Levels from './levels.png';

import RustReport from '../RustReport';

const RustReportSep23 = () => {
    return <RustReport 
        title={'Rust Jobs Report: September 2023'}
        subtitle={'Welcome to the September 2023 Rust Jobs Report. We maintain an index of companies hiring for Rust roles. Filtra has received an exciting level of support from the Rust community. As a thank you, we have elected to share this data publicly.'}
        titleNote={'To add your or another company to the index, please email filtra@filtra.io with the subject line "Rust Index"'}
        openPositionsPng={NumJobsPng}
        openPositionsDesc={'The number of jobs popped in September, up from 363 in August to 462 in September. Maybe HR is back from their summer vacations?'}
        numCompaniesPng={NumCompanies}
        numCompaniesDesc={'The number of hirers stayed almost exactly the same, only going from 80 to 81 month over month. However, regular readers will notice that the companies that are hiring have shuffled around quite a bit.'}
        largeHirersPng={LargeHirers}
        largeHirersDesc={'Microsoft marched its way all the way up to the third spot on the leader board, posting a huge number of Rust-related jobs in September.'}
        topTenCompanies={[
            'Amazon',
            'Cloudflare',
            'Microsoft',
            'DataDog',
            'SpaceX',
            'Meta',
            'Apple',
            'Crypto.com',
            'Fortanix',
            'Google',
        ]}
        otherHirersPng={OtherHirers}
        otherHirersDesc={"Thanks to community submissions, the index of hirers continues to grow. Newcomers this month include, yes, THAT OpenAI, as well as Scanner, Arkeus, HelsingAI, Prisma, and EdgeDB. If you know of a company that should be on this list, don't hesitate to point it out: filtra@filtra.io"}
        topTenIndustriesPng={LargeIndustries}
        topTenIndustriesDesc={'The group of industries that tend to hire Rust engineers the most continues to be stable. This month had a surprise showing from "Productivity." You can thank Microsoft for that. And, to answer your question about listing Microsoft under productivity, we would say, how would you categorize Microsoft?!'}
        topTenIndustries={[
            'cloud/infrastructure',
            'crypto',
            'productivity',
            'security',
            'systems/hardware',
            'aerospace',
            'monitoring/reliability',
            'data science',
            'social media',
            'browser',
        ]}
        otherIndustriesPng={OtherIndustries}
        otherIndustriesDesc={''}
        seniorityPng={Levels}
        seniorityDesc={"While the vast majority of Rust jobs are still mid or senior level, we were encouraged to see a few more junior level jobs listed in September. If you're looking for an internship where you might get to write some Rust, maybe take a look at 1Password ;)"}
    />
}

export default RustReportSep23