import React from 'react';

import NumJobsPng from './numjobs.png';
import NumCompanies from './numcompanies.png';
import LargeHirers from './largehirers.png';
import OtherHirers from './otherhirers.png';
import LargeIndustries from './largeindustries.png';
import OtherIndustries from './otherindustries.png';

import RustReport from '../RustReport';


const RustReportJan23 = () => {
    return <RustReport 
        title={'Rust Jobs Report: January 2023'}
        subtitle={'Welcome to the January 2023 Rust Jobs Report. We maintain an index of companies hiring for Rust roles. Filtra has received an exciting level of support from the Rust community. As a thank you, we have elected to share this data publicly.'}
        titleNote={'To add your or another company to the index, please email filtra@filtra.io with the subject line "Rust Index"'}
        openPositionsPng={NumJobsPng}
        openPositionsDesc={"The number of jobs continues to grow!"}
        numCompaniesPng={NumCompanies}
        numCompaniesDesc={'We added 63 new companies to the index this month but only found 15 more hirers. Many of the new companies seemed to be smaller organizations that hire less frequently.'}
        largeHirersPng={LargeHirers}
        largeHirersDesc={"While Cloudflare continues to dominate, this month's leaderboard includes newcomers like Fortanix, DataDog, Ditto, KeyRock, and Embark"}
        topTenCompanies={[
            'Cloudflare',
            'Fortanix',
            'Heliax',
            'Parity',
            'DataDog',
            'Matter Labs',
            'Ditto',
            'KeyRock',
            'Embark',
            'Materialize',
        ]}
        otherHirersPng={OtherHirers}
        otherHirersDesc={"We're excited by the number of companies hiring for just one or two Rust jobs. We think this may be a sign of more companies gradually adopting the language for core use cases."}
        topTenIndustriesPng={LargeIndustries}
        topTenIndustriesDesc={"The list of top industries has been relatively stable. However, we were surprised to see the consultancies break the top ten this month. It seems more and more consultants are reaching for Rust to solve their clients' problems."}
        topTenIndustries={[
            'cloud/infrastructure',
            'security',
            'crypto',
            'monitoring/reliability',
            'systems/hardware',
            'databases',
            'browser',
            'messaging/notifications',
            'iot/robotics/automotive',
            'consultancy',
        ]}
        otherIndustriesPng={OtherIndustries}
        otherIndustriesDesc={'Industries we\'ve always expected to see in the report such as fintech finally appeared in this month\'s edition.'}
    />
}


export default RustReportJan23 