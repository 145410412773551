import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 5rem;
`

export const LogoImage = styled.img`
    aspect-ratio: 1;
    object-fit: cover;
    display: block;
    width: 100%;
    margin-top: 0.05px;
`

export const LogoImageContainer = styled.div`
    border: var(--medBorder);
    background-color: var(--black);
    max-height: 100%;
    aspect-ratio: 1;
    cursor: pointer;
    &:hover {
        border: var(--border);
    }
`

export const CompanyName = styled.h3`
    flex-grow: 1;
    text-align: center;
    color: var(--white);
    background-color: var(--black);
    margin: 0;
    font-family: 'Noto Sans';
    font-size: 1.75em;
    font-weight: 500;
    border: var(--border);
    max-height: 100%;
    margin-left: 1.5rem;
    padding: 0 0.5rem 0 0.5rem;
    cursor: pointer;
    &:hover {
        font-size: 1.8em;
    }
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const NameLogoContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 3.5em;
    margin-bottom: 1.5rem;
`

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    margin: 0 0 0.5rem 0;
`

export const JobCard = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--white);
    border: var(--medBorder);
    color: var(--black);
    font-family: 'Noto Sans';
    font-size: 1em;
    font-weight: 600;
    text-align: center;
    aspect-ratio: 2;
    padding: 0.5em;
    cursor: pointer;
    &:hover {
        border: var(--border);
    }
`

export const PlusSign = styled.span`
    font-size: 3em;
    font-weight: bold;
    cursor: default; 
`

export const JobTitle = styled.span`
   cursor: pointer; 
`

export const DeleteButton = styled.button`
    position: absolute;
    top: 0;
    left: 0;
    color: var(--black);
    background: var(--white);
    aspect-ratio: 1;
    border: var(--smallBorder);
    outline: none;
    font-family: 'Noto Sans Mono', monospace;
    font-weight: bold;
    margin: 0;
    z-index: 1;
`

export const JobContainer = styled.div`
    position: relative;
`