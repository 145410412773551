import Header from '../../../Header';
import { Background, Container, GraphImage, List, ListBlock, GraphDescription, SectionHead, Subtitle, Title, TitleNote, WideGraphDescription, WideGraphImage, OrangeLink, InlineLink, TitlePage, EndingNote } from './RustReport.styles.js';

const RustReport = ({ title, subtitle, titleNote, openPositionsPng, openPositionsDesc, numCompaniesPng, 
    numCompaniesDesc, largeHirersPng, largeHirersDesc, topTenCompanies, 
    otherHirersPng, otherHirersDesc, topTenIndustriesPng, topTenIndustriesDesc, topTenIndustries, 
    otherIndustriesPng, otherIndustriesDesc, seniorityPng, seniorityDesc }) => {
    return <Background>
            <Header showFiltraConfig={false}/> 
            <Container>
                <TitlePage>
                    <Title>{ title }</Title>
                    <Subtitle>{ subtitle }</Subtitle>
                    <TitleNote>{ titleNote }</TitleNote>
                </TitlePage>
                <SectionHead>Open Positions</SectionHead>
                <GraphImage src={ openPositionsPng }/>
                <GraphDescription>{ openPositionsDesc }</GraphDescription>
                <SectionHead>Hiring Companies</SectionHead>
                <GraphImage src={ numCompaniesPng }/>
                <GraphDescription>{ numCompaniesDesc }</GraphDescription>
                <SectionHead>Top Hirers</SectionHead>
                <WideGraphImage src={ largeHirersPng }/>
                <WideGraphDescription>{ largeHirersDesc }</WideGraphDescription>
                <ListBlock>
                    <WideGraphDescription>
                        Top 10 Companies: 
                    </WideGraphDescription>
                    <List>
                        {topTenCompanies.map((company, index) => (
                            <li key={index}>{company}</li>
                        ))}
                    </List>
                </ListBlock>
                <SectionHead>Other Hirers</SectionHead>
                <GraphImage src={ otherHirersPng }/>
                <GraphDescription>{ otherHirersDesc }</GraphDescription>
                <SectionHead>Industry Concentration</SectionHead>
                <WideGraphImage src={ topTenIndustriesPng }/>
                <WideGraphDescription>{ topTenIndustriesDesc }</WideGraphDescription>
                <ListBlock>
                    <WideGraphDescription>Top 10 Industries: </WideGraphDescription>
                    <List>
                        {topTenIndustries.map((industry, index) => (
                            <li key={index}>{industry}</li>
                        ))}
                    </List>
                </ListBlock>
                <SectionHead>Other Industries</SectionHead>
                <GraphImage src={ otherIndustriesPng }/>
                <GraphDescription>{ otherIndustriesDesc }</GraphDescription>
                {seniorityPng && (
                    <>
                        <SectionHead>Seniority Levels</SectionHead>
                        <WideGraphImage src={ seniorityPng }/>
                        <WideGraphDescription>{seniorityDesc}</WideGraphDescription>
                    </>
                )}
                <TitlePage>
                    <OrangeLink
                        to={'/'} 
                    >
                        get rust jobs on filtra 
                    </OrangeLink>
                    <EndingNote>
                        Data for this edition of the Rust Jobs Report is filtra platform data supplemented with public job postings. Feel free to visit the filtra Rust blog to{' '}
                        <InlineLink to={'/rust'}>
                            see past reports.
                        </InlineLink>
                    </EndingNote>
                </TitlePage>
            </Container>
        </Background>
}

export default RustReport