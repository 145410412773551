import React from 'react';
import { Background, Container, ContentContainer, Subtitle, Title, OrangeLink } from './RustHome.styles.js';
import Header from '../../Header/index.js';

const RustHome = () => {
    return <Background>
            <Header showFiltraConfig={false}/> 
            <Container>
                <ContentContainer>
                    <Title>The Filtra Rust Blog</Title>
                    <Subtitle>Welcome to the Filtra Rust Blog. Filtra has received an exciting level of support from the Rust community. As a thank you, we are doing our best to contribute to the conversation around working with the Rust programming language. Below you will find previous editions of the Rust Jobs Report as well as a selection of Rust-related articles.</Subtitle>
                </ContentContainer>
            </Container>
            <Container>
                <ContentContainer>
                    <OrangeLink
                        to={'/rust-nov-22'} 
                    >
                        November 2022 Jobs Report
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-dec-22'} 
                    >
                        December 2022 Jobs Report
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-jan-23'} 
                    >
                        January 2023 Jobs Report
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-feb-23'} 
                    >
                        February 2023 Jobs Report
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-mar-23'} 
                    >
                        March 2023 Jobs Report
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-apr-23'} 
                    >
                        April 2023 Jobs Report
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-may-23'} 
                    >
                        May 2023 Jobs Report
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-jun-23'} 
                    >
                        June 2023 Jobs Report
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-wingback-jul-23'} 
                    >
                        Conversation With Yann Leretaille of Wingback 
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-shuttle-jul-23'} 
                    >
                        Conversation With Ivan Cernja of Shuttle 
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-jul-23'} 
                    >
                        July 2023 Jobs Report
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-svix-aug-23'} 
                    >
                        Conversation With Tom Hacohen of Svix
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-aug-23'} 
                    >
                        August 2023 Jobs Report
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-gama-sep-23'} 
                    >
                        Using Rust to Fly Solar Sails 
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-corrode-oct-23'} 
                    >
                        Rust, Open Source, and Consulting 
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-sep-23'} 
                    >
                        September 2023 Jobs Report
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-ochagavia-oct-23'} 
                    >
                        Contributing To Rust As A Novice 
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-kittycad-nov-23'} 
                    >
                        Rewriting CAD in Rust 
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-oct-23'} 
                    >
                        October 2023 Jobs Report
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-meshcomm-dec-23'} 
                    >
                        Networking The Unnetworkable in Rust 
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-nov-23'} 
                    >
                        November 2023 Jobs Report
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-dec-23'} 
                    >
                        December 2023 Jobs Report
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-jan-24'} 
                    >
                        January 2024 Jobs Report
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-amp-feb-24'} 
                    >
                        How Rust Could Change Robotics 
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-feb-24'} 
                    >
                        February 2024 Jobs Report
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-flox-mar-24'} 
                    >
                        Making Nix Usable With Rust
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-mar-24'} 
                    >
                        March 2024 Jobs Report
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-apr-24'} 
                    >
                        April 2024 Jobs Report
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-amo-may-24'} 
                    >
                        Rebuilding Consumer Social In Rust
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-may-24'} 
                    >
                        May 2024 Jobs Report
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-mainmatter-jun-24'} 
                    >
                        Luca Palmieri, Mainmatter
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-jun-24'} 
                    >
                        June 2024 Jobs Report
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-meilisearch-jul-24'} 
                    >
                        Building Search In Rust   
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-jul-24'} 
                    >
                        July 2024 Jobs Report
                    </OrangeLink>
                    <OrangeLink
                        to={'/rust-quadratic-aug-24'} 
                    >
                        Building A Spreadsheet in Rust, WASM, and WebGL
                    </OrangeLink>
                </ContentContainer>
            </Container>
            <Container>
            <OrangeLink
                to={'/'} 
            >
                get rust jobs on filtra 
            </OrangeLink>
            </Container>
        </Background>
}

export default RustHome 