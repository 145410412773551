import styled from 'styled-components';

export const SignUpButton = styled.button`
    border: none;
    background: none;
    color: var(${(props) => props.color});
    font-family: 'Noto Sans Mono', monospace;
    font-size: 2em;
    text-decoration: underline;
    padding: 0;
    cursor: pointer;
`