import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 90vh;
    width: 100%;
    position: relative;
`

export const BackgroundOrange = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--orange);
  width: 100vw;
  min-height: 100vh;
`;

export const ValuePropContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    @media (min-width: 1000px) {
        width: 60%;
    }
`

export const Tagline = styled.span`
    color: var(--white);
    font-family: 'Noto Sans Mono', monospace;
    font-size: 3.5em;
    font-weight: 500;
    margin-bottom: 3rem;
    hyphens: manual;
`

export const ValueProp = styled.span`
    color: var(--grey-200);
    font-family: 'Noto Sans';
    font-size: 1.25em;
    font-weight: 400;
    margin-bottom: 3rem;
`

export const ScrollButton = styled.button`
    position: fixed;
    bottom: 0;
    color: var(--white);
    font-family: 'Noto Sans Mono', monospace;
    font-size: 3.5em;
    font-weight: 500;
    background: none;
    border: none;
    margin-bottom: 1rem;
`

export const DemoLink = styled.a`
    color: var(--white);
    font-family: 'Noto Sans Mono', monospace; 
    margin: 0 0.5em 0 0.5em;
    font-size: 2em;
    /* cursor: pointer; */
`