import React from 'react';
import { Container, Input, InputContainer, Label, LabelError } from './ShortBasicInput.styles.js';

const ShortBasicInput = ({ label, placeholder, val, onChange, readOnly, borderColor, type, error }) => {
    return <Container>
        <Label>
            { label }
            <LabelError>{ error }</LabelError>
        </Label>
        <InputContainer>
            <Input
                type={type}
                value={val}
                placeholder={placeholder}
                onChange={({ target }) => {
                    onChange(target.value)
                }}
                readOnly={readOnly}
                disabled={readOnly}
            />
        </InputContainer>
    </Container>
}

export default ShortBasicInput;
