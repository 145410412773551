import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 90vh;
    @media (min-width: 1000px) {
        min-width: 60%;
        max-width: 60%;
    }
    min-width: 80%;
    max-width: 80%;
`
export const TitlePage = styled.div`
    width: 100%;
    @media (min-width: 1000px) {
        width: 60%;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10vh 0 5rem 0;
    height: 90vh;
`

export const Title = styled.h1`
    color: var(--orange);
    font-family: 'Noto Sans Mono', monospace;
    font-size: 2.75em;
    font-weight: 500;
    margin: 0 0 1rem 0;
`

export const Subtitle = styled.h3`
    color: var(--black);
    font-family: 'Noto Sans';
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 1rem;
`

export const TitleNote = styled.h3`
    color: var(--black);
    font-family: 'Noto Sans';
    font-size: 0.6em;
    font-weight: 500;
    margin-bottom: 3rem;
    align-self: flex-start;
`

export const SectionHead = styled.h3`
    color: var(--orange);
    font-family: 'Noto Sans Mono', monospace;
    font-size: 1.5em;
    font-weight: 500;
    margin: 0 0 2rem 0;
`

export const SectionHeadMainmatter = styled.h3`
    color: #6200ee;
    font-family: 'Noto Sans Mono', monospace;
    font-size: 1.5em;
    font-weight: 500;
    margin: 0 0 2rem 0;
`

export const GraphDescription = styled.p`
    color: var(--black);
    font-family: 'Noto Sans';
    font-size: 1em;
    font-weight: 500;
    margin: 0 0 8rem 0;
    width: 100%;
    @media (min-width: 1000px) {
        width: 60%;
    }
    hyphens: auto;
`

export const Advertisement = styled.p`
    color: var(--black);
    font-family: 'Noto Sans';
    font-size: 1em;
    font-weight: 500;
    margin: 0 0 8rem 0;
    width: 100%;
    @media (min-width: 1000px) {
        width: 60%;
    }
    hyphens: auto;
    border: var(--orangeBorder);
    padding: 1rem;
`

export const AdvertisementMainmatter = styled.p`
    color: var(--black);
    font-family: 'Noto Sans';
    font-size: 1em;
    font-weight: 500;
    margin: 0 0 8rem 0;
    width: 100%;
    @media (min-width: 1000px) {
        width: 60%;
    }
    hyphens: auto;
    border: 6px solid #6200ee;
    padding: 1rem;
`

export const Background = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--grey-100);
  width: 100vw;
  min-height: 100vh;
`;

export const OrangeLink = styled(Link)`
    color: var(--orange);
    font-family: 'Noto Sans Mono', monospace; 
    font-weight: 500;
    font-size: 2em;
    margin: 0 0 2rem 0;
`

export const InlineLink = styled(Link)`
    color: var(--orange);
    font-family: 'Noto Sans'; 
    font-weight: 500;
`

export const InlineLinkMainmatter = styled.a`
    color: #6200ee;
    font-family: 'Noto Sans'; 
    font-weight: 500;
`

export const PlusSign = styled.span`
    font-size: 1.25em;
`

export const EndingNote = styled.h3`
    color: var(--black);
    font-family: 'Noto Sans';
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 1rem;
`

export const BigNumber = styled.span`
    color: var(--orange);
    font-family: 'Noto Sans';
    font-size: 5em;
    font-weight: 500;
    margin-bottom: 2rem;
`

export const Logo = styled.img`
    height: 1.25em;
`