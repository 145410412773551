import styled from "styled-components"

export const CompaniesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 80vw;
    min-width: 350px;
    @media (min-width: 600px) {
        width: 60vw;
    }
    @media (min-width: 1000px) {
        width: 25vw;
    }
        margin: 7rem 0 3rem 0;
`

export const AddCompanyBar = styled.div`
    border: var(--medBorder);
    background-color: var(--white);
    color: var(--black);
    text-align: center;
    font-family: 'Noto Sans';
    font-size: 3em;
    font-weight: bold;
    height: 1.25em;
`