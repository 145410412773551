import React from 'react';

import RustReportV2Mainmatter from '../RustReportV2Mainmatter';

const RustReportApr24 = () => {
    const NUM_JOBS = 806
    const NUM_COMPANIES = 103
    const LARGE_HIRERS = [
        ['Amazon', 120],
        ['Microsoft', 113],
        ['Cloudflare', 60],
        ['DataDog', 44],
        ['Apple', 39],
        ['Crypto.com', 27],
        ['SpaceX', 27],
        ['Meta', 26],
        ['Canonical', 26],
        ['IBM', 15],
    ]
    const OTHER_HIRERS = [
        ['Stockly', 15],
        ['Worldcoin', 14],
        ['Google ', 14],
        ['Fortanix', 13],
        ['HelsingAI', 13],
        ['Figma', 10],
        ['Syndica', 9],
        ['Akamai', 9],
        ['Mozilla', 8],
        ['Discord', 8],
        ['KeyRock', 7],
        ['Matter Labs', 7],
        ['GitHub', 6],
        ['Deep Fence', 5],
        ['Ardan Labs', 5],
        ['HyperExponential', 5],
        ['OneSignal', 5],
        ['Volume Finance', 5],
        ['Qdrant', 5],
        ['Smarkets', 4],
        ['Okta', 4],
        ['arm', 4],
        ['Sentry', 4],
        ['Scaleway', 4],
        ['IOHK', 3],
        ['InfinyOn', 3],
        ['IOTA Foundation', 3],
        ['Chorus One', 3],
        ['HealPay', 3],
        ['Scythe Robotics', 3],
        ['Zoo', 3],
        ['Deliveroo', 3],
        ['ApolloGraphQL', 3],
        ['Amo', 3],
        ['informal', 3],
        ['PingCAP', 3],
        ['10X Genomics', 3],
        ['Parity', 3],
        ['1Password', 3],
        ['Ledger', 3],
        ['SmartThings', 3],
        ['Svix', 3],
        ['Rapid7', 3],
        ['Heliax', 3],
        ['Ditto', 3],
        ['Aleph Alpha', 2],
        ['Fireblocks', 2],
        ['Reddio', 2],
        ['Open Cosmos', 2],
        ['Ellipsis Labs', 2],
        ['OpenAI', 2],
        ['Imperva', 2],
        ['Qovery', 2],
        ['ZORA', 2],
        ['Magic Eden', 2],
        ['Matic', 2],
        ['Railway', 2],
        ['Shadow', 2],
        ['OpenTalk', 2],
        ['Liquid Analytics', 2],
        ['Adobe', 1],
        ['Volka', 1],
        ['Embark', 1],
        ['Axiom', 1],
        ['Uniswap Labs', 1],
        ['ReadySet', 1],
        ['Aztec', 1],
        ['Tonari', 1],
        ['Argent', 1],
        ['Osmosis Labs', 1],
        ['Conduit', 1],
        ['Blowfish', 1],
        ['Clever Cloud', 1],
        ['SurrealDB', 1],
        ['Chef', 1],
        ['Fullstory', 1],
        ['Astropad', 1],
        ['Replit', 1],
        ['KION', 1],
        ['Threema', 1],
        ['Tabnine', 1],
        ['Grafbase', 1],
        ['AllSpice', 1],
        ['DSCVR', 1],
        ['Knowbe4', 1],
        ['AppSignal', 1],
        ['Star Lab', 1],
        ['Cymo', 1],
        ['OVH', 1],
        ['FP Complete', 1],
        ['Telstra', 1],
        ['Turso', 1],
        ['StarkWare', 1],
    ]
    const LARGE_INDUSTRIES = [
        ['cloud/infrastructure', 199],
        ['productivity', 133],
        ['crypto', 108],
        ['systems/hardware', 71],
        ['monitoring/reliability', 49],
        ['security', 44],
        ['data science', 30],
        ['aerospace', 29],
        ['social media', 29],
        ['browser', 22],
    ]
    const OTHER_INDUSTRIES = [
        ['consultancy', 21],
        ['marketplace', 18],
        ['messaging/notifications', 17],
        ['fintech', 12],
        ['databases', 9],
        ['iot/robotics/automotive', 9],
        ['health/biotech', 3],
        ['gaming', 2],
        ['animation', 1],
    ]
    const LEVELS = [
        ['junior', 13],
        ['mid', 411],
        ['senior', 382],
    ]
    return <RustReportV2Mainmatter
        title={'Rust Jobs Report: April 2024'}
        subtitle={'Welcome to the April 2024 Rust Jobs Report by filtra. Please enjoy this month\'s overview of the Rust job market.'}
        titleNote={'To add your or another company to the filtra Rust jobs index, please email filtra@filtra.io with the subject line "Rust Index"'}
        openPositions={NUM_JOBS}
        openPositionsDesc={'We found 806 public job postings in the last month. This marked a slight decrease from last month\'s record 862 postings.'}
        numCompanies={NUM_COMPANIES}
        numCompaniesDesc={'While overall postings declined slightly in April, the number of hirers actually grew appreciably from 90 to 103!'}
        largeHirers={LARGE_HIRERS}
        largeHirersDesc={'The list of the largest hirers continues to be dominated more and more by big-name tech companies. While perhaps a bit boring, this is a very positive indicator for Rust\'s sustainability!'}
        otherHirers={OTHER_HIRERS}
        otherHirersDesc={"The growth in the overall number of hirers can mostly be attributed to the addition of several companies hiring just a few engineers. However, this month we also added some well known names like Adobe. We're always looking to improve our index by adding more hirers. So, if you know of a company that you don't see here, don't hesitate to point it out: filtra@filtra.io."}
        topTenIndustries={LARGE_INDUSTRIES}
        topTenIndustriesDesc={'Rust shines where it shines. So, there\'s not much change happening as it relates to where and why Rust is being used. With that said, it seems inevitable that Rust ends up being used to build lower level deep learning infrastructure amidst the AI boom. If you know of companies doing that, let us know!'}
        otherIndustries={OTHER_INDUSTRIES}
        otherIndustriesDesc={'Rust\'s versatility is clear when you look at the diversity of problems spaces where it\'s being applied.'}
        seniority={LEVELS}
        seniorityDesc={"Rust jobs definitely skew toward the side of requiring more experience. This has to be due in part to the fact that companies favor Rust's memory safety and efficiency for mission-critical applications. If the language becomes more ubiquitous, the proportion of entry-level jobs will probably grow too."}
    />
}

export default RustReportApr24 