import React, { useState, useEffect } from 'react';
import { Container, LogoImage, LogoImageContainer, CompanyName, NameLogoContainer, GridContainer, JobCard, JobTitle, DeleteButton, JobContainer } from './CompanyOverview.styles';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';
import handleAPIError from '../../api/error';
import Loading from '../Loading';
import AddPanelButton from '../AddPanelButton';

const CompanyOverview = ({ company }) => {
    const { user, getAccessTokenSilently } = useAuth0()
    const recruiterId = user.sub.split('|')[1];
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState([])
    const [jobs, setJobs] = useState([])

    useEffect(() => {
        const getJobs = async () => {
            setIsLoading(true);
            try {
                const token = await getAccessTokenSilently();
                const response = await api.get(
                    `/recruiters/${recruiterId}/companies/${company.id}/jobs`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setJobs(response.data)
            } catch (err) {
                handleAPIError(err);
            }
            setIsLoading(false);
        }
        getJobs();
    }, [recruiterId, getAccessTokenSilently, navigate, company.id]);

    const deleteJob = async (jobId) => {
        setIsLoading(true);
        const isSure = window.confirm(`You are about to permanently delete this job. Click OK to confirm.`)
        if (isSure) {
            try {
                const token = await getAccessTokenSilently()
                await api.delete(`/recruiters/${recruiterId}/companies/${company.id}/jobs/${jobId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                let newJobs = jobs.filter(job => job.id !== jobId)
                setJobs(newJobs) 
            } catch (err) {
                handleAPIError(err);
            }
        }
        setIsLoading(false);
    }; 

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <Container>
                    <NameLogoContainer
                        onClick={() => {
                            navigate(`/recruiters/companies/${company.id}`)
                        }}
                    >
                        <LogoImageContainer>
                            <LogoImage src={company.photoUrl}/>
                        </LogoImageContainer>
                        <CompanyName borderColor={'--orange'}>
                            { company.name } 
                        </CompanyName>
                    </NameLogoContainer>
                    <GridContainer>
                        {jobs.map((job) => 
                            <JobContainer key={job.id}>
                                <DeleteButton
                                    onClick={() => {
                                        deleteJob(job.id)
                                    }} 
                                >
                                    x
                                </DeleteButton>
                                <JobCard 
                                    onClick={() => {
                                        navigate(`/recruiters/jobs/${company.id}/${job.id}`)
                                    }}
                                >
                                    <JobTitle>
                                        {job.title}
                                    </JobTitle>
                                </JobCard>
                            </JobContainer>
                        )}
                        <AddPanelButton
                            onClick={() => {
                                const newjobid = uuidv4()
                                navigate(`/recruiters/jobs/${company.id}/${newjobid}`)
                            }}
                        >
                            +
                        </AddPanelButton>
                    </GridContainer>
                </Container>
            )}
        </>
    );
};

export default CompanyOverview