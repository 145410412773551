import React from 'react';

import RustReportV2Mainmatter from '../RustReportV2Mainmatter';

const RustReportFeb24 = () => {
    const NUM_JOBS = 722
    const NUM_COMPANIES = 92
    const LARGE_HIRERS = [
        ['Amazon', 116],
        ['Cloudflare', 94],
        ['Microsoft', 86],
        ['DataDog', 43],
        ['Apple', 37],
        ['SpaceX', 29],
        ['Crypto.com', 21],
        ['Canonical', 20],
        ['Meta', 19],
        ['Fortanix', 15],
    ]
    const OTHER_HIRERS = [
        ['IBM', 13],
        ['Stockly', 12],
        ['Worldcoin', 11],
        ['Syndica', 9],
        ['Matter Labs', 7],
        ['KeyRock', 7],
        ['Akamai', 7],
        ['Ditto', 6],
        ['Figma', 6],
        ['Volume Finance', 5],
        ['Deep Fence', 5],
        ['Ardan Labs', 5],
        ['ApolloGraphQL', 5],
        ['Matic', 5],
        ['Workato', 5],
        ['PingCAP', 4],
        ['SmartThings', 4],
        ['Mozilla', 4],
        ['Qdrant', 4],
        ['IOTA Foundation', 4],
        ['Discord', 4],
        ['OneSignal', 4],
        ['InfinyOn', 3],
        ['10X Genomics', 3],
        ['Chorus One', 3],
        ['HealPay', 3],
        ['Scythe Robotics', 3],
        ['Google ', 3],
        ['Gamaspace', 3],
        ['Neurelo', 3],
        ['Qovery', 3],
        ['Zoo', 3],
        ['Svix', 3],
        ['1Password', 3],
        ['Open Cosmos', 3],
        ['Trivago', 3],
        ['Replit', 2],
        ['Imperva', 2],
        ['Rapid7', 2],
        ['OVH', 2],
        ['HyperExponential', 2],
        ['Liquid Analytics', 2],
        ['Smarkets', 2],
        ['Starry', 2],
        ['Railway', 2],
        ['Qwiet AI', 2],
        ['Deliveroo', 2],
        ['Embark', 2],
        ['Heliax', 2],
        ['OpenTalk', 2],
        ['Samsung', 2],
        ['arm', 2],
        ['Toyota Connected', 2],
        ['OpenAI', 2],
        ['Cymo', 1],
        ['Turso', 1],
        ['SurrealDB', 1],
        ['Bytebeam', 1],
        ['FP Complete', 1],
        ['Signal', 1],
        ['DSCVR', 1],
        ['WalletConnect', 1],
        ['Parity', 1],
        ['Clever Cloud', 1],
        ['Tonari', 1],
        ['Ledger', 1],
        ['Star Lab', 1],
        ['AppSignal', 1],
        ['Okta', 1],
        ['Aleph Alpha', 1],
        ['Rust Foundation', 1],
        ['Grafbase', 1],
        ['Tabnine', 1],
        ['Threema', 1],
        ['Zama', 1],
        ['Astropad', 1],
        ['Fullstory', 1],
        ['Atlassian', 1],
        ['Meilisearch', 1],
        ['shuttle', 1],
        ['UXStream', 1],
        ['Volka', 1],
    ]
    const LARGE_INDUSTRIES = [
        ['cloud/infrastructure', 231],
        ['productivity', 101],
        ['crypto', 71],
        ['systems/hardware', 62],
        ['monitoring/reliability', 44],
        ['security', 41],
        ['aerospace', 35],
        ['social media', 19],
        ['consultancy', 19],
        ['marketplace', 17],
    ]
    const OTHER_INDUSTRIES = [
        ['data science', 16],
        ['iot/robotics/automotive', 15],
        ['databases', 14],
        ['messaging/notifications', 12],
        ['browser', 7],
        ['fintech', 7],
        ['gaming', 3],
        ['health/biotech', 3],
        ['networking', 2],
        ['animation', 2],
        ['programming languages', 1],
    ]
    const LEVELS = [
        ['junior', 38],
        ['mid', 344],
        ['senior', 340],
    ]
    return <RustReportV2Mainmatter
        title={'Rust Jobs Report: February 2024'}
        subtitle={'Welcome to the February 2024 Rust Jobs Report by filtra. Please enjoy this month\'s overview of the Rust job market.'}
        titleNote={'To add your or another company to the filtra Rust jobs index, please email filtra@filtra.io with the subject line "Rust Index"'}
        openPositions={NUM_JOBS}
        openPositionsDesc={'While the number of Rust jobs is likely small compared to the overall number of programming jobs, the sample of 722 we were able to find this month is substantial and marks a new record high for this report!'}
        numCompanies={NUM_COMPANIES}
        numCompaniesDesc={'We found 92 unique companies currently hiring for Rust developers in February.'}
        largeHirers={LARGE_HIRERS}
        largeHirersDesc={'The list of top Rust hirers consistently includes well-known companies like Amazon and Microsoft. The language has clearly been embraced by the tech establishment, a great sign for longevity.'}
        otherHirers={OTHER_HIRERS}
        otherHirersDesc={"Several startups and smaller companies have been turning to Rust, contributing to the \"long tail\" of hirers. If we missed a company on this list, don't hesitate to point it out: filtra@filtra.io."}
        topTenIndustries={LARGE_INDUSTRIES}
        topTenIndustriesDesc={'Some believe that Rust is only used for crypto projects. But, the numbers tell a different story. Developers in a variety of fields have been attracted by the language\'s strengths.'}
        otherIndustries={OTHER_INDUSTRIES}
        otherIndustriesDesc={'Rust is basically used everywhere, even if only a little!'}
        seniority={LEVELS}
        seniorityDesc={"Rust jobs definitely skew toward the side of requiring more experience. This has to be due in part to the fact that companies favor Rust's memory safety and efficiency for mission-critical applications. If the language becomes more ubiquitous, the proportion of entry-level jobs will probably grow too."}
    />
}

export default RustReportFeb24 