import Header from '../../../Header';
import { Background, Container, GraphDescription, SectionHead, Subtitle, Title, TitleNote, OrangeLink, InlineLink, TitlePage, EndingNote, BigNumber, AdvertisementMainmatter, Logo, InlineLinkMainmatter, SectionHeadMainmatter, PlusSign } from './RustReportV2Mainmatter.styles.js';
import BarChart from './BarChart';
import TableChart from './TableChart';
import LogoImg from '../../../../assets/filtra_logo.png'
import MainmatterLogoImg from '../../../../assets/mainmatter_logo.png'
import BarChartMainmatter from './BarChartMainmatter/index.js';


const RustReportV2Mainmatter = ({ title, subtitle, titleNote, openPositions, openPositionsDesc, numCompanies, 
    numCompaniesDesc, largeHirers, largeHirersDesc, 
    otherHirers, otherHirersDesc, topTenIndustries, topTenIndustriesDesc,
    otherIndustries, otherIndustriesDesc, seniority, seniorityDesc }) => {
    return <Background>
            <Header showFiltraConfig={false}/> 
            <Container>
                <TitlePage>
                    <Title>{ title }</Title>
                    <Subtitle>{ subtitle }</Subtitle>
                    <TitleNote>{ titleNote }</TitleNote>
                </TitlePage>
                <AdvertisementMainmatter>
                    <Logo src={LogoImg} /><PlusSign> + &nbsp;</PlusSign><Logo src={MainmatterLogoImg} /><br/><br/>
                    We're hugely grateful to this month's sponsor, Mainmatter. Mainmatter is a consultancy that focuses on Rust on the backend: APIs, data pipelines, and web solutions. They offer strategic advice, training, and team reinforcement. They also organize the EuroRust conference. 
                    <br/><br/><InlineLinkMainmatter href={'https://mainmatter.com/rust-consulting/?utm_source=filtra'}>Learn more about Mainmatter</InlineLinkMainmatter>
                    <br/><br/>Sponsors allow us to make the investment required to continue the report. To sponsor, please reach out! filtra@filtra.io
                </AdvertisementMainmatter>
                <SectionHead>Are There Jobs in Rust?</SectionHead>
                <BigNumber>{ openPositions }</BigNumber>
                <GraphDescription>{ openPositionsDesc }</GraphDescription>
                <SectionHead>How Many Companies Use Rust?</SectionHead>
                <BigNumber>{ numCompanies }</BigNumber>
                <GraphDescription>{ numCompaniesDesc }</GraphDescription>
                <SectionHead>What Companies Use Rust Most?</SectionHead>
                <BarChart data={largeHirers}/>
                <GraphDescription>{ largeHirersDesc }</GraphDescription>
                <SectionHead>What Other Companies Use Rust?</SectionHead>
                <TableChart data={otherHirers}/>
                <GraphDescription>{ otherHirersDesc }</GraphDescription>
                <SectionHead>What Industries Use Rust Most?</SectionHead>
                <BarChart data={topTenIndustries}/>
                <GraphDescription>{ topTenIndustriesDesc }</GraphDescription>
                <SectionHead>What Other Industries Use Rust?</SectionHead>
                <TableChart data={otherIndustries}/>
                <GraphDescription>{ otherIndustriesDesc }</GraphDescription>
                <SectionHeadMainmatter>Are Rust Jobs Only For Senior Devs?</SectionHeadMainmatter>
                <BarChartMainmatter data={seniority}/>
                <GraphDescription>The Rust job market is tough. It favors experienced candidates. Companies are looking for engineers who can be trusted to build and operate their mission-critical Rust services.<br/><br/>If you want to make that jump, <InlineLinkMainmatter href={'https://mainmatter.com/services/workshops/rust/?utm_source=filtra'}>check out Mainmatter's Rust workshops!</InlineLinkMainmatter> They fill the gap between what you learned in the Rust book and what you need to build production-ready Rust services. The approach is hands-on- you learn by doing, solving exercises with the guidance of an experience instructor.<br/><br/>Use our code FILTRA20 to get a 20% discount on the next remote sessions: <br/><br/><InlineLinkMainmatter href={'https://ti.to/mainmatter/rust-telemetry-may-2024?utm_source=filtra'}>You Can't Fix What You Can't See: Telemetry For Rust Applications</InlineLinkMainmatter> (May 28-29)<br/><br/>Don't miss out on this opportunity to level up your Rust game!</GraphDescription>
                <TitlePage>
                    <OrangeLink
                        to={'/'} 
                    >
                        get rust jobs on filtra 
                    </OrangeLink>
                    <EndingNote>
                        Data for this edition of the Rust Jobs Report is filtra platform data supplemented with public job postings. Feel free to visit the filtra Rust blog to{' '}
                        <InlineLink to={'/rust'}>
                            see past reports.
                        </InlineLink>
                    </EndingNote>
                </TitlePage>
            </Container>
        </Background>
}

export default RustReportV2Mainmatter