import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { SignUpButton } from './SignupButton.styles.js';

const SignupButton = ({ cta, color }) => {
  const { loginWithRedirect } = useAuth0();
  return (
    <SignUpButton
      onClick={() =>
        loginWithRedirect({
          appState: {
            returnTo: '/profile'
          },
          screen_hint: 'signup',
        })
      }
      color={color}
    >
      { cta } 
    </SignUpButton>
  );
};

export default SignupButton;