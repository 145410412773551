import React, { useState, useEffect } from "react";
import LoginButton from '../LoginButton';
import { SubsectionHeader, Form, ButtonContainer, SectionContainer, MedButton, BackgroundPlain, InfoBox, Required } from '../../GlobalStyle';
import Loading from '../Loading';
import CheckBoxInput from "../CheckBoxInput";
import ShortBasicInput from '../ShortBasicInput';
import MultipleOptionInput from "../MultipleOptionInput";
import PanelInput from "../PanelInput";
import api from "../../api";
import { useLocation, useParams, useSearchParams, useNavigate } from "react-router-dom";
import { BoldInfoBox, BoldLink, Logo, LoginHeaderCol, LoginHeaderRow } from './Filter.styles'
import handleAPIError from "../../api/error";
import { useAuth0 } from "@auth0/auth0-react";
import RecruiterHeader from "../RecruiterHeader";
import { Container } from "../RecruiterHeader/RecruiterHeader.styles";
import { LabelError } from '../ShortBasicInput/ShortBasicInput.styles';
import CharLimitInput from '../CharLimitInput';

const Filter = () => {
    const id = useParams()['*']
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const jobId = searchParams.get('j');
    const companyId = searchParams.get('c');
    const navigate = useNavigate();
    const { user, getIdTokenClaims, getAccessTokenSilently } = useAuth0();

    const [isLoggedInRecruiter, setIsLoggedInRecruiter] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [minSalary, setMinSalary] = useState('')
    const [maxSalary, setMaxSalary] = useState('')
    const [stockOrEquity, setStockOrEquity] = useState(false)
    const [paidTimeOff, setPaidTimeOff] = useState('')
    const [parentalLeave, setParentalLeave] = useState('')
    const [health, setHealth] = useState(false)
    const [life, setLife] = useState(false)
    const [dental, setDental] = useState(false)
    const [retirement, setRetirement] = useState(false)
    const [vision, setVision] = useState(false)
    const [tuition, setTuition] = useState(false)
    const [workType, setWorkType] = useState([])
    const [skills, setSkills] = useState([])
    const [workPlace, setWorkPlace] = useState([])
    const [locations, setLocations] = useState([])
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [company, setCompany] = useState('')
    const [companyDescription, setCompanyDescription] = useState('')
    const [jobTitle, setJobTitle] = useState('')
    const [customComments, setCustomComments] = useState('')
    const [userFirstName, setUserFirstName] = useState('')
    const [errors, setErrors] = useState({});

    const getCompany = async (recruiterId, companyId) => {
        setIsLoading(true);
        try {
            const token = await getAccessTokenSilently();
            const response = await api.get(
                `/recruiters/${recruiterId}/companies/${companyId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setIsLoading(false);
            return response.data;
        } catch (err) {
            handleAPIError(err);
        }
        setIsLoading(false);
    }

    const getJob = async (recruiterId, companyId, jobId) => {
        setIsLoading(true);
        try {
            const token = await getAccessTokenSilently();
            const response = await api.get(
                `/recruiters/${recruiterId}/companies/${companyId}/jobs/${jobId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setIsLoading(false);
            return response.data;
        } catch (err) {
            handleAPIError(err);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        const checkClaims = async () => {
            setIsLoading(true);

            // try to get user info - no error if fails, only found if authenticated
            try {
                const token = await getAccessTokenSilently();
                const person = await api.get(`/people/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUserFirstName(person.data.firstName);
            } catch (e) {}

            const claims = await getIdTokenClaims()
            if (claims['https://filtra.io/roles'].includes('Recruiter')) {
                setIsLoggedInRecruiter(true) 
                const recruiterId = user.sub.split('|')[1];
                if (location.state || (jobId && companyId)) {
                    let job, company;
                    if (location.state) {
                        job = location.state.job;
                        company = location.state.company;
                    } else {
                        job = (await getJob(recruiterId, companyId, jobId)) ?? {};
                        company = (await getCompany(recruiterId, companyId)) ?? {};
                    }
                    setMinSalary(job.minSalary)
                    setMaxSalary(job.maxSalary)
                    setStockOrEquity(job.stockOrEquity)
                    setPaidTimeOff(job.paidTimeOff)
                    setParentalLeave(job.parentalLeave)
                    setHealth(job.health)
                    setLife(job.life)
                    setDental(job.dental)
                    setRetirement(job.retirement)
                    setVision(job.vision)
                    setTuition(job.tuition)
                    setWorkType(job.workType)
                    setSkills(job.skills)
                    setWorkPlace(job.workPlace)
                    setLocations(job.locations ?? [])
                    setCompany(company.name)
                    setCompanyDescription(company.description)
                    setJobTitle(job.title)
                } 
                try {
                    const token = await getAccessTokenSilently();
                    const response = await api.get(`/recruiters/${recruiterId}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    setFirstName(response.data.firstName);
                    setLastName(response.data.lastName);
                    setEmail(response.data.email);
                } catch (err) {
                    handleAPIError(err)
                }
            } 
            setIsLoading(false);
        };
        if (user) checkClaims();
    }, [user, location, id, getIdTokenClaims, getAccessTokenSilently]);

    const sendOpportunity = async (body) => {
        setIsLoading(true)
        setErrors({});
        try {
            await api.post(`/people/${id}/filter/responses`, body)
            alert('Opportunity sent!');
            if (location.state || (jobId && companyId)) {
                navigate(-1);
            }
        } catch (err) {
            const errorMsgs = handleAPIError(err, 'send');
            if (errorMsgs) setErrors(errorMsgs);
        }
        setIsLoading(false)
    }

    const getSubmittableSkills = () => {
        const submittableSkills = skills.filter(skill => skill.title.length > 0)
        setSkills(submittableSkills)
        return submittableSkills
    }

    const getSubmittableLocations = () => {
        const submittableLocations = locations.filter(location => location.name.length > 0)
        locations.forEach(location => delete location['radius'])
        setLocations(submittableLocations)
        return submittableLocations
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const preferences = {
            minSalary: parseInt(minSalary),
            maxSalary: parseInt(maxSalary),
            stockOrEquity: stockOrEquity,
            paidTimeOff: parseInt(paidTimeOff),
            parentalLeave: parseInt(parentalLeave),
            health: health,
            life: life,
            dental: dental,
            retirement: retirement,
            vision: vision,
            tuition: tuition,
            workType: workType,
            skills: getSubmittableSkills(),
            workPlace: workPlace,
            locations: getSubmittableLocations(),
            firstName: firstName,
            lastName: lastName,
            email: email,
            company: company,
            jobTitle: jobTitle,
            companyDescription: companyDescription,
            customComments: customComments.trim(),
        }
        sendOpportunity({
            jobId: jobId,
            companyId: companyId,
            recruiterId: isLoggedInRecruiter ? user.sub.split('|')[1] : null,
            opportunity: preferences
        });
    }

    const addSkillPanel = () => {
        if (skills.filter(skill => skill.title === '').length === 0) {
            const newSkills = skills.concat([{ title: '', skillLevel: 'interested'}])
            setSkills(newSkills)
        }
    }

    const deleteSkillPanel = (index) => {
        const newSkills = skills.filter((skill, idx) => {
            return idx !== index
        })
        setSkills(newSkills)
    }

    const addLocationPanel = () => {
        if (locations.filter(location => location.name === '').length === 0) {
            const newLocations = locations.concat([{ name: '', radius: 50}])
            setLocations(newLocations)
        }
    }

    const deleteLocationPanel = (index) => {
        const newLocations = locations.filter((location, idx) => {
            return idx !== index
        })
        setLocations(newLocations)
    }

    return <>
        {isLoading ?  <Loading /> :
            <BackgroundPlain>
                    {isLoggedInRecruiter ? 
                        <RecruiterHeader userExists={true}/> 
                    : null}
                    {/* Note: The following code segment will be used later on to invite recruiters to create acct */}
                    {/* {isLoggedInRecruiter ?
                        <RecruiterHeader userExists={true}/> 
                    : (userFirstName ? null : <Container>
                        <ButtonContainer>
                            <LoginHeaderRow>
                                <LoginHeaderCol>
                                    <InfoInline>Are you a recruiter?</InfoInline>
                                    <InfoInline>Login for a smoother experience.</InfoInline>
                                </LoginHeaderCol>
                                <LoginButton returnTo={window.location.pathname}/>
                            </LoginHeaderRow>
                        </ButtonContainer>
                    </Container>) } */}
                    <Form onSubmit={handleSubmit}>
                        {isLoggedInRecruiter ? 
                            <BoldInfoBox>
                                choose from<BoldLink to={`/recruiters/job-selection?id=${id}`}>saved jobs</BoldLink>
                            </BoldInfoBox>
                        :
                            <SectionContainer>
                                <Logo src={'https://storage.googleapis.com/filtra-photos-prod/filtra-word-logo.png'}/>
                                <BoldInfoBox>Hi there! This is filtra, the form that vets work opportunities for devs. It looks like you're trying to reach {userFirstName ? userFirstName : 'someone'}. Please describe your opportunity and we'll get you in contact if it's a good fit.</BoldInfoBox>
                            </SectionContainer>
                        }
                        <InfoBox>
                            <Required highlightColor={'--orange'}>Note that all fields are required</Required>
                        </InfoBox>
                        <SectionContainer>
                            <SubsectionHeader borderColor={'--orange'}>
                                skills
                                <LabelError fontSize='0.5em'>{errors['skills']}</LabelError>
                            </SubsectionHeader>
                            <InfoBox>List the tech skills you require. Note the minimum required skill level on the scale of interested to expert.</InfoBox>
                            <PanelInput 
                                accentColor={'--orange'}
                                isEnum={true}
                                vals={skills} 
                                enumOptions={['interested', 'beginner', 'intermediate', 'proficient', 'expert']}
                                onAddPanel={addSkillPanel}
                                placeHolder={'skill'}
                                fieldNames={['title', 'skillLevel']}
                                onDeletePanel={deleteSkillPanel}
                                onChange={setSkills}
                                panelType={'skill'}
                            />
                        </SectionContainer>
                        <SectionContainer>
                            <SubsectionHeader
                                borderColor={'--navy'} 
                            >compensation</SubsectionHeader>
                            <InfoBox>List the low end of the salary range.</InfoBox>
                            <ShortBasicInput 
                                type={'number'}
                                borderColor={'--navy'}
                                label={'minSalary ($)'}
                                placeholder={'minimum salary for the position'}
                                val={minSalary}
                                onChange={setMinSalary}
                                error={errors['minSalary']}
                            />
                            <InfoBox>List the high end of the salary range.</InfoBox>
                            <ShortBasicInput 
                                type={'number'}
                                borderColor={'--navy'}
                                label={'max. salary ($)'}
                                placeholder={'maximum salary for the position'}
                                val={maxSalary}
                                onChange={setMaxSalary}
                                error={errors['maxSalary']}
                            /> 
                            <InfoBox>May candidates be compensated with ownership? Check if yes.</InfoBox>
                            <CheckBoxInput 
                                accentColor={'--navy'}
                                label={'stockOrEquity'} 
                                checked={stockOrEquity}
                                onChange={setStockOrEquity}
                                error={errors['stockOrEquity']}
                            />
                        </SectionContainer>
                        <SectionContainer>
                            <SubsectionHeader
                                borderColor={'--green'} 
                            >benefits</SubsectionHeader>
                            <InfoBox>
                                List the time off policies. 
                            </InfoBox>
                            <ShortBasicInput 
                                borderColor={'--green'} 
                                label={'paidTimeOff (wks)'}
                                type={'number'}
                                placeholder={'number of paid weeks off'}
                                val={paidTimeOff}
                                onChange={setPaidTimeOff}
                                error={errors['paidTimeOff']}
                            />
                            <ShortBasicInput 
                                borderColor={'--green'} 
                                label={'parentalLeave (wks)'}
                                type={'number'}
                                placeholder={'number of paid weeks provided'}
                                val={parentalLeave}
                                onChange={setParentalLeave}
                                error={errors['parentalLeave']}
                            />
                            <InfoBox>Mark all applicable benefits.</InfoBox>
                            <CheckBoxInput 
                                accentColor={'--green'}
                                label={'health'} 
                                checked={health}
                                onChange={setHealth}
                                error={errors['health']}
                            />
                            <CheckBoxInput 
                                accentColor={'--green'}
                                label={'life'} 
                                checked={life}
                                onChange={setLife}
                                error={errors['life']}
                            />
                            <CheckBoxInput 
                                accentColor={'--green'}
                                label={'dental'} 
                                checked={dental}
                                onChange={setDental}
                                error={errors['dental']}
                            />
                            <CheckBoxInput 
                                accentColor={'--green'}
                                label={'retirement'} 
                                checked={retirement}
                                onChange={setRetirement}
                                error={errors['retirement']}
                            />
                            <CheckBoxInput 
                                accentColor={'--green'}
                                label={'vision'} 
                                checked={vision}
                                onChange={setVision}
                            />
                            <CheckBoxInput 
                                accentColor={'--green'}
                                label={'tuition'} 
                                checked={tuition}
                                onChange={setTuition}
                                error={errors['tuition']}
                            />
                        </SectionContainer>
                        <SectionContainer>
                            <SubsectionHeader
                                borderColor={'--orange'} 
                            >work</SubsectionHeader>
                            <InfoBox>Check all applicable work types.</InfoBox>
                            <MultipleOptionInput 
                                accentColor={'--orange'}
                                label={'workType'} 
                                val={workType}
                                options={[
                                    'fullTime', 
                                    'partTime', 
                                    'volunteer', 
                                ]}
                                onChange={setWorkType}
                                error={errors['workType']}
                            />
                        </SectionContainer>
                        <SectionContainer>
                            <SubsectionHeader
                                borderColor={'--navy'} 
                            >workPlace</SubsectionHeader>
                            <InfoBox>Check all possible work places.</InfoBox>
                            <MultipleOptionInput 
                                accentColor={'--navy'}
                                label={'workPlace'} 
                                val={workPlace}
                                options={['inPerson', 'hybrid', 'remote']}
                                onChange={setWorkPlace}
                                error={errors['workPlace']}
                            />
                        </SectionContainer>
                                                {(workPlace.includes('inPerson') ||
                            workPlace.includes('hybrid')) ? 
                                <SectionContainer>
                                    <SubsectionHeader
                                        borderColor={'--orange'} 
                                    >locations</SubsectionHeader>
                                    <InfoBox>List all office locations.</InfoBox>
                                    <PanelInput
                                        accentColor={'--orange'}
                                        isEnum={false}
                                        vals={locations}
                                        discreteRange={300}
                                        onAddPanel={addLocationPanel}
                                        placeHolder={{
                                            name: 'city',
                                        }}
                                        fieldNames={['name', 'radius', 'lat', 'lng', ]}
                                        onDeletePanel={deleteLocationPanel}
                                        onChange={setLocations}
                                        showRadius={false}
                                    />
                                </SectionContainer>
                            : null}
                        <SectionContainer>
                            <SubsectionHeader
                                borderColor={'--green'} 
                            >contact</SubsectionHeader>
                            <InfoBox>Supply your contact info.</InfoBox>
                            <ShortBasicInput 
                                borderColor={'--green'}
                                type="text"
                                label={'firstName'}
                                placeholder={'your first name'}
                                val={firstName}
                                onChange={setFirstName}
                                error={errors['firstName']}
                            /> 
                            <ShortBasicInput 
                                borderColor={'--green'}
                                type="text"
                                label={'lastName'}
                                placeholder={'your last name'}
                                val={lastName}
                                onChange={setLastName}
                                error={errors['lastName']}
                            /> 
                            <ShortBasicInput 
                                borderColor={'--green'}
                                type="email"
                                label={'email'}
                                placeholder={'your email'}
                                val={email}
                                onChange={setEmail}
                                error={errors['email']}
                            /> 
                            <ShortBasicInput 
                                borderColor={'--green'}
                                type="text"
                                label={'company'}
                                placeholder={'company you are inquiring for'}
                                val={company}
                                onChange={setCompany}
                                error={errors['company']}
                            /> 
                            <ShortBasicInput 
                                borderColor={'--green'}
                                type="text"
                                label={'job'}
                                placeholder={'job title you are inquiring for'}
                                val={jobTitle}
                                onChange={setJobTitle}
                                error={errors['jobTitle']}
                            /> 
                            <CharLimitInput 
                                borderColor={'--orange'} 
                                label={'companyDescription'}
                                placeholder={'short description about the company'}
                                val={companyDescription}
                                onChange={setCompanyDescription}
                                charLimit={128}
                                error={errors['companyDescription']}
                            />
                            <CharLimitInput 
                                borderColor={'--orange'} 
                                label={'message'}
                                placeholder={'any additional information you want to send to the candidate'}
                                val={customComments}
                                onChange={setCustomComments}
                                charLimit={1000}
                                error={errors['customComments']}
                            />
                        </SectionContainer>
                        <ButtonContainer>
                            <MedButton>send</MedButton>
                        </ButtonContainer>
                    </Form> 
            </BackgroundPlain>
        } 
    </>
}

export default Filter
