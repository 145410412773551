import React from 'react';
import { Background, Container, ContentContainer, Subtitle, Title, OrangeLink, Paragraph, Speaker, OrangeLinkInline } from '../DeepDive.styles.js';
import Header from '../../../Header';

const RustQuadraticAug24 = () => {
    return <Background>
            <Header showFiltraConfig={false}/> 
            <Container>
                <ContentContainer>
                    <Title>Building A Spreadsheet in Rust with WASM and WebGL</Title>
                    <Subtitle>This is my interview with David Kircos, CEO of Quadratic. They're heavily using Rust to build a highly performant, web-based, programmable spreadsheet. I'm really intrigued by the workflows that Quadratic enables, and I think they're on the forefront of some exciting trends around web applications. I enjoyed talking to David. He has a great way of explaining some of the technology trends we talked about. As always, feel free to check out our extensive list of rust jobs by setting up your profile on <OrangeLinkInline to={'/'}>filtra</OrangeLinkInline>. - Drew</Subtitle>

                    <Paragraph><Speaker> Drew: </Speaker>What’s the elevator pitch for people who haven’t heard about Quadratic?</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>Quadratic is a technical spreadsheet with support for Python, SQL, and JavaScript. Quadratic lets you use AI to write those languages. The vision for the product is to let people analyze data the developer way and share it the spreadsheet way. One of the huge benefits is being able to use a lot of the amazing, open source data tools that exist in the Python and SQL communities while being in a familiar spreadsheet environment.</Paragraph>

                    <Paragraph><Speaker> Drew: </Speaker>I imagine this idea grew out of something you experienced personally. What made you think to build this?</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>My background is in finance. I also wrote software to help venture capital firms like Techstars keep track of their finances. So, my stack was Python for analysis, SQL for retrieving data, and spreadsheets as the interface to communicate results to my team. All three of those things are just working with tables of data. So, it feels like they should have worked well together. But that’s not the case. I spent a lot of time gluing all of those pieces together rather than solving the specific problems I had. So, the idea was just to bring these separate tools together to work on the same table, and let users choose the tool they need on a cell by cell basis.</Paragraph>

                    <Paragraph><Speaker> Drew: </Speaker>That makes sense. With the little bit of analytics that I’ve done, it always feels like you’re extracting data from a table into some other modality only to recreate the table and then exfiltrate the data back to another modality where you create yet another table. So, skipping that repeated reconstruction of the table makes a lot of sense.</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>Yeah, that workflow is so common: select data with SQL, analyze it with Python, and display it in a spreadsheet. So, in Quadratic, you can do that entire workflow in one tool.</Paragraph>

                    <Paragraph><Speaker> Drew: </Speaker>When I was researching your tech stack, it sounds like a lot of the really cool things you are able to do are made possible by a couple of really cutting edge web technologies that you’re using. Would you be able to give an overview of your stack?</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>A big part of the initial thesis for Quadratic is that the web platform has changed. In an early blog post, I actually called it Web3! (laughing) Crypto isn’t necessarily an evolution of the web, but the browser has really taken a step forward. Nowadays, you could build a competitor to Adobe in a browser. I don’t think anyone thought that was possible five or ten years ago. And, at that time, it wasn’t. By the way, that’s kind of what Figma has done. So the technologies that enable that are WebAssembly and WebGL. These give you the ability to control exactly how data is laid out in memory and exactly what’s drawn to the screen. So, instead of being limited to HTML elements, you can build any application. For example, people have compiled Windows XP to run in WebAssembly and WebGL. So, you can even run a full operating system in your browser. We felt this set of tools was particularly useful for a spreadsheet application because we could actually build something more performant than existing spreadsheets and have that pinch and zoom, free-panning experience. With the previous iteration of the web, you had to choose between the distribution of a website or the performance of a native application. Now, you can have both.</Paragraph>

                    <Paragraph><Speaker> Drew: </Speaker>One of the parts that was so interesting to me is the distribution piece. Is the distribution so much better because you as a developer basically just need to target the web platform and then can run anywhere that the web platform runs?</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>Yeah, the distribution is superior because anyone can put a URL in their browser and open one of our spreadsheets. They don’t have to download our application onto a system that they know is compatible. You can see with Figma how fast these things proliferate. In Figma, someone can just build something and share it with their coworkers. When you try to share something in Adobe, you often discover that once you’ve shared a file, your coworker has a two hour install process before they can open the files. So, that’s what I mean about getting superior distribution. Anyone can open a Quadratic file and immediately be in our high-performance, native-like application within four seconds on any platform.</Paragraph>

                    <Paragraph><Speaker> Drew: </Speaker>Yeah, that makes so much sense based on my own experience with Figma. I know Figma has a desktop app, which is probably Electron.</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>Yeah, it’s Electron. So, it’s just their website wrapped.</Paragraph>

                    <Paragraph><Speaker> Drew: </Speaker>Okay, yeah, because I’ve really only used Figma in the browser. And, there isn’t really a performance difference that I’ve noticed from the browser to the native app.</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>Yeah, the most popular desktop apps today are almost all web apps packaged for desktop with Electron. That includes Slack, Notion, Figma, and VSCode.</Paragraph>

                    <Paragraph><Speaker> Drew: </Speaker>I imagine in the early days it's not really a priority to do Electron… Have you done anything with it?</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>We did actually have an Electron release early on, but we realized it was going to be too difficult to maintain it while shipping features with the velocity we wanted. So, we discontinued it for a while. But it will be back sometime later this year or next year.</Paragraph>

                    <Paragraph><Speaker> Drew: </Speaker>We’ve pretty much covered the topic of WebAssembly and WASM, but I do want to ask one more question. One of your blog posts said that you think these technologies are bizarrely underdiscussed, which I definitely agree with. Why aren’t they being talked about?</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>I say they’re under-discussed because usually, when there’s some new technology, people get very excited and talk about it a lot. Even if it doesn’t have a bunch of obvious, immediate applications. We definitely saw that with crypto. But, for whatever reason, WebAssembly and WebGL have not been hyped in the same way. I think that might be because they’re actually not that useful for most applications. For example, if you’re building a CRM or something like that, there’s just no value. You have to have an application where you need a very particular interface that’s hard to build with HTML or you need an extremely high level of performance that isn’t achievable in standard JavaScript. And the performance in JavaScript has become amazing, by the way. So, that’s a high bar to clear. If you think about something like Figma, obviously, the interface is highly nuanced, and the graphics rendering really matters. For example, doing a transform across a bunch of elements could be done much more efficiently if you could control memory. In a spreadsheet, you have similar problems and constraints.</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>I think the other category where we’ll see this a lot more is games. For example, Unity is being ported to WebAssembly and WebGL. Other engines are being ported as well. So, you can actually finally make really great games in the browser without using Flash. Beyond what we’ve discussed, there aren’t really that many applications that have this set of requirements. But, I still think they’re incredibly exciting and should be discussed more.</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>I think one other exciting application will be AI models. For example, Google announced that Gemini is going to be running in the browser in a web worker. So, I think we’ll also see new technologies that require a lot of local performance come along and provide new use cases.</Paragraph>

                    <Paragraph><Speaker> Drew: </Speaker>It’s interesting to think about smaller AI models running in a browser context. I don’t know everything that needs to be in place for that to work, but it seems like if Google is interested in it, it’ll happen. They’re basically in charge of browsers after all.</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>Yeah, I’m not sure if it's going to be WebGPU or WebAssembly, but I know that will be in an upcoming release of Chrome.</Paragraph>

                    <Paragraph><Speaker> Drew: </Speaker>This is off topic a bit for our conversation, but have you heard about some of the efforts to run WebAssembly on the server as a way to simplify virtualization? Do you know much about that?</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>Yeah, we haven’t really done much with that. WebAssembly itself is a portable compilation target, and that’s been a sort of holy grail for a long time. People have always wanted basically a portable binary that could run on any system. So, yeah, people started thinking it could be really great for server architecture. There’s a lot of excitement around that, and I would say that the enthusiasm there might even be a bit ahead of the use cases. One of the interesting ideas is to have applications run in WebAssembly on the edge. This will be really useful for things like multiplayer rooms. So, those are things where you need a server but want fast back and forth with the end user.</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>As a paradigm, WebAssembly on the server is just such a far step away from something like Docker. Docker has an operating system, which means I can use packages that expect that Linux is available. WebAssembly is not that. People have tried to emulate parts of a system with things like WASI. But, this is all pretty outside of what I know a lot about.</Paragraph>

                    <Paragraph><Speaker> Drew: </Speaker>Yeah, I wonder about it. As you said, there’s a limited set of web applications that can benefit from WebAssembly. But, those that can benefit see huge advantages. On the other hand, if WebAssembly were used heavily as a target for backends, it seems like that would give at least an order of magnitude increase in adoption and usage.</Paragraph>

                    <Paragraph><Speaker> Drew: </Speaker>I think I understand intuitively why you would want web assembly to perform operations efficiently on your spreadsheet, but I’m not sure it’s clear to me why you need to draw it with WebGL. Why take that step?</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>Yeah, the Quadratic spreadsheet is drawn like a tile map, similar to Google Maps. So, we draw the entire thing in WebGL. All of the interactions are done in WebGL as well, including clicking, moving the mouse, resizing, and so forth. The reason we do it that way is because we can put it on a canvas where you can zoom out and pan smoothly. So, it’s really about navigating larger datasets easily. With our goal of bringing data science tools into the spreadsheet, we anticipate people wanting to bring bigger datasets in as well. While it may never make sense to bring a billion rows into a spreadsheet, I can see the case for bringing in a million. So, being able to navigate that easily is important. The navigation on current spreadsheets is very chunky.</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>So, a simple example of the interaction this enables is that you can put a huge dataset in quadratic and zoom out, finding what you’re looking for from a distance and then zooming back in.</Paragraph>

                    <Paragraph><Speaker> Drew: </Speaker>The other thing I was curious about from the WebAssembly perspective is the fact that you’re using Rust to target WebAssembly. That seems to be kind of the way to do web assembly. Is there a reason people don’t use other compiled languages more? I know there are the general reasons why anyone would want to use Rust for a new project, but is there something specific about WebAssembly and Rust?</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>So, lots of people do use C++. I believe Figma is mostly C++. There are a lot of pre-existing graphics applications in C++, and I think in part they wanted to take advantage of those. They actually originally compiled C++ to Javascript. So, I would actually say that overall there’s probably more C++ compiled to WebAssembly than Rust. But I do think a lot of greenfield projects are choosing Rust. And maybe that’s just because it gives them an excuse to use Rust. (laughing)</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>There are two primary reasons we chose Rust. The first is that Rust cares a lot about program correctness and stability, which is super important in spreadsheets. The second thing is that Rust attracts great talent. So, being a Rust product differentiates us as an employer in a way that appeals to great engineers. So, it’s been a hiring advantage and a product quality advantage.</Paragraph>

                    <Paragraph><Speaker> Drew: </Speaker>I hadn’t thought about the correctness aspect as it relates to spreadsheets. But, it makes so much sense. So many businesses run critical business processes off of some spreadsheet.</Paragraph>

                    <Paragraph><Speaker> Drew: </Speaker>On the note of the hiring advantage, have you been able to parse out why these really great engineers seem to be drawn to Rust?</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>I think talented engineers see Rust as this language that forces good habits and good code. It’s maybe more of an ideological thing for them. I think good developers want to write great code no matter what, and they see Rust as a tool to help them do that. Rust just embodies correctness in a way that's very different from something like C++. At the end of the day, C++ doesn’t really care if your code is right or wrong. Rust, most of the time, tests aren’t even required. If it compiles, it’s probably correct.</Paragraph>

                    <Paragraph><Speaker> Drew: </Speaker>We’ve talked a lot about using Rust on the frontend. Do you use it on the backend as well?</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>Yep, we have a number of Rust services that we run on the backend as well. That’s one of the areas where Rust has really demonstrated its value to me as someone that’s built a lot of Python and Javascript APIs in the past. For example, the Python and Javascript services I’ve created in the past tend to crash and cause problems. On the other hand, we’ve launched our Rust services and basically never thought about them again. Mainly, you don’t get the runtime type errors because that’s something that Rust explicitly protects against.</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>I also love the fact that using it on the backend and frontend means we can share code. For example, we have this core spreadsheet library that runs in the browser and on our servers. It’s the same exact code.</Paragraph>

                    <Paragraph><Speaker> Drew: </Speaker>That’s the second time I’ve heard someone mention that benefit. The other company was amo, a consumer social startup in France. They’re building mobile apps, and they share a lot of code. I think a lot of it is reusing types. So, that seems to be a benefit people are realizing.</Paragraph>

                    <Paragraph><Speaker> Drew: </Speaker>I’ve heard some people express frustration about ecosystem maturity when it comes to using Rust for web backends. Have you had frustration with that at all, or has it been smooth sailing?</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>Well, I’d say it’s been smoother than Javascript. Particularly, tools like Express seem to surprise me with how immature they are. For example, we use Typescript. Express doesn’t have good Typescript support.</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>As far as Rust, we use Axum with Tokio. It's been plenty stable for what we need. And, it does seem like the community is actually coalescing around these packages. When we got into Rust a couple of years ago, things were a little more scattered. There were more competing ideas. But, things have really stabilized in the last little bit.</Paragraph>

                    <Paragraph><Speaker> Drew: </Speaker>It does feel like the pieces are coming together. One of the pieces of Quadratic that was interesting to me is that you’re running Python in the browser. That doesn’t sound straightforward. How did you make that work?</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>There’s a compilation of CPython to WebAssembly that’s pretty well maintained. I believe the Mozilla Foundation is in charge of it. So, we use that to run Python in Quadratic. We chose to do that all in the browser, because its faster for most spreadsheet-size workloads. In most cases, there’s no waiting at all. It also comes with real benefits for us as a company. We don’t have to worry about spinning up and spinning down servers that are running untrusted, user-written code. And, we don’t have the costs that would be involved with that. This is one of the big reasons we’re able to give out a free version of Quadratic.</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>I will say the Python experience inside Quadratic is kind of Python lite. You can’t use arbitrary packages, and you can’t use super compute-heavy packages either. So, we are working on an enterprise code runner that will enable those things. So, that’s coming but for most cases we think in-browser is the way to go.</Paragraph>

                    <Paragraph><Speaker> Drew: </Speaker>I hadn’t thought about the simplicity and efficiency it brings for you as a company.</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>Yeah, and the other thing is consistency. If something runs on my computer, it’s going to run for you too. We’re literally running in the exact same environment. That’s cool because consistency across environments has always been a pain point of sharing and working with Python code. We want people to just be able to use code to work on data and not think about configuration.</Paragraph>

                    <Paragraph><Speaker> Drew: </Speaker>Yeah, I’m very familiar with all of the Python package management and environment pain. Along those same lines, this requires you to build code editing capabilities in your app. If I were to make a list of things that sound challenging because of the spread of desired features, I feel like code editors would be near the top of that list. How have you broken down that problem?</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>People would probably think of building a spreadsheet and building a code editor as two big, daunting tasks. Quadratic is kind of both of those things. But the real answer is we’re basically not building a code editor. The code editor is Monaco under the hood. That’s the same code editor that powers VSCode. We are building an IDE that’s focused on data analysis. So, we actually have done a lot of work to get the language server, syntax highlighting, and all of those sorts of things. But we’re not taking on the code editor itself.</Paragraph>

                    <Paragraph><Speaker> Drew: </Speaker>The audience for these interviews is technical, so I try to mostly ask technical questions, but I can’t resist asking one strategy question. So, almost every business either uses Microsoft Office or Google Workspace, both of which come with spreadsheets. So, what’s your plan for convincing people to shell out for an additional spreadsheet?</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>I think if Quadratic is compared to Excel or Google Sheets as a line item in the budget, we will lose. Why add incremental cost for a spreadsheet, right? So, we’re focused more on business intelligence use cases where the fundamental problem is that you have some data and you want to analyze it and distribute that knowledge to a team of people who are familiar with spreadsheets. So, I think we can win if we’re being compared to something like Metabase or Tableau. As a result, our focus has really been on not just rebuilding Excel. I think if we just rebuild Excel with Python in it, that’s a failure. We want to create the best tool for working with data, and we happened to pick the interface that most people in the world are familiar with.</Paragraph>

                    <Paragraph><Speaker> Drew: </Speaker>That makes a lot of sense to me, and my intuition tells me this is a strong approach for that.</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>The other key piece is that we’re the only spreadsheet that’s actively targeting technical people. There’s like fifty million technical people out there, and Google Sheets and Excel do not speak the language of technical people. We’re really trying to serve those people by making Quadratic comfortable for them to work in. And, I think in general developers are willing to adopt any tool that makes them more productive.</Paragraph>

                    <Paragraph><Speaker> Drew: </Speaker>Obviously, I love that. As a technical person, I always feel like a second class citizen in other spreadsheets. So, are there any use cases you’re particularly excited about?</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>Yeah. I mentioned that the idea for Quadratic came out of my experience working in finance. A lot of our early users have been in finance. So, that’s been an exciting vertical. There are so many people in finance who are good at spreadsheets but maybe want to pick up Python and SQL. Or, they’re good at Python and SQL and need to collaborate with people who only know spreadsheets.</Paragraph>

                    <Paragraph><Speaker> Drew: </Speaker>So, the other thing that I’ve been impressed with about Quadratic is that it seems like you’ve got a great team. And, I know you’ve been thoughtful about creating a really specific team dynamic. Can you describe what it’s like working at Quadratic?</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>The Quadratic team is very technical, and it's just a group of people who are generally really passionate about building great software. We’re basically all generalists. Most everyone on the team can write both Typescript and Rust. And, as I said, we’ve really focused on hiring people who have a passion for creating great software, whether that’s super smooth user interfaces, highly efficient algorithms, or really robust services.</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>We’re mostly a hybrid company. So, we work in person some of the time and remote some of the time. In this environment, it’s really important to find people who are self-driven to achieve results. We’ve been lucky to find some great people early on, and then I think teams with great talent tend to attract more great talent. So, it is something that you have to get right very early.</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>I think since I’ve been here writing code since the beginning, I’ve been able to set the tone for Quadratic to have this very technical, product-focused culture.</Paragraph>

                    <Paragraph><Speaker> Drew: </Speaker>So, do you feel like you’ve gone slow to go fast as it relates to your hiring?</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>Well, the market for talent has changed dramatically since we started Quadratic. And, our ability to attract talent has also changed quite a bit. When we were hiring our first engineer, it took us like three months for one hire. It was hard to find people who wanted to work at Quadratic, and it was even harder to find people who we thought would be a great fit as the first hire. Because that hire was really great, they’ve attracted other great talent. Then, as I’ve said, the talent market also seems to have flipped on its head. When we first started hiring, the people we were talking to were getting multiple very good offers. Now, we’re seeing more people sitting on the market for a bit. I’ve also seen more people who are purely interested in working on an interesting project versus getting paid as much as possible. The days of job offers just escalating and escalating seem to have ended. The hiring market today is probably the best I’ve seen in a long time for startups.</Paragraph>

                    <Paragraph><Speaker> Drew: </Speaker>Yeah, that tracks with everything I’ve seen. Before we wrap up, is there anything else you want to mention?</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>Well, the roadmap for building a spreadsheet is very long. We’ve been building for two years now, and it feels like we’re finally at the point of having something useful. We’ve launched SQL and database connections and will be adding more over the next couple of weeks. And, in the last couple of months, we’ve launched real-time multiplayer, JavaScript support, and charts and graphs support through popular libraries like Plotly. Also, the performance has dramatically improved. So, it’s been an exciting last year for features. Going forward, we’re excited to focus on building the business.</Paragraph>

                    <Paragraph><Speaker> Drew: </Speaker>That’s awesome! I’ll be looking forward to your SQL release and everything else that the future brings.</Paragraph>

                    <Paragraph><Speaker> David: </Speaker>Thank you for taking the time to formulate these questions and think about all these things.</Paragraph>

                    <Paragraph><Speaker> Drew: </Speaker>Thank you for chatting!</Paragraph>

                </ContentContainer>
            </Container>
            <Container>
            <OrangeLink
                to={'/'} 
            >
                get rust jobs on filtra 
            </OrangeLink>
            </Container>
        </Background>
}

export default RustQuadraticAug24 