import React from 'react';
import { Button, Container } from './AddPanelButton.styles';

const AddPanelButton = ({ onClick, accentColor }) => {
  return (
    <Container>
      <Button 
        accentColor={accentColor}
        type="button" 
        onClick={onClick}
      >
        +
      </Button>
    </Container>
  );
};

export default AddPanelButton;
