import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 90vh;
    width: 100%;
`

export const Background = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--grey-100);
  width: 100vw;
  min-height: 100vh;
`;


export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media (min-width: 1000px) {
        width: 60%;
    }
    width: 80%;
`

export const Title = styled.h1`
    align-self: center;
    color: var(--orange);
    font-family: 'Noto Sans Mono', monospace;
    font-size: 3.5em;
    font-weight: 500;
    margin-top: 10rem;
    margin-bottom: 3rem;
    max-width: 100%;
    overflow-wrap: break-word;
`

export const Subtitle = styled.h3`
    color: var(--black);
    font-family: 'Noto Sans';
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 3rem;
`

export const OrangeLink = styled(Link)`
    color: var(--orange);
    font-family: 'Noto Sans Mono', monospace; 
    font-weight: 500;
    font-size: 2em;
    margin: 2rem;
`

export const OrangeLinkInline = styled(Link)`
    color: var(--orange);
    font-family: 'Noto Sans'; 
`

export const ExternalLink = styled.a`
    color: var(--orange);
    font-family: 'Noto Sans'; 
`

export const Paragraph = styled.p`
    color: var(--black);
    font-family: 'Noto Sans';
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 1rem;
`

export const Speaker = styled.span`
    color: var(--black);
    font-family: 'Noto Sans';
    font-size: 1em;
    font-weight: 600;
`