import React from 'react';
import AddPanelButton from '../AddPanelButton';
import PanelDiscrete from '../PanelDiscrete';
import PanelEnum from '../PanelEnum';
import { Container } from './PanelInput.styles';

const PanelInput = ({ isEnum, vals, enumOptions, fieldNames, discreteRange, onAddPanel, placeHolder, onDeletePanel, onChange, 
     panelType, accentColor, showRadius }) => {

    return <Container>
        {isEnum ? 
            vals.map((val, index) => <PanelEnum 
                key={index} 
                val={val} 
                vals={vals}
                enumOptions={enumOptions}
                placeHolder={placeHolder}
                fieldNames={fieldNames}
                onDeletePanel={onDeletePanel}
                panelType={panelType}
                index={index}
                onChange={onChange}
            />)
        : vals.map((val, index) => <PanelDiscrete 
                key={index} 
                val={val} 
                vals={vals}
                discreteRange={discreteRange}
                placeHolder={placeHolder}
                fieldNames={fieldNames}
                onDeletePanel={onDeletePanel}
                index={index}
                onChange={onChange}
                showRadius={showRadius}
        />)}
        <AddPanelButton
            accentColor={accentColor}
            onClick={onAddPanel} 
        />
    </Container>
}

export default PanelInput;