import React from 'react';

import NumJobsPng from './numjobs.png';
import NumCompanies from './numcompanies.png';
import LargeHirers from './largehirers.png';
import OtherHirers from './otherhirers.png';
import LargeIndustries from './largeindustries.png';
import OtherIndustries from './otherindustries.png';
import Levels from './levels.png';

import RustReport from '../RustReport';


const RustReportJun23 = () => {
    return <RustReport 
        title={'Rust Jobs Report: June 2023'}
        subtitle={'Welcome to the June 2023 Rust Jobs Report. We maintain an index of companies hiring for Rust roles. Filtra has received an exciting level of support from the Rust community. As a thank you, we have elected to share this data publicly.'}
        titleNote={'To add your or another company to the index, please email filtra@filtra.io with the subject line "Rust Index"'}
        openPositionsPng={NumJobsPng}
        openPositionsDesc={"June was another record month for the report, with the number of jobs approaching 400."}
        numCompaniesPng={NumCompanies}
        numCompaniesDesc={'While number of jobs grew in June, the number of hirers fell slightly. A few major hirers increasing their listings accounts for the overall growth.'}
        largeHirersPng={LargeHirers}
        largeHirersDesc={"The big story in June is Amazon dethroning Cloudflare on the hirer leaderboard."}
        topTenCompanies={[
            'Amazon',
            'Cloudflare',
            'SpaceX',
            'Workato',
            'Apple',
            'DataDog',
            'Crypto.com',
            'Discord',
            'Google',
            'Fortanix',
        ]}
        otherHirersPng={OtherHirers}
        otherHirersDesc={"Thanks to community submissions, the list of other hirers includes some cool new robotics companies like Scythe Robotics. If you know of a company that should be on this list, don't hesitate to point it out: filtra@filtra.io"}
        topTenIndustriesPng={LargeIndustries}
        topTenIndustriesDesc={"SpaceX put aerospace high on the industry leader board in June."}
        topTenIndustries={[
            'cloud/infrastructure',
            'crypto',
            'security',
            'aerospace',
            'systems/hardware',
            'productivity',
            'monitoring/reliability',
            'browser',
            'messaging/notifications',
            'data science',
        ]}
        otherIndustriesPng={OtherIndustries}
        otherIndustriesDesc={''}
        seniorityPng={Levels}
        seniorityDesc={"Rust's emphasis on systems-level problem solving and its relative \"newness\" make it hard to find entry-level jobs."}
    />
}

export default RustReportJun23 