import React, { useEffect } from 'react';
import { Container, BackgroundOrange, ValuePropContainer, Tagline, ValueProp, ScrollButton} from './LandingPage.styles.js';
import Header from '../Header';
import SignupButton from '../SignupButton';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
    const { isAuthenticated } = useAuth0();
    const navigate = useNavigate();
    
    if (isAuthenticated) {
        navigate('/profile')
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return <BackgroundOrange>
            <Header showFiltraConfig={false} useBackground={false} /> 
            <Container>
                <ValuePropContainer>
                    <Tagline>
                        It's our turn to drive!
                    </Tagline>
                    <ValueProp>
                        Filtra is the career tool that puts devs in control. Skip applications. Cut out recruiter spam. Get direct, relevant offers. Always free for devs.
                    </ValueProp>
                    <SignupButton
                        cta={'quick setup'} 
                        color={'--white'}
                    />
                </ValuePropContainer>
            </Container>
            <Container>
                <ValuePropContainer>
                    <Tagline>
                        Skip applica&shy;tions!
                    </Tagline>
                    <ValueProp>
                        Filtra is a passive career development tool. Setup your account once and we'll email you when a job comes your way.
                    </ValueProp>
                    <SignupButton
                        cta={'quick setup'} 
                        color={'--white'}
                    />
                </ValuePropContainer>
            </Container>
            <Container>
                <ValuePropContainer>
                    <Tagline>
                        Cut out recruiter spam!
                    </Tagline>
                    <ValueProp>
                        Filtra's scoring and filtering algorithm will help you make quick decisions about what deserves attention. Tell us what you're looking for in a job, and we'll let you know when we see a match.
                    </ValueProp>
                    <SignupButton
                        cta={'quick setup'} 
                        color={'--white'}
                    />
                </ValuePropContainer>
            </Container>
            <Container>
                <ValuePropContainer>
                    <Tagline>
                        Direct, relevant offers!
                    </Tagline>
                    <ValueProp>
                        Filtra surfaces important info like salary and time off right up front. And, recruiters are required to follow up on all their outreach. No games here.
                    </ValueProp>
                    <SignupButton
                        cta={'quick setup'} 
                        color={'--white'}
                    />
                </ValuePropContainer>
            </Container>
            <ScrollButton
                onClick={() => { 
                    if ((window.innerHeight + window.scrollY + 200) >= document.body.scrollHeight) {
                        window.scrollTo({top: 0, behavior: 'smooth'})
                    } else {
                        window.scrollBy({top: window.innerHeight - window.innerHeight * 0.1, behavior: 'smooth'})
                    }
                }} 
            >
                &#8595;
            </ScrollButton>
        </BackgroundOrange>
}

export default LandingPage