import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background: var(--black);
    border: var(--medBorder);
    margin: 0 0 1.25rem 0;
    padding: 0.5rem;

    progress[value] {
        appearance: none;
        width: 100%;
        ::-webkit-progress-bar {
            background-color: var(--white);
        }
        ::-webkit-progress-value {
            background-color: var(--orange);
        }
        ::-moz-progress-bar {
            background-color: var(--orange);
        }
    }
`

export const Text = styled.span`
    color: var(--white);
    font-family: 'Noto Sans';
    font-size: 1em;
    margin: 0 0 0.5rem 0;
`

export const InfoText = styled.span`
    color: var(--white);
    font-family: 'Noto Sans';
    font-size: 0.75em;
    margin: 0.5rem 0 0 0;
`

export const TextBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`