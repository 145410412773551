import React from 'react';

import NumJobsPng from './numjobs.png';
import NumCompanies from './numcompanies.png';
import LargeHirers from './largehirers.png';
import OtherHirers from './otherhirers.png';
import LargeIndustries from './largeindustries.png';
import OtherIndustries from './otherindustries.png';
import Levels from './levels.png';

import RustReport from '../RustReport';


const RustReportMay23 = () => {
    return <RustReport 
        title={'Rust Jobs Report: May 2023'}
        subtitle={'Welcome to the May 2023 Rust Jobs Report. We maintain an index of companies hiring for Rust roles. Filtra has received an exciting level of support from the Rust community. As a thank you, we have elected to share this data publicly.'}
        titleNote={'To add your or another company to the index, please email filtra@filtra.io with the subject line "Rust Index"'}
        openPositionsPng={NumJobsPng}
        openPositionsDesc={"The number of jobs grew significantly in April but leveled off in May."}
        numCompaniesPng={NumCompanies}
        numCompaniesDesc={'The number of hiring companies also stayed about the same from April to May.'}
        largeHirersPng={LargeHirers}
        largeHirersDesc={"The leaderboard contains many of the usual suspects, but Microsoft and Discord had an unusually high number of Rust-related jobs this month."}
        topTenCompanies={[
            'Cloudflare',
            'Amazon',
            'Apple',
            'Microsoft',
            'Discord',
            'Fortanix',
            'Google',
            'Crypto.com',
            'Ditto',
            'Fly.io',
        ]}
        otherHirersPng={OtherHirers}
        otherHirersDesc={"Don't snooze on the long tail! Some of the coolest opportunities are hidden away at these smaller companies. If you know of a company that should be on this list, don't hesitate to point it out: filtra@filtra.io"}
        topTenIndustriesPng={LargeIndustries}
        topTenIndustriesDesc={"Many of the same industries as usual continue to account for the bulk of the Rust hiring."}
        topTenIndustries={[
            'cloud/infrastructure',
            'crypto',
            'security',
            'productivity',
            'systems/hardware',
            'browser',
            'monitoring/reliability',
            'messaging/notifications',
            'marketplace',
            'data science',
        ]}
        otherIndustriesPng={OtherIndustries}
        otherIndustriesDesc={''}
        seniorityPng={Levels}
        seniorityDesc={"Rust's emphasis on systems-level problem solving and its relative \"newness\" make it hard to find entry-level jobs."}
    />
}

export default RustReportMay23 