const handleAPIError = (err, action = 'save') => {
  // console.log(err);
  if (err?.response?.data?.status === 400) {
    // check is not robjust, don't care - good enough for right now
    if (typeof err.response.data.detail === 'object') {
      alert(`Failed to ${action}, check fields for errors.`);
      return err.response.data.detail; // map of errors to messages
    }
    else if (typeof err.response.data.detail === 'string') {
      alert(err.response.data.detail);
    }
    else {
      alert('Please correct the format of your data before submitting.');
    }
  } else {
    alert(err?.response?.data?.detail);
  }
}

export default handleAPIError;
