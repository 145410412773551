import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import handleAPIError from '../../api/error';
import Loading from '../Loading';
import { BackgroundPlain, Form, ButtonContainer, MedButton } from '../../GlobalStyle';
import RecruiterHeader from '../RecruiterHeader';
import { ProfileImageContainer, ProfileImage } from './RecruiterProfile.styles';
import ShortBasicInput from '../ShortBasicInput';
import PhotoDialog from '../PhotoDialog';
import api from '../../api';

const RecruiterProfile = () => {
    const navigate = useNavigate()
    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const id = user.sub.split('|')[1];
    const email = user.email;

    const PLACEHOLDER_IMAGE_URL = 'https://storage.googleapis.com/filtra-photos-prod/placeholder_image.png'
    const [isLoading, setIsLoading] = useState(false);
    const [showPhotoDialog, setShowPhotoDialog] = useState(false)
    const [exists, setExists] = useState(true);
    const [photoUrl, setPhotoUrl] = useState(PLACEHOLDER_IMAGE_URL);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [title, setTitle] = useState('');
    const [changedPhoto, setChangedPhoto] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const getPerson = async () => {
            setIsLoading(true);
            try {
                const claims = await getIdTokenClaims()
                if (!claims['https://filtra.io/roles'].includes('Recruiter')) {
                    navigate('/profile') 
                } else {
                    const token = await getAccessTokenSilently();
                    const response = await api.get(`/recruiters/${id}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    setPhotoUrl(response.data.photoUrl);
                    setFirstName(response.data.firstName);
                    setLastName(response.data.lastName);
                    setTitle(response.data.title);
                }
            } catch (err) {
                if (err.response.data.status === 404) {
                    setExists(false);
                } else {
                    handleAPIError(err);
                }
            }
            setIsLoading(false);
        }
        getPerson();
    }, [id, getAccessTokenSilently, getIdTokenClaims, navigate]);
    
    useEffect(() => {
        // do nothing - just updates the photo without reloading config (which is outdated url)
        // wait for the user to save, if they don't the photo won't be updated (as expected)
    }, [changedPhoto]);

    const postPerson = async (body) => {
        setIsLoading(true);
        setErrors({});
        try {
            const token = await getAccessTokenSilently()
            await api.post('/recruiters', body, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            setExists(true)
            navigate('/recruiters/companies')
        } catch (err) {
            const errorMsgs = handleAPIError(err);
            if (errorMsgs) setErrors(errorMsgs);
        }
        setIsLoading(false);
    };

    const patchPerson = async (body) => {
        setIsLoading(true);
        setErrors({});
        try {
            const token = await getAccessTokenSilently()
            await api.patch(`/recruiters/${id}`, body, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        } catch (err) {
            const errorMsgs = handleAPIError(err);
            if (errorMsgs) setErrors(errorMsgs);
        }
        setIsLoading(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const person = { id, email, firstName, lastName, title, photoUrl };
        if (exists) {
            patchPerson(person);
        } else {
            postPerson(person);
        }
    };
    
    const updatePhotoUrl = (url) => {
        setPhotoUrl(url);
        setChangedPhoto(!changedPhoto);
        if (exists) {
            const person = { id, email, firstName, lastName, title, photoUrl: url };
            patchPerson(person)
        }
    };

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <BackgroundPlain>
                    <RecruiterHeader userExists={exists}/>
                    <Form onSubmit={handleSubmit}>
                        <ProfileImageContainer
                            onClick={() => setShowPhotoDialog(true)} 
                        >
                            <ProfileImage src={photoUrl} key={photoUrl}/>
                        </ProfileImageContainer>
                        <ShortBasicInput
                            borderColor={'--orange'}
                            label={'first name'}
                            placeholder={'your first name'}
                            val={firstName}
                            onChange={setFirstName}
                            error={errors['firstName']}
                        />
                        <ShortBasicInput
                            borderColor={'--orange'}
                            label={'last name'}
                            placeholder={'your last name'}
                            val={lastName}
                            onChange={setLastName}
                            error={errors['lastName']}
                        />
                        <ShortBasicInput
                            borderColor={'--orange'}
                            label={'title'}
                            placeholder={'your job title'}
                            val={title}
                            onChange={setTitle}
                            error={errors['title']}
                        />
                        <ShortBasicInput
                            borderColor={'--orange'}
                            type="email"
                            label={'email'}
                            placeholder={'your email'}
                            val={email}
                            readOnly={true}
                        />
                        <ButtonContainer>
                            <MedButton>save</MedButton>
                        </ButtonContainer>
                    </Form>
                </BackgroundPlain>
            )}
            {showPhotoDialog ? 
                <PhotoDialog 
                    closer={setShowPhotoDialog}
                    setPhotoUrl={updatePhotoUrl}
                    identifier={id}
                    placeholderPhotoUrl={PLACEHOLDER_IMAGE_URL}
                    photoUrl={photoUrl}
                    exists={exists}
                />
            : null}
        </>
    );
};

export default withAuthenticationRequired(RecruiterProfile, {
    onRedirecting: () => <Loading />,
});
