import styled from "styled-components"

export const CompaniesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 80vw;
    min-width: 350px;
    @media (min-width: 600px) {
        width: 60vw;
    }
    @media (min-width: 1000px) {
        width: 25vw;
    }
        margin: 7rem 0 3rem 0;
`

export const AddCompanyBar = styled.button`
    border: var(--border);
    background: var(--black);
    color: var(--white);
    text-align: center;
    font-family: 'Noto Sans';
    font-weight: 600;
    font-size: 2.5em;
    min-height: 5.5rem;
    &:hover {
        font-size: 3.25em;
    }
    cursor: pointer;
`

export const DeleteButton = styled.button`
    position: absolute;
    top: 0;
    right: 0;
    color: var(--black);
    background: var(--white);
    aspect-ratio: 1;
    border: var(--smallBorder);
    outline: none;
    font-family: 'Noto Sans Mono', monospace;
    font-weight: bold;
    margin: 0;
    z-index: 1;
    cursor: pointer;
    line-height: 0;
`

export const CompanyContainer = styled.div`
    position: relative;
`
