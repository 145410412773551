import React from 'react';

import RustReportV2Ad from '../RustReportV2Ad';

const RustReportNov23 = () => {
    const NUM_JOBS = 541
    const NUM_COMPANIES = 94 
    const LARGE_HIRERS = [
        ['Amazon', 76],
        ['Microsoft', 65],
        ['Cloudflare', 44],
        ['Apple', 33],
        ['SpaceX', 24],
        ['Meta', 21],
        ['Canonical', 18],
        ['DataDog', 17],
        ['Fortanix', 17],
        ['Embark', 10],
    ]
    const OTHER_HIRERS = [
        ['Crypto.com', 8],
        ['Syndica', 8],
        ['GitHub', 7],
        ['Stockly', 6],
        ['OneSignal', 5],
        ['Ardan Labs', 5],
        ['Figma', 5],
        ['Deep Fence', 5],
        ['Volume Finance', 5],
        ['Deliveroo', 5],
        ['Discord', 5],
        ['Matic', 4],
        ['Matter Labs', 4],
        ['KeyRock', 4],
        ['Worldcoin', 4],
        ['Smarkets', 4],
        ['1Password', 4],
        ['Open Cosmos', 4],
        ['Trivago', 4],
        ['Sentry', 4],
        ['HelsingAI', 4],
        ['Replit', 3],
        ['Rapid7', 3],
        ['Google ', 3],
        ['Mozilla', 3],
        ['Liquid Analytics', 3],
        ['OVH', 3],
        ['Ledger', 3],
        ['Atlassian', 3],
        ['HealPay', 3],
        ['Zama', 3],
        ['InfinyOn', 3],
        ['Zelis', 3],
        ['OpenAI', 3],
        ['Gamaspace', 3],
        ['Lit Protocol', 2],
        ['Qdrant', 2],
        ['HyperExponential', 2],
        ['Turso', 2],
        ['IOTA Foundation', 2],
        ['EdgeDB', 2],
        ['Railway', 2],
        ['Galois', 2],
        ['InfluxDB', 2],
        ['Scaleway', 2],
        ['10X Genomics', 2],
        ['Threema', 2],
        ['Svix', 2],
        ['SmartThings', 2],
        ['Starry', 2],
        ['Akamai', 2],
        ['Qwiet AI', 2],
        ['Okta', 2],
        ['arm', 2],
        ['Ditto', 2],
        ['Qovery', 2],
        ['Astropad', 1],
        ['FP Complete', 1],
        ['Tonari', 1],
        ['TrueLayer', 1],
        ['shuttle', 1],
        ['Clever Cloud', 1],
        ['KION', 1],
        ['Star Lab', 1],
        ['Meilisearch', 1],
        ['Prisma', 1],
        ['Imperva', 1],
        ['Vivint', 1],
        ['Data Direct Networks', 1],
        ['KittyCAD', 1],
        ['UXStream', 1],
        ['Cymo', 1],
        ['Nuclia', 1],
        ['Aleph Alpha', 1],
        ['Heliax', 1],
        ['PingCAP', 1],
        ['Chorus One', 1],
        ['Grafbase', 1],
        ['Tabnine', 1],
        ['Workato', 1],
        ['Toyota Connected', 1],
        ['Activision', 1],
        ['WalletConnect', 1],
        ['Dock Financial', 1],
    ]
    const LARGE_INDUSTRIES = [
        ['cloud/infrastructure', 139],
        ['productivity', 82],
        ['systems/hardware', 54],
        ['crypto', 42],
        ['security', 41],
        ['aerospace', 31],
        ['social media', 21],
        ['monitoring/reliability', 21],
        ['data science', 21],
        ['marketplace', 15],
    ]
    const OTHER_INDUSTRIES = [
        ['messaging/notifications', 12],
        ['gaming', 11],
        ['fintech', 11],
        ['iot/robotics/automotive', 9],
        ['databases', 8],
        ['consultancy', 8],
        ['browser', 6],
        ['health/biotech', 5],
        ['animation', 2],
        ['networking', 2],
    ]
    const LEVELS = [
        ['junior', 25],
        ['mid', 290],
        ['senior', 226],
    ]
    return <RustReportV2Ad
        title={'Rust Jobs Report: November 2023'}
        subtitle={'Welcome to the November 2023 Rust Jobs Report by filtra. Please enjoy our overview of the Rust job market.'}
        titleNote={'To add your or another company to the filtra Rust jobs index, please email filtra@filtra.io with the subject line "Rust Index"'}
        openPositions={NUM_JOBS}
        openPositionsDesc={'November marks another record month for the Rust Jobs Report. The total number of jobs we found took a big step up from 460 to 541.'}
        numCompanies={NUM_COMPANIES}
        numCompaniesDesc={'Some of the growth in overall job postings can be attributed to new hirers. We found 7 more this month compared to last month.'}
        largeHirers={LARGE_HIRERS}
        largeHirersDesc={'Listings at Amazon, Microsoft, and Apple all grew significantly in November. The top ten also includes newcomer Canonical this month.'}
        otherHirers={OTHER_HIRERS}
        otherHirersDesc={"Thanks to community submissions, the index of hirers continues to grow. Newcomers this month include Open Cosmos, TrueLayer, HyperExponential, and Dock Financial. If you know of a company that should be on this list, don't hesitate to point it out: filtra@filtra.io"}
        topTenIndustries={LARGE_INDUSTRIES}
        topTenIndustriesDesc={'The relative importance of crypto continues to diminish, moving into third last month and now fourth this month.'}
        otherIndustries={OTHER_INDUSTRIES}
        otherIndustriesDesc={'The general distribution of Rust jobs across industries remains stable.'}
        seniority={LEVELS}
        seniorityDesc={"The Rust job market continues to favor more experience engineers."}
    />
}

export default RustReportNov23 