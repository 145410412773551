import React from 'react';

import NumJobsPng from './numjobs.png';
import NumCompanies from './numcompanies.png';
import LargeHirers from './largehirers.png';
import OtherHirers from './otherhirers.png';
import LargeIndustries from './largeindustries.png';
import OtherIndustries from './otherindustries.png';
import Levels from './levels.png';

import RustReport from '../RustReport';


const RustReportAug23 = () => {
    return <RustReport 
        title={'Rust Jobs Report: August 2023'}
        subtitle={'Welcome to the August 2023 Rust Jobs Report. We maintain an index of companies hiring for Rust roles. Filtra has received an exciting level of support from the Rust community. As a thank you, we have elected to share this data publicly.'}
        titleNote={'To add your or another company to the index, please email filtra@filtra.io with the subject line "Rust Index"'}
        openPositionsPng={NumJobsPng}
        openPositionsDesc={'The number of jobs fell slightly again month over month.'}
        numCompaniesPng={NumCompanies}
        numCompaniesDesc={'The number of hirers grew slightly in August.'}
        largeHirersPng={LargeHirers}
        largeHirersDesc={"The list of large hirers didn't change much in August. However, it's worth noting that Cloudflare seems to be reducing their number of openings."}
        topTenCompanies={[
            'Amazon',
            'Cloudflare',
            'SpaceX',
            'DataDog',
            'Meta',
            'Fortanix',
            'Google',
            'KeyRock',
            'Discord',
            'Apple',
        ]}
        otherHirersPng={OtherHirers}
        otherHirersDesc={"Thanks to community submissions, the index of hirers continues to grow. Newcomers this month include Oxide, KittyCAD, and FP Complete. If you know of a company that should be on this list, don't hesitate to point it out: filtra@filtra.io"}
        topTenIndustriesPng={LargeIndustries}
        topTenIndustriesDesc={'The group of industries that tend to hire Rust engineers the most continues to be stable with cloud/infrastructure, crypto, and security still leading the way.'}
        topTenIndustries={[
            'cloud/infrastructure',
            'crypto',
            'security',
            'aerospace',
            'monitoring/reliability',
            'social media',
            'browser',
            'systems/hardware',
            'messaging/notifications',
            'data science',
        ]}
        otherIndustriesPng={OtherIndustries}
        otherIndustriesDesc={''}
        seniorityPng={Levels}
        seniorityDesc={"Hirers remain focused on tapping engineers with prior experience in other stacks for their Rust roles."}
    />
}

export default RustReportAug23