import React, { useState, useEffect } from "react";
import { SubsectionHeader, Form, ButtonContainer, SectionContainer, MedButton, BackgroundPlain, InfoBox, Required } from '../../GlobalStyle';
import Loading from '../Loading';
import CheckBoxInput from "../CheckBoxInput";
import ShortBasicInput from '../ShortBasicInput';
import MultipleOptionInput from "../MultipleOptionInput";
import PanelInput from "../PanelInput";
import api from "../../api";
import { useNavigate, useParams } from "react-router-dom";
import handleAPIError from "../../api/error";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import RecruiterHeader from "../RecruiterHeader";
import { BoldInfoBox, BoldLink } from "./Job.styles";
import { LabelError } from '../ShortBasicInput/ShortBasicInput.styles';

const Job = () => {
    const navigate = useNavigate()
    const id = useParams()['jobId']
    const companyId = useParams()['companyId']
    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const recruiterId = user.sub.split('|')[1];

    const [isLoading, setIsLoading] = useState(false)
    const [exists, setExists] = useState('true')
    const [title, setTitle] = useState('')
    const [minSalary, setMinSalary] = useState('')
    const [maxSalary, setMaxSalary] = useState('')
    const [stockOrEquity, setStockOrEquity] = useState(false)
    const [paidTimeOff, setPaidTimeOff] = useState('')
    const [parentalLeave, setParentalLeave] = useState('')
    const [health, setHealth] = useState(false)
    const [life, setLife] = useState(false)
    const [dental, setDental] = useState(false)
    const [retirement, setRetirement] = useState(false)
    const [vision, setVision] = useState(false)
    const [tuition, setTuition] = useState(false)
    const [workType, setWorkType] = useState([])
    const [skills, setSkills] = useState([])
    const [workPlace, setWorkPlace] = useState([])
    const [locations, setLocations] = useState([])
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const getJob = async () => {
            setIsLoading(true);
            try {
                const claims = await getIdTokenClaims()
                if (!claims['https://filtra.io/roles'].includes('Recruiter')) {
                    navigate('/profile') 
                } else {
                    const token = await getAccessTokenSilently();
                    const response = await api.get(
                        `/recruiters/${recruiterId}/companies/${companyId}/jobs/${id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    setTitle(response.data.title);
                    setMinSalary(response.data.minSalary);
                    setMaxSalary(response.data.maxSalary);
                    setStockOrEquity(response.data.stockOrEquity);
                    setPaidTimeOff(response.data.paidTimeOff);
                    setParentalLeave(response.data.parentalLeave);
                    setHealth(response.data.health);
                    setLife(response.data.life);
                    setDental(response.data.dental);
                    setRetirement(response.data.retirement);
                    setVision(response.data.vision);
                    setTuition(response.data.tuition);
                    setWorkType(response.data.workType);
                    if (response.data.skills !== undefined) {
                        setSkills(response.data.skills);
                    }
                    setWorkPlace(response.data.workPlace);
                    if (response.data.locations !== undefined) {
                        setLocations(response.data.locations);
                    }
                }
            } catch (err) {
                if (err.response.data.status === 404) {
                    setExists(false);
                } else {
                    handleAPIError(err);
                }
            }
            setIsLoading(false);
        };
        getJob();
    }, [id, companyId, recruiterId, getAccessTokenSilently, getIdTokenClaims, navigate]);

    const postJob = async (body) => {
        setIsLoading(true);
        setErrors({});
        try {
            const token = await getAccessTokenSilently();
            await api.post(`/recruiters/${recruiterId}/companies/${companyId}/jobs`, body, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setExists(true);
        } catch (err) {
            const errorMsgs = handleAPIError(err);
            if (errorMsgs) setErrors(errorMsgs);
        }
        setIsLoading(false);
    };

    const patchJob = async (body) => {
        setIsLoading(true);
        setErrors({});
        try {
            const token = await getAccessTokenSilently();
            await api.patch(`/recruiters/${recruiterId}/companies/${companyId}/jobs/${id}`, body, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        } catch (err) {
            const errorMsgs = handleAPIError(err);
            if (errorMsgs) setErrors(errorMsgs);
        }
        setIsLoading(false);
    };

    const getSubmittableSkills = () => {
        const submittableSkills = skills.filter(skill => skill.title.length > 0)
        setSkills(submittableSkills)
        return submittableSkills
    }

    const getSubmittableLocations = () => {
        const submittableLocations = locations.filter(location => location.name.length > 0)
        locations.forEach(location => delete location['radius'])
        setLocations(submittableLocations)
        return submittableLocations
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const job = {
            id: id,
            title: title,
            minSalary: parseInt(minSalary),
            maxSalary: parseInt(maxSalary),
            stockOrEquity: stockOrEquity,
            paidTimeOff: parseInt(paidTimeOff),
            parentalLeave: parseInt(parentalLeave),
            health: health,
            life: life,
            dental: dental,
            retirement: retirement,
            vision: vision,
            tuition: tuition,
            workType: workType,
            skills: getSubmittableSkills(),
            workPlace: workPlace,
            locations: getSubmittableLocations(),
        };
        if (exists) {
            patchJob(job);
        } else {
            postJob(job);
        }
    };

    const addSkillPanel = () => {
        if (skills.filter(skill => skill.title === '').length === 0) {
            const newSkills = skills.concat([{ title: '', skillLevel: 'interested'}])
            setSkills(newSkills)
        }
    }

    const deleteSkillPanel = (index) => {
        const newSkills = skills.filter((skill, idx) => {
            return idx !== index
        })
        setSkills(newSkills)
    }

    const addLocationPanel = () => {
        if (locations.filter(location => location.name === '').length === 0) {
            const newLocations = locations.concat([{ name: '', radius: 50}])
            setLocations(newLocations)
        }
    }

    const deleteLocationPanel = (index) => {
        const newLocations = locations.filter((location, idx) => {
            return idx !== index
        })
        setLocations(newLocations)
    }

    return <>
        {isLoading ?  <Loading /> :
            <BackgroundPlain>
                    <RecruiterHeader userExists={true}/>
                    <Form onSubmit={handleSubmit}>
                        {exists ? 
                            <BoldInfoBox>
                                <BoldLink to={`/recruiters/search?c=${companyId}&j=${id}&p=${0}&ic=false`}>search</BoldLink>matching candidates
                            </BoldInfoBox>
                        : null}
                        <InfoBox>
                            <Required highlightColor={'--orange'}>Note that all fields are required</Required>
                        </InfoBox>
                        <SectionContainer>
                            <SubsectionHeader
                                borderColor={'--green'} 
                            >description</SubsectionHeader>
                            <InfoBox></InfoBox>
                            <ShortBasicInput 
                                type={'text'}
                                borderColor={'--green'}
                                label={'title'}
                                placeholder={'the job title'}
                                val={title}
                                onChange={setTitle}
                                error={errors['title']}
                            />
                        </SectionContainer>
                        <SectionContainer>
                            <SubsectionHeader borderColor={'--orange'}>
                                skills
                                <LabelError fontSize='0.5em'>{errors['skills']}</LabelError>
                            </SubsectionHeader>
                            <InfoBox>List the tech skills you require. Note the minimum required skill level on the scale of interested to expert.</InfoBox>
                            <PanelInput 
                                accentColor={'--orange'}
                                isEnum={true}
                                vals={skills} 
                                enumOptions={['interested', 'beginner', 'intermediate', 'proficient', 'expert']}
                                onAddPanel={addSkillPanel}
                                placeHolder={'skill'}
                                fieldNames={['title', 'skillLevel']}
                                onDeletePanel={deleteSkillPanel}
                                onChange={setSkills}
                                panelType={'skill'}
                                error={errors['title']}
                            />
                        </SectionContainer>
                        <SectionContainer>
                            <SubsectionHeader
                                borderColor={'--navy'} 
                            >compensation</SubsectionHeader>
                            <InfoBox>List the low end of the salary range.</InfoBox>
                            <ShortBasicInput 
                                type={'number'}
                                borderColor={'--navy'}
                                label={'min. salary ($)'}
                                placeholder={'minimum salary for the position'}
                                val={minSalary}
                                onChange={setMinSalary}
                                error={errors['minSalary']}
                            /> 
                            <InfoBox>List the high end of the salary range.</InfoBox>
                            <ShortBasicInput 
                                type={'number'}
                                borderColor={'--navy'}
                                label={'max. salary ($)'}
                                placeholder={'maximum salary for the position'}
                                val={maxSalary}
                                onChange={setMaxSalary}
                                error={errors['maxSalary']}
                            /> 
                            <InfoBox>May candidates be compensated with ownership? Check if yes.</InfoBox>
                            <CheckBoxInput 
                                accentColor={'--navy'}
                                label={'stock or equity'} 
                                checked={stockOrEquity}
                                onChange={setStockOrEquity}
                                error={errors['stockOrEquity']}
                            />
                        </SectionContainer>
                        <SectionContainer>
                            <SubsectionHeader
                                borderColor={'--green'} 
                            >benefits</SubsectionHeader>
                            <InfoBox>
                                List the time off policies. 
                            </InfoBox>
                            <ShortBasicInput 
                                borderColor={'--green'} 
                                label={'paid time off (wks)'}
                                type={'number'}
                                placeholder={'number of paid weeks off'}
                                val={paidTimeOff}
                                onChange={setPaidTimeOff}
                                error={errors['paidTimeOff']}
                            />
                            <ShortBasicInput 
                                borderColor={'--green'} 
                                label={'parental leave (wks)'}
                                type={'number'}
                                placeholder={'number of paid weeks provided'}
                                val={parentalLeave}
                                onChange={setParentalLeave}
                                error={errors['parentalLeave']}
                            />
                            <InfoBox>Mark all applicable benefits.</InfoBox>
                            <CheckBoxInput 
                                accentColor={'--green'}
                                label={'health'} 
                                checked={health}
                                onChange={setHealth}
                                error={errors['health']}
                            />
                            <CheckBoxInput 
                                accentColor={'--green'}
                                label={'life'} 
                                checked={life}
                                onChange={setLife}
                                error={errors['life']}
                            />
                            <CheckBoxInput 
                                accentColor={'--green'}
                                label={'dental'} 
                                checked={dental}
                                onChange={setDental}
                                error={errors['dental']}
                            />
                            <CheckBoxInput 
                                accentColor={'--green'}
                                label={'retirement'} 
                                checked={retirement}
                                onChange={setRetirement}
                                error={errors['retirement']}
                            />
                            <CheckBoxInput 
                                accentColor={'--green'}
                                label={'vision'} 
                                checked={vision}
                                onChange={setVision}
                                error={errors['vision']}
                            />
                            <CheckBoxInput 
                                accentColor={'--green'}
                                label={'tuition'} 
                                checked={tuition}
                                onChange={setTuition}
                                error={errors['tuition']}
                            />
                        </SectionContainer>
                        <SectionContainer>
                            <SubsectionHeader
                                borderColor={'--orange'} 
                            >work type</SubsectionHeader>
                            <InfoBox>Check all applicable work types.</InfoBox>
                            <MultipleOptionInput 
                                accentColor={'--orange'}
                                label={'workType'} 
                                val={workType}
                                options={[
                                    'fullTime', 
                                    'partTime', 
                                    'volunteer', 
                                ]}
                                onChange={setWorkType}
                                error={errors['workType']}
                            />
                        </SectionContainer>
                        <SectionContainer>
                            <SubsectionHeader
                                borderColor={'--navy'} 
                            >work place</SubsectionHeader>
                            <InfoBox>Check all possible work places.</InfoBox>
                            <MultipleOptionInput 
                                accentColor={'--navy'}
                                label={'workPlace'} 
                                val={workPlace}
                                options={['inPerson', 'hybrid', 'remote']}
                                onChange={setWorkPlace}
                                error={errors['workPlace']}
                            />
                        </SectionContainer>
                                                {(workPlace.includes('inPerson') ||
                            workPlace.includes('hybrid')) ? 
                                <SectionContainer>
                                    <SubsectionHeader borderColor={'--orange'}>
                                        locations
                                        <LabelError fontSize='0.5em'>{errors['locations']}</LabelError>
                                    </SubsectionHeader>
                                    <InfoBox>List all office locations.</InfoBox>
                                    <PanelInput
                                        accentColor={'--orange'}
                                        isEnum={false}
                                        vals={locations}
                                        discreteRange={300}
                                        onAddPanel={addLocationPanel}
                                        placeHolder={{
                                            name: 'city',
                                        }}
                                        fieldNames={['name', 'radius', 'lat', 'lng', ]}
                                        onDeletePanel={deleteLocationPanel}
                                        onChange={setLocations}
                                        showRadius={false}
                                    />
                                </SectionContainer>
                            : null}
                        <ButtonContainer>
                            <MedButton>save</MedButton>
                        </ButtonContainer>
                    </Form> 
            </BackgroundPlain>
        } 
    </>
}

export default withAuthenticationRequired(Job, {
    onRedirecting: () => <Loading />,
});
