import React, { useState, useEffect } from 'react';
import { BackgroundPlain } from '../../GlobalStyle';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import Loading from '../Loading';
import RecruiterHeader from '../RecruiterHeader';
import { useNavigate } from 'react-router-dom';
import handleAPIError from '../../api/error';
import SearchResultsTable from '../SearchResultsTable';

const SearchResults = () => {
    const { getIdTokenClaims } = useAuth0()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const checkRole = async () => {
            setIsLoading(true);
            try {
                const claims = await getIdTokenClaims()
                if (!claims['https://filtra.io/roles'].includes('Recruiter')) {
                    navigate('/profile') 
                } 
            } catch (err) {
                handleAPIError(err);
            }
            setIsLoading(false);
        }
        checkRole();
    }, [getIdTokenClaims, navigate]);

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <BackgroundPlain>
                    <RecruiterHeader userExists={true}/>
                    <SearchResultsTable/>
                </BackgroundPlain>
            )}
        </>
    );
};

export default withAuthenticationRequired(SearchResults, {
    onRedirecting: () => <Loading />,
});
