import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 90vh;
    width: 100%;
`

export const Background = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--grey-100);
  width: 100vw;
  min-height: 100vh;
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 1000px) {
        min-width: 60%;
        max-width: 60%;
    }
    min-width: 80%;
    max-width: 80%;
`

export const Title = styled.h1`
    color: var(--orange);
    font-family: 'Noto Sans Mono', monospace;
    font-size: 3.5em;
    font-weight: 500;
    margin-bottom: 3rem;
`

export const Subtitle = styled.h3`
    color: var(--orange);
    font-family: 'Noto Sans Mono', monospace;
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 3rem;
`

export const TitleNote = styled.h3`
    color: var(--grey-500);
    font-family: 'Noto Sans Mono', monospace;
    font-size: 0.6em;
    font-weight: 500;
    margin-bottom: 3rem;
    align-self: flex-start;
`

export const GraphDescription = styled.p`
    color: var(--black);
    font-family: 'Noto Sans Mono', monospace;
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 0;
    width: 100%;
    @media (min-width: 1000px) {
        width: 60%;
    }
    hyphens: auto;
`

export const OrangeLink = styled(Link)`
    color: var(--orange);
    font-family: 'Noto Sans Mono', monospace; 
    font-weight: 500;
    font-size: 2em;
    margin: 1rem
`
