import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: var(--white);
    background: var(--black);
`

export const Input = styled.input`
    width: 90%;
    padding: 1% 12% 1% 12%;
    color: var(--black);
    font-family: 'Noto Sans';
    font-size: 1em;
    font-weight: 600;
    background: var(--white);
    text-align: center;
    outline: none;
    border: none;
    margin: 6px 6px 0 6px;
    :focus {
      ::placeholder,
      ::-webkit-input-placeholder {
        color: transparent;
      }
      :-ms-input-placeholder {
        color: transparent;
      }
    }
    &:focus {
        color: var(--white);
        background-color: var(--grey-600);
        font-weight: 500;
        ::placeholder {
            color: var(--grey-200);
        }
    }
    &:hover {
        ${({ readOnly }) => !readOnly && `
          color: var(--white);
          background-color: var(--grey-600);
          font-weight: 500;
          ::placeholder {
              color: var(--grey-200);
          }
        `}
    }

`

export const RangeInput = styled.input`
    -webkit-appearance: none;
    height: 10px;
    background: var(--white);
    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 15px;
        aspect-ratio: 1;
        color: var(--grey-400);
        background-color: var(--grey-400);
        background: var(--grey-400);
        cursor: pointer;
    }
    &::-moz-range-thumb {
      -webkit-appearance: none;
      height: 15px;
      aspect-ratio: 1;
      color: var(--grey-400);
      background-color: var(--grey-400);
      background: var(--grey-400);
      border-radius: 0;
      border: none;
    }
    margin: 1rem;
    &:hover {
      &::-webkit-slider-thumb {
        height: 17px;
        -webkit-appearance: none;
        aspect-ratio: 1;
      }
    }
    font-size: 0.65em;
`

export const Span = styled.span`
    font-family: 'Noto Sans Mono', monospace;
    margin-bottom: 1rem;
`

export const DeleteButton = styled.button`
    position: absolute;
    top: 0;
    left: 0;
    color: var(--black);
    background: var(--white);
    aspect-ratio: 1;
    border: var(--smallBorder);
    outline: none;
    font-family: 'Noto Sans Mono', monospace;
    font-weight: bold;
    cursor: pointer;
    line-height: 0;
`

export const Ul = styled.ul`
  background-color: var(--white);
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: 100%;
  min-width: 150px;
  position: absolute;
  top: 1;
  li {
    border-bottom: 1px solid var(--grey-100);
  }
  li :last-child {
    border-bottom: none;
  }
`;

export const Li = styled.li`
  color: var(--black);
  font-family: 'Noto Sans';
  font-weight: 600;
  padding: 0.5rem;
  &:hover {
    cursor: pointer;
    background-color: var(--grey-400);
  }
  &:active {
    background-color: var(--grey-400);
  }
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 6px;
    min-width: 90%;
`

export const OnBlurDiv = styled.div`
  width: 100%;
`

export const SpecialInputContainer = styled.div`
  display: flex;
  justify-content: center;
`