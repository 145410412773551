import styled from 'styled-components';

export const LinkContainer = styled.div`
    position: relative;
`

export const CopyButton = styled.button`
    position: absolute;
    top: 0.5em;
    right: 0.35em;
    color: var(--black);
    background-color: var(--white);
    font-family: 'Noto Sans Mono', monospace;
    font-size: 0.75em;
    font-weight: bold;
    border: var(--smallBorder);
    cursor: pointer;
    &:active {
        border-color: var(--grey-500);
    }
`

export const CopyIcon = styled.img`
    height: 1em;
    aspect-ratio: 1;
`