import React from 'react';
import { Container, Label, TextArea, LabelError } from './CharLimitInput.styles.js';

const CharLimitInput = ({ label, placeholder, val, onChange, readOnly, charLimit, error }) => {
    return <Container>
        <Label>
            { label }
            <LabelError>{ error }</LabelError>
        </Label>
        <TextArea
            value={val}
            placeholder={placeholder}
            onChange={({ target }) => {
                onChange(target.value)
            }}
            readOnly={readOnly}
            rows={3}
            maxLength={charLimit}
        />
    </Container>
}

export default CharLimitInput;
