import React from 'react';

import RustReportV2Ad from '../RustReportV2Ad';

const RustReportJan24 = () => {
    const NUM_JOBS = 611 
    const NUM_COMPANIES = 89 
    const LARGE_HIRERS = [
        ['Amazon', 98],
        ['Microsoft', 73],
        ['Cloudflare', 64],
        ['Apple', 42],
        ['DataDog', 23],
        ['Meta', 22],
        ['Canonical', 19],
        ['Fortanix', 17],
        ['Stockly', 16],
        ['Crypto.com', 14],
    ]
    const OTHER_HIRERS = [
        ['IBM', 13],
        ['Syndica', 9],
        ['Workato', 8],
        ['HelsingAI', 8],
        ['Matter Labs', 7],
        ['Figma', 6],
        ['GitHub', 6],
        ['KeyRock', 6],
        ['Deep Fence', 5],
        ['Volume Finance', 5],
        ['Matic', 5],
        ['Akamai', 5],
        ['Atlassian', 4],
        ['Ditto', 4],
        ['SpaceX', 4],
        ['Deliveroo', 4],
        ['OneSignal', 4],
        ['Ardan Labs', 4],
        ['Mozilla', 3],
        ['Google ', 3],
        ['Liquid Analytics', 3],
        ['InfinyOn', 3],
        ['SmartThings', 3],
        ['Rapid7', 3],
        ['IOTA Foundation', 3],
        ['Worldcoin', 3],
        ['Chorus One', 3],
        ['Gamaspace', 3],
        ['HyperExponential', 3],
        ['Svix', 3],
        ['HealPay', 3],
        ['arm', 3],
        ['1Password', 3],
        ['Scythe Robotics', 3],
        ['Imperva', 3],
        ['OVH', 3],
        ['Smarkets', 2],
        ['OpenAI', 2],
        ['Qdrant', 2],
        ['Railway', 2],
        ['Neurelo', 2],
        ['Zelis', 2],
        ['Discord', 2],
        ['Trivago', 2],
        ['SurrealDB', 2],
        ['Replit', 2],
        ['Clever Cloud', 2],
        ['Ledger', 2],
        ['Embark', 2],
        ['Samsung', 2],
        ['Qovery', 2],
        ['PingCAP', 2],
        ['Starry', 2],
        ['Zama', 2],
        ['Qwiet AI', 2],
        ['10X Genomics', 1],
        ['Aleph Alpha', 1],
        ['Zoo', 1],
        ['AppSignal', 1],
        ['FP Complete', 1],
        ['Okta', 1],
        ['Tonari', 1],
        ['Turso', 1],
        ['Star Lab', 1],
        ['Heliax', 1],
        ['Toyota Connected', 1],
        ['Grafbase', 1],
        ['Threema', 1],
        ['Activision', 1],
        ['Signal', 1],
        ['DSCVR', 1],
        ['Astropad', 1],
        ['WalletConnect', 1],
        ['UXStream', 1],
        ['Fullstory', 1],
        ['Scaleway', 1],
        ['KION', 1],
        ['shuttle', 1],
        ['Sentry', 1],
    ]
    const LARGE_INDUSTRIES = [
        ['cloud/infrastructure', 183],
        ['productivity', 98],
        ['systems/hardware', 67],
        ['crypto', 54],
        ['security', 43],
        ['monitoring/reliability', 25],
        ['social media', 22],
        ['marketplace', 22],
        ['data science', 20],
        ['consultancy', 18],
    ]
    const OTHER_INDUSTRIES = [
        ['iot/robotics/automotive', 13],
        ['fintech', 8],
        ['messaging/notifications', 8],
        ['databases', 7],
        ['aerospace', 7],
        ['browser', 6],
        ['gaming', 3],
        ['health/biotech', 3],
        ['animation', 2],
        ['networking', 2],
    ]
    const LEVELS = [
        ['junior', 24],
        ['mid', 333],
        ['senior', 254],
    ]
    return <RustReportV2Ad
        title={'Rust Jobs Report: January 2024'}
        subtitle={'Welcome to the January 2024 Rust Jobs Report by filtra. Please enjoy our overview of the Rust job market.'}
        titleNote={'To add your or another company to the filtra Rust jobs index, please email filtra@filtra.io with the subject line "Rust Index"'}
        openPositions={NUM_JOBS}
        openPositionsDesc={'Apparently there are still people out there saying there are no jobs in Rust. Actually, there are at least 611! Next time your coworker says there are no jobs in Rust, send \'em this.'}
        numCompanies={NUM_COMPANIES}
        numCompaniesDesc={'Rust ain\'t no Javascript, but 89 companies using Rust is nothing to scoff at! And, remember, these are just the companies that are CURRENTLY HIRING for Rust devs.'}
        largeHirers={LARGE_HIRERS}
        largeHirersDesc={'If your coworker wants to say that Rust is only used by "startups", tell them to scroll down here. The list of the top ten Rust hirers has more in common with the Fortune 500 than a YC Batch.'}
        otherHirers={OTHER_HIRERS}
        otherHirersDesc={"The list of other Rust hirers includes many smaller companies and some behemoths slowly bulking up on Rust skills. If we missed a company on this list, don't hesitate to point it out: filtra@filtra.io."}
        topTenIndustries={LARGE_INDUSTRIES}
        topTenIndustriesDesc={'Rust is popular in crypto. But, it\'s much more popular with the giants building the infrastructure of the internet like Amazon and Cloudflare.'}
        otherIndustries={OTHER_INDUSTRIES}
        otherIndustriesDesc={'Rust has reached a point in it\'s maturity where it\'s pretty much being used for everything you can think of.'}
        seniority={LEVELS}
        seniorityDesc={"Rust jobs definitely skew toward the side of requiring more experience. This has to be due in part to the fact that companies favor Rust's memory safety and efficiency for mission-critical applications. If the language becomes more ubiquitous, the proportion of entry-level jobs will probably grow too."}
    />
}

export default RustReportJan24 