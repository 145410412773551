import React from 'react';

import NumJobsPng from './numjobs.png';
import NumCompanies from './numcompanies.png';
import LargeHirers from './largehirers.png';
import OtherHirers from './otherhirers.png';
import LargeIndustries from './largeindustries.png';
import OtherIndustries from './otherindustries.png';
import Levels from './levels.png';

import RustReport from '../RustReport';


const RustReportJul23 = () => {
    return <RustReport 
        title={'Rust Jobs Report: July 2023'}
        subtitle={'Welcome to the July 2023 Rust Jobs Report. We maintain an index of companies hiring for Rust roles. Filtra has received an exciting level of support from the Rust community. As a thank you, we have elected to share this data publicly.'}
        titleNote={'To add your or another company to the index, please email filtra@filtra.io with the subject line "Rust Index"'}
        openPositionsPng={NumJobsPng}
        openPositionsDesc={"The number of jobs fell slightly in July when compared to June's 394."}
        numCompaniesPng={NumCompanies}
        numCompaniesDesc={'The number of hirers remains remarkably stable month to month at ~72.'}
        largeHirersPng={LargeHirers}
        largeHirersDesc={"Amazon continues to quietly post Rust jobs in large numbers, even slightly increasing total listings from last month."}
        topTenCompanies={[
            'Amazon',
            'Cloudflare',
            'SpaceX',
            'Fortanix',
            'Google',
            'Discord',
            'Meta',
            'DataDog',
            'KeyRock',
            'Embark',
        ]}
        otherHirersPng={OtherHirers}
        otherHirersDesc={"Thanks to community submissions, the index of hirers continues to grow. Newcomers this month include Neurelo and Cymo. If you know of a company that should be on this list, don't hesitate to point it out: filtra@filtra.io"}
        topTenIndustriesPng={LargeIndustries}
        topTenIndustriesDesc={"Amazon and Cloudflare are the one-two punch that cements cloud/infrastructure as the largest industry using Rust. Crypto, once a close second, is now a distant second. Social media also made a surprise showing on this month's leader board."}
        topTenIndustries={[
            'cloud/infrastructure',
            'crypto',
            'security',
            'aerospace',
            'browser',
            'messaging/notifications',
            'systems/hardware',
            'monitoring/reliability',
            'social media',
            'productivity',
        ]}
        otherIndustriesPng={OtherIndustries}
        otherIndustriesDesc={''}
        seniorityPng={Levels}
        seniorityDesc={"Sorry juniors! No good news on the seniority level front. You'll most likely still have to cut your teeth with another stack before working in Rust."}
    />
}

export default RustReportJul23 