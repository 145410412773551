import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Logo = styled.img`
    display: flex;
    width: 100%;
    margin: 0 0 2rem 0;
`

export const BoldInfoBox = styled.p`
  color: var(--grey-500);
  font-family: 'Noto Sans';
  font-size: 1.25em;
  font-weight: 500;
  padding: 0.3em;
  margin-bottom: 1rem;
`;

export const BoldLink = styled(Link)`
    color: var(--orange);
    font-family: 'Noto Sans'; 
    font-size: 1em;
    font-weight: 500;
    margin: 0 0.5em 0 0.5em;
`