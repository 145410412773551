import React from 'react';

import RustReportV2Ad from '../RustReportV2Ad';

const RustReportJul24 = () => {
    const NUM_JOBS = 801
    const NUM_COMPANIES = 111
    const LARGE_HIRERS = [
        ['Amazon', 176],
        ['Microsoft', 119],
        ['DataDog', 46],
        ['Apple', 37],
        ['Cloudflare', 29],
        ['Canonical', 26],
        ['SpaceX', 17],
        ['Worldcoin', 15],
        ['Google ', 13],
        ['Discord', 13],
    ]
    const OTHER_HIRERS = [
        ['Figma', 11],
        ['KeyRock', 11],
        ['Tesla', 10],
        ['IBM', 10],
        ['Mozilla', 9],
        ['Parity', 9],
        ['HelsingAI', 8],
        ['Crypto.com', 8],
        ['Open Cosmos', 7],
        ['Fortanix', 7],
        ['Deliveroo', 7],
        ['Syndica', 7],
        ['Smarkets', 6],
        ['GitHub', 6],
        ['Ardan Labs', 5],
        ['ApolloGraphQL', 5],
        ['IOHK', 5],
        ['Zama', 5],
        ['Okta', 5],
        ['Volume Finance', 5],
        ['Deep Fence', 5],
        ['Matter Labs', 4],
        ['HyperExponential', 4],
        ['Matic', 4],
        ['Dropbox', 4],
        ['Stockly', 4],
        ['Aztec', 4],
        ['informal', 4],
        ['Osmosis Labs', 4],
        ['Ellipsis Labs', 4],
        ['Scaleway', 3],
        ['HealPay', 3],
        ['InfinyOn', 3],
        ['Vivint', 3],
        ['Heliax', 3],
        ['Imperva', 3],
        ['Akamai', 3],
        ['Conduit', 3],
        ['Svix', 3],
        ['OneSignal', 3],
        ['Axiom', 3],
        ['Qdrant', 3],
        ['1Password', 3],
        ['Zoo', 3],
        ['OpenTalk', 2],
        ['IOTA Foundation', 2],
        ['UXStream', 2],
        ['InfluxDB', 2],
        ['Railway', 2],
        ['Workato', 2],
        ['amo', 2],
        ['Chorus One', 2],
        ['Uniswap Labs', 2],
        ['Zelis', 2],
        ['Magic Eden', 2],
        ['Scythe Robotics', 2],
        ['LINE', 2],
        ['Hugging Face', 2],
        ['Meta', 2],
        ['PayPal', 2],
        ['10X Genomics', 2],
        ['PingCAP', 2],
        ['Embark', 2],
        ['Atlassian', 2],
        ['Qovery', 2],
        ['Fullstory', 2],
        ['Ditto', 2],
        ['Sentry', 2],
        ['Clever Cloud', 1],
        ['ZORA', 1],
        ['Rapid7', 1],
        ['Blowfish', 1],
        ['Qumulo', 1],
        ['Shadow', 1],
        ['Materialize', 1],
        ['Fireblocks', 1],
        ['Argent', 1],
        ['Ledger', 1],
        ['Signal', 1],
        ['Tonari', 1],
        ['StarkWare', 1],
        ['Volka', 1],
        ['Liquid Analytics', 1],
        ['Star Lab', 1],
        ['Immunant', 1],
        ['Matchday', 1],
        ['DSCVR', 1],
        ['shuttle', 1],
        ['Meilisearch', 1],
        ['Astropad', 1],
        ['Cymo', 1],
        ['Toyota Connected', 1],
        ['FP Complete', 1],
        ['OpenAI', 1],
        ['Threema', 1],
        ['Grafbase', 1],
        ['Swift Navigation', 1],
        ['Prisma', 1],
        ['Qwiet AI', 1],
        ['KION', 1],
        ['AppSignal', 1],
    ]
    const LARGE_INDUSTRIES = [
        ['cloud/infrastructure', 225],
        ['productivity', 143],
        ['crypto', 102],
        ['systems/hardware', 64],
        ['monitoring/reliability', 49],
        ['security', 37],
        ['aerospace', 24],
        ['messaging/notifications', 22],
        ['browser', 22],
        ['data science', 22],
    ]
    const OTHER_INDUSTRIES = [
        ['iot/robotics/automotive', 21],
        ['consultancy', 17],
        ['fintech', 15],
        ['databases', 11],
        ['marketplace', 11],
        ['social media', 4],
        ['gaming', 4],
        ['health/biotech', 4],
        ['animation', 3],
        ['networking', 1],
    ]
    const LEVELS = [
        ['junior', 8],
        ['mid', 421],
        ['senior', 372],
    ]
    return <RustReportV2Ad
        title={'Rust Jobs Report: July 2024'}
        subtitle={'Welcome to the July 2024 Rust Jobs Report by filtra. Please enjoy our overview of the Rust job market.'}
        titleNote={'To add your or another company to the filtra Rust jobs index, please email filtra@filtra.io with the subject line "Rust Index"'}
        openPositions={NUM_JOBS}
        openPositionsDesc={'July saw a jump in total postings from 776 in June to 801 this month!'}
        numCompanies={NUM_COMPANIES}
        numCompaniesDesc={'The number of hirers also grew slighty month over month, hitting 111!'}
        largeHirers={LARGE_HIRERS}
        largeHirersDesc={'The list of top Rust hirers has become very stable over the last few months. This largely reflects the presence of nearly all of the major tech companies on this list. The total volume of hiring at these giants makes it hard for smaller companies to crack the top ten.'}
        otherHirers={OTHER_HIRERS}
        otherHirersDesc={"One noteworthy development in July was finding 10 Rust jobs at Tesla. This is the first time we've noted a significant number of Rust jobs at that company. Of course, we're always adding new companies as well! One new name you might be familiar with is Hugging Face. If you know of a company that should be on this list but isn't, let us know: filtra@filtra.io."}
        topTenIndustries={LARGE_INDUSTRIES}
        topTenIndustriesDesc={'The consistency in the list of top hirers is almost becoming boring. But, one of the things we are looking out for is growth in the data science category driven by the AI boom. Anecdotally, we seem to be seeing the beginnings of this in the new Rust hirers we\'ve been finding.'}
        otherIndustries={OTHER_INDUSTRIES}
        otherIndustriesDesc={'Rust is everywhere! Even if only a little in some categories.'}
        seniority={LEVELS}
        seniorityDesc={"The greatest weakness in the Rust job market continues to be the remarkably low number of entry-level positions."}
    />
}

export default RustReportJul24