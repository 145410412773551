import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100vw;
    background: var(--grey-200);
    z-index: 1;
`

export const ButtonContainer = styled.div`
    margin-right: 1.75rem;
`

export const LinkBarContainer = styled.div`
    display: flex;
    flex-grow: 1;
`

export const LinkBar = styled.div`
    display: none;
    @media (min-width: 600px) {
        display: flex;
        flex-direction: row;
        margin: auto;
        width: 60vw;
    }
    @media (min-width: 1000px) {
        width: 25vw;
    }
`

export const MenuLinkContainer = styled.div`
    flex-grow: 1;
    text-align: center;
`

export const MenuLink = styled(Link)`
    color: var(--white);
    font-family: 'Noto Sans'; 
    font-weight: 600;
    font-size: 1.25em;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`

export const Logo = styled.img`
    height: 1.25em;
`
export const HomeButtonContainer = styled.div`
    position: relative;
    margin-left: 1rem;
`

export const NavButton = styled.button`
  color: var(--black);
  background-color: var(--white);
  font-family: 'Noto Sans';
  font-size: 1em;
  font-weight: 600;
  margin: 0.5em 0 0.5em 0;
  padding: 0 1em 0 1em;
  border: var(--medBorder);
  height: 2em;
  cursor: pointer;
`;

export const NavDropDown = styled.ul`
    color: var(--black);
    left: 0;
    background-color: var(--white);
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    overflow-y: auto;
    padding-left: 0;
    width: 100%;
    min-width: 150px;
    position: absolute;
    top: 1;
    li {
        border-bottom: 1px solid var(--grey-100);
    }
    li :last-child {
        border-bottom: none;
    }
`

export const NavDropItem = styled.li`
    font-family: 'Noto Sans';
    font-weight: 600;
    padding: 0.5rem 0.5rem 0.5rem 1.5rem;
    &:hover {
        cursor: pointer;
        background-color: var(--grey-400);
    }
    &:active {
        background-color: var(--grey-400);
    }
`