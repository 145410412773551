import React from 'react';

import RustReportV2Mainmatter from '../RustReportV2Mainmatter';

const RustReportMar24 = () => {
    const NUM_JOBS = 862
    const NUM_COMPANIES = 90
    const LARGE_HIRERS = [
        ['Cloudflare', 165],
        ['Amazon', 121],
        ['Microsoft', 115],
        ['DataDog', 45],
        ['Apple', 32],
        ['SpaceX', 30],
        ['Crypto.com', 26],
        ['Canonical', 22],
        ['Meta', 20],
        ['Stockly', 16],
    ]
    const OTHER_HIRERS = [
        ['Fortanix', 16],
        ['Google ', 12],
        ['Worldcoin', 9],
        ['HelsingAI', 9],
        ['Syndica', 9],
        ['Qdrant', 9],
        ['Figma', 8],
        ['Discord', 8],
        ['IBM', 8],
        ['GitHub', 8],
        ['Akamai', 7],
        ['KeyRock', 6],
        ['Matter Labs', 6],
        ['Deep Fence', 5],
        ['Ditto', 5],
        ['Volume Finance', 5],
        ['Workato', 5],
        ['Chorus One', 5],
        ['OneSignal', 5],
        ['Ardan Labs', 5],
        ['Open Cosmos', 5],
        ['10X Genomics', 4],
        ['ApolloGraphQL', 4],
        ['IOHK', 4],
        ['PingCAP', 4],
        ['SmartThings', 4],
        ['Mozilla', 4],
        ['Matic', 4],
        ['Amo', 3],
        ['Zoo', 3],
        ['Liquid Analytics', 3],
        ['IOTA Foundation', 3],
        ['Rapid7', 3],
        ['HealPay', 3],
        ['Heliax', 3],
        ['1Password', 3],
        ['Svix', 3],
        ['HyperExponential', 3],
        ['InfinyOn', 3],
        ['Imperva', 2],
        ['Parity', 2],
        ['Sentry', 2],
        ['OpenAI', 2],
        ['OpenTalk', 2],
        ['Railway', 2],
        ['Replit', 2],
        ['OVH', 2],
        ['Smarkets', 2],
        ['Fullstory', 2],
        ['Aleph Alpha', 2],
        ['Okta', 2],
        ['Qovery', 2],
        ['SurrealDB', 1],
        ['ReadySet', 1],
        ['Zama', 1],
        ['Grafbase', 1],
        ['Tonari', 1],
        ['Red Canary', 1],
        ['Volka', 1],
        ['Turso', 1],
        ['Toyota Connected', 1],
        ['Fermyon Technologies', 1],
        ['Threema', 1],
        ['Zelis', 1],
        ['TrueLayer', 1],
        ['UXStream', 1],
        ['WalletConnect', 1],
        ['FP Complete', 1],
        ['Embark', 1],
        ['Cymo', 1],
        ['Polybase', 1],
        ['Scythe Robotics', 1],
        ['Deliveroo', 1],
        ['KION', 1],
        ['Clever Cloud', 1],
        ['Astropad', 1],
        ['DSCVR', 1],
        ['Qwiet AI', 1],
        ['Star Lab', 1],
        ['Oxide', 1],
    ]
    const LARGE_INDUSTRIES = [
        ['cloud/infrastructure', 305],
        ['productivity', 139],
        ['crypto', 78],
        ['systems/hardware', 56],
        ['monitoring/reliability', 47],
        ['security', 45],
        ['aerospace', 35],
        ['data science', 31],
        ['social media', 23],
        ['marketplace', 17],
    ]
    const OTHER_INDUSTRIES = [
        ['messaging/notifications', 16],
        ['browser', 16],
        ['consultancy', 14],
        ['databases', 11],
        ['iot/robotics/automotive', 11],
        ['fintech', 9],
        ['health/biotech', 5],
        ['animation', 2],
        ['gaming', 2],
    ]
    const LEVELS = [
        ['junior', 21],
        ['mid', 432],
        ['senior', 409],
    ]
    return <RustReportV2Mainmatter
        title={'Rust Jobs Report: March 2024'}
        subtitle={'Welcome to the March 2024 Rust Jobs Report by filtra. Please enjoy this month\'s overview of the Rust job market.'}
        titleNote={'To add your or another company to the filtra Rust jobs index, please email filtra@filtra.io with the subject line "Rust Index"'}
        openPositions={NUM_JOBS}
        openPositionsDesc={'March was a huge month. We were able find a record 862 public jobs postings! That\'s over 100 more than last month. So, are there jobs in Rust? Rust ain\'t no javascript yet, but the pool of open positions seems to be sizable and growing at an impressive rate!'}
        numCompanies={NUM_COMPANIES}
        numCompaniesDesc={'While the total number of postings took another big jump, the list of hirers actually shrunk slightly. So, the growth in postings was mostly consolidated in the same group of hirers we\'ve been tracking.'}
        largeHirers={LARGE_HIRERS}
        largeHirersDesc={'The big story amongst the top hirers is Cloudflare retaking the lead after ceding that position to Amazon for the last several months.'}
        otherHirers={OTHER_HIRERS}
        otherHirersDesc={"While the overall number of hirers we found didn't grow in March, the index did. For example, one interesting newcomer is French social media company Amo. With that said, our index of Rust companies is by no means comprehensive. But, it'd be cool if it was! If you know of a company that you don't see here, don't hesitate to point it out: filtra@filtra.io."}
        topTenIndustries={LARGE_INDUSTRIES}
        topTenIndustriesDesc={'There\'s not much new from month to month in the list of top industries using Rust, but first time readers might be pleasantly surprised to see this diversity of applications.'}
        otherIndustries={OTHER_INDUSTRIES}
        otherIndustriesDesc={'Rust only has a mere beachhead in some industries, but it\'s touching nearly every major field that uses software!'}
        seniority={LEVELS}
        seniorityDesc={"Rust jobs definitely skew toward the side of requiring more experience. This has to be due in part to the fact that companies favor Rust's memory safety and efficiency for mission-critical applications. If the language becomes more ubiquitous, the proportion of entry-level jobs will probably grow too."}
    />
}

export default RustReportMar24 