import Header from '../../../Header';
import { Background, Container, GraphDescription, SectionHead, Subtitle, Title, TitleNote, OrangeLink, InlineLink, TitlePage, EndingNote, BigNumber } from './RustReportV2.styles.js';
import BarChart from './BarChart';
import TableChart from './TableChart';


const RustReportV2 = ({ title, subtitle, titleNote, openPositions, openPositionsDesc, numCompanies, 
    numCompaniesDesc, largeHirers, largeHirersDesc, 
    otherHirers, otherHirersDesc, topTenIndustries, topTenIndustriesDesc,
    otherIndustries, otherIndustriesDesc, seniority, seniorityDesc }) => {
    return <Background>
            <Header showFiltraConfig={false}/> 
            <Container>
                <TitlePage>
                    <Title>{ title }</Title>
                    <Subtitle>{ subtitle }</Subtitle>
                    <TitleNote>{ titleNote }</TitleNote>
                </TitlePage>
                <SectionHead>Open Positions</SectionHead>
                <BigNumber>{ openPositions }</BigNumber>
                <GraphDescription>{ openPositionsDesc }</GraphDescription>
                <SectionHead>Hiring Companies</SectionHead>
                <BigNumber>{ numCompanies }</BigNumber>
                <GraphDescription>{ numCompaniesDesc }</GraphDescription>
                <SectionHead>Top Hirers</SectionHead>
                <BarChart data={largeHirers}/>
                <GraphDescription>{ largeHirersDesc }</GraphDescription>
                <SectionHead>Other Hirers</SectionHead>
                <TableChart data={otherHirers}/>
                <GraphDescription>{ otherHirersDesc }</GraphDescription>
                <SectionHead>Industry Concentration</SectionHead>
                <BarChart data={topTenIndustries}/>
                <GraphDescription>{ topTenIndustriesDesc }</GraphDescription>
                <SectionHead>Other Industries</SectionHead>
                <TableChart data={otherIndustries}/>
                <GraphDescription>{ otherIndustriesDesc }</GraphDescription>
                <SectionHead>Seniority Levels</SectionHead>
                <BarChart data={seniority}/>
                <GraphDescription>{seniorityDesc}</GraphDescription>
                <TitlePage>
                    <OrangeLink
                        to={'/'} 
                    >
                        get rust jobs on filtra 
                    </OrangeLink>
                    <EndingNote>
                        Data for this edition of the Rust Jobs Report is filtra platform data supplemented with public job postings. Feel free to visit the filtra Rust blog to{' '}
                        <InlineLink to={'/rust'}>
                            see past reports.
                        </InlineLink>
                    </EndingNote>
                </TitlePage>
            </Container>
        </Background>
}

export default RustReportV2 