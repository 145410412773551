import React from 'react';
import { InfoBox, SmallButton } from '../../GlobalStyle';
import { ButtonContainer, Column, Container, ImageInput } from './PhotoDialog.styles';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';
import handleAPIError from '../../api/error';
import { useState } from 'react';

const PhotoDialog = ({ 
    closer, 
    setPhotoUrl, 
    identifier,
    isCompany=false,
    exists,
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [isFileSelected, setIsFileSelected] = useState(false)
    const { getAccessTokenSilently } = useAuth0();

    const handleSubmit = async () => {
        const imageInput = document.getElementById('imgfile')
        if (imageInput.files.length > 0) {
            const imageFile = imageInput.files[0]
            const blob = imageFile.slice(0, imageFile.size, 'image/jpeg')
            const newImageFile = new File([blob], `${identifier}.jpeg`, { type: 'image/jpeg' })
            const formData = new FormData()
            formData.append('imgfile', newImageFile)
            formData.append('exists', exists)
            await postImage(formData)
        }
    }
    
    const postImage = async (body) => {
        setIsLoading(true)
        try {
            const token = await getAccessTokenSilently()
            let url = null
            if (isCompany) {
                url = `/photos/companies/${identifier}`
            } else {
                url = `/photos/${identifier}`
            }
            await api.post(url, body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            })
            const rand = Math.round(Math.random() * 1000000);
            setPhotoUrl(`https://storage.googleapis.com/${process.env.REACT_APP_GCP_PHOTO_BUCKET}/${identifier}.jpeg?v=${rand}`);
        } catch (err) {
            handleAPIError(err);
        }
        setIsLoading(false)
    };

    return (
        <Container>
            <Column>
                {!isLoading ? 
                    <>
                        <InfoBox>
                            Share a profile photo so people know its you.
                        </InfoBox>
                        <ImageInput 
                            type={'file'}
                            accept={'image/jpeg'}
                            id={'imgfile'}
                            onChange={() => {
                                const imageInput = document.getElementById('imgfile')
                                if (imageInput.files.length > 0) {
                                    setIsFileSelected(true)
                                } else {
                                    setIsFileSelected(false)
                                }
                            }}
                        />
                        <ButtonContainer>
                            <SmallButton
                                onClick={async () => { 
                                    await handleSubmit()
                                    closer(false)                            
                                }} 
                            >
                                {isFileSelected ?
                                    <>save</>
                                : <>cancel</>}
                            </SmallButton>
                        </ButtonContainer>
                    </>
                : <InfoBox>Loading...</InfoBox>}
            </Column>
        </Container>
    );
};

export default PhotoDialog 