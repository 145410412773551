import React, { useState } from 'react';
import { Container, Input, Label, LabelError, OptionRow } from './MultipleOptionInput.styles';

const MultipleOptionInput = ({ label, val, options, onChange, accentColor, error }) => {
    const [values, setValues] = useState(val)

    return <Container>
        <LabelError>{ error }</LabelError>
        {options.map(option => <OptionRow key={option}>
            <Label>{ option }</Label>
            <Input
                accentColor={accentColor}
                type='checkbox'
                checked={values.includes(option)}
                onChange={({ target }) => {
                    if (target.checked) {
                        const newValues = values.concat([option]) 
                        setValues(newValues)
                        onChange(newValues)
                    } else {
                        const newValues = values.filter(value => {
                            return value !== option
                        })
                        setValues(newValues)
                        onChange(newValues)
                    }
                }}
            />
        </OptionRow>)}
    </Container>
}

export default MultipleOptionInput;
