import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
  color: var(--white);
  background: var(--black);
  border: var(--border);
  outline: none;
  font-family: 'Noto Sans';
  font-weight: 600;
  font-size: 2.5em;
  width: 100%;
  height: 100%;
  min-height: 5.5rem;
  &:hover {
    font-size: 3.25em;
  }
  cursor: pointer;
`;
