import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { SmallButton } from '../../GlobalStyle';

const LoginButton = ({returnTo = '/profile'}) => {
  const { loginWithRedirect } = useAuth0();
  return (
    <SmallButton
      onClick={() => loginWithRedirect({
        appState: { returnTo }
      })}
    >
      login 
    </SmallButton>
  );
};

export default LoginButton;
