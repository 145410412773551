import React, { useState, useEffect } from 'react';
import { Container, LogoImage, LogoImageContainer, CompanyName, NameLogoContainer, GridContainer, JobCard, JobTitle, JobContainer } from './JobSelectionCompanyOverview.styles';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';
import handleAPIError from '../../api/error';
import Loading from '../Loading';

const JobSelectionCompanyOverview = ({ company, id }) => {
    const { user, getAccessTokenSilently } = useAuth0()
    const recruiterId = user.sub.split('|')[1];
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState([])
    const [jobs, setJobs] = useState([])

    useEffect(() => {
        const getJobs = async () => {
            setIsLoading(true);
            try {
                const token = await getAccessTokenSilently();
                const response = await api.get(
                    `/recruiters/${recruiterId}/companies/${company.id}/jobs`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setJobs(response.data)
            } catch (err) {
                handleAPIError(err);
            }
            setIsLoading(false);
        }
        getJobs();
    }, [recruiterId, getAccessTokenSilently, navigate, company.id]);

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <Container>
                    <NameLogoContainer
                        onClick={() => {
                            navigate(`/recruiters/companies/${company.id}`)
                        }}
                    >
                        <LogoImageContainer>
                            <LogoImage src={company.photoUrl}/>
                        </LogoImageContainer>
                        <CompanyName borderColor={'--orange'}>
                            { company.name } 
                        </CompanyName>
                    </NameLogoContainer>
                    <GridContainer>
                        {jobs.map((job) => 
                            <JobContainer key={job.id}>
                                <JobCard 
                                    onClick={() => {
                                        navigate(`/filters/${id}`, {state: {job: job, company: company,}})
                                    }}
                                >
                                    <JobTitle>
                                        {job.title}
                                    </JobTitle>
                                </JobCard>
                            </JobContainer>
                        )}
                    </GridContainer>
                </Container>
            )}
        </>
    );
};

export default JobSelectionCompanyOverview 