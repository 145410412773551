import React, { useEffect, useState } from "react";
import { Container, MenuLink, LinkBar, ButtonContainer, LinkBarContainer, Logo, HomeButtonContainer, MenuLinkContainer, NavButton, NavDropDown, NavDropItem } from './Header.styles.js';
import LoginButton from '../LoginButton';
import LogoutButton from '../LogoutButton';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from "react-router-dom";
import LogoImg from '../../assets/filtra_logo.png'
import RecruiterHeader from "../RecruiterHeader/index.js";

const Header = ({ showFiltraConfig, showJobs, useBackground=true }) => {
    const { isAuthenticated, getIdTokenClaims } = useAuth0();
    const location = useLocation()
    const navigate = useNavigate()
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [isLoggedInRecruiter, setIsLoggedInRecruiter] = useState(false);

    useEffect(() => {
        const checkClaims = async () => {
            const claims = await getIdTokenClaims()
            if (claims['https://filtra.io/roles'].includes('Recruiter')) {
                setIsLoggedInRecruiter(true) 
            } 
        };
        checkClaims();
    }, [getIdTokenClaims]);

    if (isLoggedInRecruiter) {
        return <RecruiterHeader hideCenter={true} useBackground={false}></RecruiterHeader>;
    }

    return <Container style={{background: useBackground ? 'var(--grey-200)' : 'none'}}>
        <HomeButtonContainer>
            <NavButton
                onClick={() => { 
                    if (isAuthenticated) {
                        setIsMenuOpen(!isMenuOpen)
                    } else {
                        navigate('/')
                    }
                }} 
            >
                <Logo src={LogoImg} />
            </NavButton>
            {isMenuOpen ?
                <NavDropDown>
                    <NavDropItem 
                        onClick={() => navigate('/profile')}
                        style={{color: location.pathname === '/profile' ? 'var(--orange)' : 'var(--grey-500)'}}
                    >
                        profile 
                    </NavDropItem>
                    <NavDropItem 
                        onClick={() => navigate('/filterconfig')}
                        style={{color: location.pathname === '/filterconfig' ? 'var(--orange)' : 'var(--grey-500)'}}
                    >
                        filtra 
                    </NavDropItem>
                    <NavDropItem 
                        onClick={() => navigate('/job-recs')}
                        style={{color: location.pathname === '/job-recs' ? 'var(--orange)' : 'var(--grey-500)'}}
                    >
                        jobs 
                    </NavDropItem>
                    <NavDropItem 
                        onClick={() => window.location ='https://forms.gle/AFgP91mnYEDRk6Yu9'}
                        style={{color: 'var(--grey-500)'}}
                    >
                        feedback 
                    </NavDropItem>
                </NavDropDown>
            : null}
        </HomeButtonContainer>
            <LinkBarContainer>
                {isAuthenticated ? 
                    <LinkBar>
                        {(location.pathname !== '/' 
                            && location.pathname !== '/about'
                            && location.pathname !== '/rust-nov-22'
                            && location.pathname !== '/rust-dec-22'
                            && location.pathname !== '/rust-jan-23'
                            && location.pathname !== '/rust-feb-23'
                            && location.pathname !== '/rust') ?
                            <>
                                <MenuLinkContainer>
                                    <MenuLink 
                                        to={'/profile'} 
                                        style={{color: location.pathname === '/profile' ? 'var(--orange)' : 'var(--grey-500)'}}
                                    >profile</MenuLink>
                                </MenuLinkContainer>
                                {showFiltraConfig ? 
                                    <>
                                        <MenuLinkContainer>
                                            <MenuLink 
                                                to={'/filterconfig'} 
                                                style={{color: location.pathname === '/filterconfig' ? 'var(--orange)' : 'var(--grey-500)'}}
                                            >filtra</MenuLink>
                                        </MenuLinkContainer>
                                    </>
                                : null}
                                {showJobs ?
                                    <>
                                        <MenuLinkContainer>
                                            <MenuLink 
                                                to={'/job-recs'} 
                                                style={{color: location.pathname === '/job-recs' ? 'var(--orange)' : 'var(--grey-500)'}}
                                            >jobs</MenuLink>
                                        </MenuLinkContainer>
                                    </>
                                : null}
                            </>
                        : null}
                    </LinkBar>
                : null }
            </LinkBarContainer>
        <ButtonContainer>
            {isAuthenticated ? <LogoutButton /> : <LoginButton /> }
        </ButtonContainer>
    </Container>
}

export default Header;
