// import React, { useState, useEffect } from 'react';
// import {
//     BackgroundPlain, 
// } from '../../GlobalStyle';
// import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
// import Loading from '../Loading';
// import RecruiterHeader from '../RecruiterHeader';
// import { useNavigate } from 'react-router-dom';
// import handleAPIError from '../../api/error';
// import { AddCompanyBar, CompaniesContainer } from './JobSelection.styles';
// import CompanyOverview from '../CompanyOverview';
// import { v4 as uuidv4 } from 'uuid';
// import api from '../../api';
import React, { useState, useEffect } from 'react';
import { BackgroundPlain } from '../../GlobalStyle';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import Loading from '../Loading';
import RecruiterHeader from '../RecruiterHeader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import handleAPIError from '../../api/error';
import { CompaniesContainer } from './JobSelection.styles';
import JobSelectionCompanyOverview from '../JobSelectionCompanyOverview';
import api from '../../api';

const JobSelection = () => {
    const [searchParams] = useSearchParams()
    const id = searchParams.get('id')
    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0()
    const recruiterId = user.sub.split('|')[1];
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const [companies, setCompanies] = useState([])

    useEffect(() => {
        const getCompanies = async () => {
            setIsLoading(true);
            try {
                const claims = await getIdTokenClaims()
                if (!claims['https://filtra.io/roles'].includes('Recruiter')) {
                    navigate('/profile') 
                } else {
                    const token = await getAccessTokenSilently();
                    const response = await api.get(
                        `/recruiters/${recruiterId}/companies`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    setCompanies(response.data)
                }
            } catch (err) {
                handleAPIError(err);
            }
            setIsLoading(false);
        }
        getCompanies();
    }, [recruiterId, getIdTokenClaims, getAccessTokenSilently, navigate]);

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <BackgroundPlain>
                    <RecruiterHeader userExists={true}/>
                    <CompaniesContainer>
                        {companies.map((company) => <JobSelectionCompanyOverview key={company.name} company={company} id={id}/>)}
                    </CompaniesContainer>
                </BackgroundPlain>
            )}
        </>
    );
};

export default withAuthenticationRequired(JobSelection, {
    onRedirecting: () => <Loading />,
});
