import React from 'react';

import RustReportV2 from '../RustReportV2';

const RustReportOct23 = () => {
    const NUM_JOBS = 460
    const NUM_COMPANIES = 87
    const LARGE_HIRERS = [
        ['Amazon', 61],
        ['Microsoft', 43],
        ['Cloudflare', 37],
        ['SpaceX', 23],
        ['DataDog', 21],
        ['Meta', 20],
        ['Apple', 20],
        ['Embark', 11],
        ['Fortanix', 10],
        ['Discord',  8],
    ]
    const OTHER_HIRERS = [
        ['Syndica', 7],
        ['Crypto.com', 7],
        ['Google ', 7],
        ['Kraken', 6],
        ['1Password', 6],
        ['Deliveroo', 6],
        ['Akamai', 6],
        ['Stockly', 5],
        ['Zama', 5],
        ['Atlassian', 5],
        ['Gamaspace', 5],
        ['OVH', 5],
        ['Ditto', 5],
        ['InfluxDB', 5],
        ['Volume Finance', 5],
        ['Replit', 4],
        ['HealPay', 4],
        ['Smarkets', 4],
        ['Matter Labs', 4],
        ['Mozilla', 4],
        ['Matic', 4],
        ['OneSignal', 4],
        ['Protocol Labs', 4],
        ['Worldcoin', 3],
        ['OpenAI', 3],
        ['HelsingAI', 3],
        ['Figma', 3],
        ['Qumulo', 3],
        ['PingCAP', 3],
        ['InfinyOn', 3],
        ['Liquid Analytics', 3],
        ['Qdrant', 2],
        ['Galois', 2],
        ['Turso', 2],
        ['Lit Protocol', 2],
        ['Trivago', 2],
        ['IOTA Foundation', 2],
        ['Qovery', 2],
        ['Scaleway', 2],
        ['KeyRock', 2],
        ['Sentry', 2],
        ['Clever Cloud', 2],
        ['Dropbox', 2],
        ['SmartThings', 2],
        ['Starry', 2],
        ['Vivint', 2],
        ['Qwiet AI', 2],
        ['arm', 2],
        ['Railway', 2],
        ['Threema', 2],
        ['Holo', 1],
        ['10X Genomics', 1],
        ['Wingback', 1],
        ['Arcjet', 1],
        ['Tonari', 1],
        ['OpenTalk', 1],
        ['EdgeDB', 1],
        ['Fly', 1],
        ['Materialize', 1],
        ['FP Complete', 1],
        ['Cymo', 1],
        ['Polybase', 1],
        ['Sourcegraph', 1],
        ['Astropad', 1],
        ['Nuclia', 1],
        ['Activision', 1],
        ['Star Lab', 1],
        ['Shuttle', 1],
        ['AppSignal', 1],
        ['DSCVR', 1],
        ['KION', 1],
        ['WalletConnect', 1],
        ['UXStream', 1],
        ['Knowbe4', 1],
        ['Aleph Alpha', 1],
        ['Tabnine', 1],
        ['Canonical', 1],
    ]
    const LARGE_INDUSTRIES = [
        ['cloud/infrastructure', 125],
        ['productivity', 52],
        ['crypto', 46],
        ['security', 32],
        ['aerospace', 28],
        ['systems/hardware', 24],
        ['monitoring/reliability', 24],
        ['social media', 20],
        ['data science', 18],
        ['messaging/notifications', 15],
    ]
    const OTHER_INDUSTRIES = [
        ['marketplace', 13],
        ['databases', 12],
        ['gaming', 12],
        ['browser', 11],
        ['iot/robotics/automotive', 9],
        ['fintech', 8],
        ['consultancy', 3],
        ['animation', 2],
        ['networking', 2],
        ['martech', 1],
        ['health/biotech', 1],
    ]
    const LEVELS = [
        ['mid', 256],
        ['senior', 185],
        ['junior', 19],
    ]
    return <RustReportV2 
        title={'Rust Jobs Report: October 2023'}
        subtitle={'Welcome to the October 2023 Rust Jobs Report by filtra. Please enjoy our overview of the Rust job market.'}
        titleNote={'To add your or another company to the filtra Rust jobs index, please email filtra@filtra.io with the subject line "Rust Index"'}
        openPositions={NUM_JOBS}
        openPositionsDesc={'The number of jobs was basically flat in October, dropping from 462 in September to 460 in October.'}
        numCompanies={NUM_COMPANIES}
        numCompaniesDesc={'The number of hirers grew slightly, reflecting a greater diversity of companies using Rust.'}
        largeHirers={LARGE_HIRERS}
        largeHirersDesc={'Listings at Microsoft continued to grow, putting the giant in second place this month.'}
        otherHirers={OTHER_HIRERS}
        otherHirersDesc={"Thanks to community submissions, the index of hirers continues to grow. Newcomers this month include Trivago and Railway. If you know of a company that should be on this list, don't hesitate to point it out: filtra@filtra.io"}
        topTenIndustries={LARGE_INDUSTRIES}
        topTenIndustriesDesc={'Expanded hiring at Microsoft put "productivity" in second place for the first time. This casts some doubt on the value of categorizing companies, especially megacaps, as participants in a particular industry.'}
        otherIndustries={OTHER_INDUSTRIES}
        otherIndustriesDesc={'The number of other industries hiring Rust engineers has grown slightly over time.'}
        seniority={LEVELS}
        seniorityDesc={"While Rust jobs still skew heavily mid-level and above, it is worth noting that the distribution has moved more toward mid over time. Perhaps we will see growth in junior jobs soon enough."}
    />
}

export default RustReportOct23 