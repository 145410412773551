import styled from 'styled-components';

export const Table = styled.table`
    width: 100%;
    @media (min-width: 1000px) {
        width: 60%;
    }
    color: var(--black);
    font-family: 'Noto Sans Mono', monospace;
    font-size: 1em;
    font-weight: 500;
    margin: 0 0 2rem 0;
    border-collapse: collapse;
`

export const TableRow = styled.tr`

`

export const TableBody = styled.tbody`

`

export const TableData = styled.td`
    padding: 0.25rem 0.25rem 0.25rem 0.25rem;
    border: var(--smallBorder);
`