import React from 'react';

import NumJobsPng from './numjobs.png';
import NumCompanies from './numcompanies.png';
import LargeHirers from './largehirers.png';
import OtherHirers from './otherhirers.png';
import LargeIndustries from './largeindustries.png';
import OtherIndustries from './otherindustries.png';

import RustReport from '../RustReport';


const RustReportDec22 = () => {
    return <RustReport 
        title={'Rust Jobs Report: December 2022'}
        subtitle={'Welcome to the December 2022 Rust Jobs Report. We maintain an index of companies hiring for Rust roles. Filtra has received an exciting level of support from the Rust community. As a thank you, we have elected to share this data publicly.'}
        titleNote={'To add your or another company to the index, please email filtra@filtra.io with the subject line "Rust Index"'}
        openPositionsPng={NumJobsPng}
        openPositionsDesc={"We significantly increased the dataset this month, including 218 available Rust positions."}
        numCompaniesPng={NumCompanies}
        numCompaniesDesc={'Our watchlist grew from 87 companies to 115, allowing us to find 50 hiring companies.'}
        largeHirersPng={LargeHirers}
        largeHirersDesc={"If you're looking to write some Rust on the job these days, it's Cloudflare, Cloudflare, Cloudflare."}
        topTenCompanies={[
            'Cloudflare',
            'Heliax',
            'Mozilla',
            'Parity',
            'Activision',
            'Matter Labs',
            'Materialize',
            'arm',
            'Akamai',
            'DeepSign GmbH',
        ]}
        otherHirersPng={OtherHirers}
        otherHirersDesc={"The long tail of Rust jobs is alive and well, with several companies offering just a few jobs."}
        topTenIndustriesPng={LargeIndustries}
        topTenIndustriesDesc={"For the most part, the top industries are the same this month as last. However, the addition of Activision to the index put gaming on the map."}
        topTenIndustries={[
            'cloud/infrastructure',
            'security',
            'crypto',
            'systems/hardware',
            'browser',
            'messaging/notifications',
            'databases',
            'gaming',
            'iot/robotics/automotive',
            'data science',
        ]}
        otherIndustriesPng={OtherIndustries}
        otherIndustriesDesc={'With this month\'s larger dataset, we saw more diversity in the list of industries hiring Rust programmers.'}
    />
}

export default RustReportDec22 