import React from 'react';
import { Background, Container, ContentContainer, Subtitle, Title, OrangeLink, Paragraph, Speaker, OrangeLinkInline } from '../DeepDive.styles.js';
import Header from '../../../Header';

const RustGAMASep23 = () => {
    return <Background>
            <Header showFiltraConfig={false}/> 
            <Container>
                <ContentContainer>
                    <Title>Flying Solar Sails With Rust</Title>
                    <Subtitle>The following is the transcript from my interview with Chris de Claverie, Head of Guidance, Navigation, and Control at GAMA Space. I enjoyed our wide ranging conversation where we hit on topics like the mechanics of solar sails, the challenges of writing code for space applications, and learning to manage high-performing teams. These interviews, along with the Rust Jobs Report, are part of our effort to educate about the state of the Rust job market. As always, you can check out our extensive list of rust jobs by setting up your profile on <OrangeLinkInline to={'/'}>filtra</OrangeLinkInline>. - Drew</Subtitle>
                    <Paragraph><Speaker>Drew: </Speaker>Thanks for chatting today Chris. So, just to lay the groundwork, you are the head of Guidance, Navigation, and Control (GNC) at GAMA right?</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>That’s right. I am the head of GNC, but my team is a bit larger than that. We also encompass embedded systems. So, I can speak to both GNC and embedded.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>You have a pretty unusual job, so I’d like to learn a little bit more about your career path. How did you end up with this job?</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>When I was a child, I wanted to be an airline pilot. I eventually came to understand that pilots are likely to get automated out of a job, so aerospace engineering felt like a safer long-term bet. And, I thought I could get into piloting for fun. But, I never thought that space engineering was something I could do, because of the reputation it has of being very selective. That seemed like an impossibility for me.</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>So, I ended up doing my aerospace engineering studies at a school here in Paris. And, I ended up just getting hooked on space. It was exciting seeing rockets take off! We had a lot of student projects in school, but there were no actual space projects. I needed a project to finish off my studies, and so I decided to try and learn something and do my own project around space. So, I led a student project working on a cubesat, which is a very small satellite. Noone else on the project had any competence in GNC, so I learned it and did that part of the project. Later, I had some classes on GNC, but I had to teach myself a lot before those classes. So, that was my path into GNC.</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>I also started programming when I was about thirteen. I started coding in C, which was probably not the best to start with, but I eventually learned a bunch of different things like C++, Node, and PHP. I also did some game development and security stuff. I just tried a bunch of stuff. At some point, I found Rust and just got completely hooked. And, of course, I learned embedded systems on my student project as well.</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>So, I had all that background, and I was finishing my studies and looking for a job. I had a good network, and the founder of a startup called me and told me he’d hired my best friend. That was GAMA! My friend is now the head of systems engineering. So, I really got to witness the birth of the company. Officially, I am the fourth employee of GAMA. That’s how it all started!</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>That’s an exciting career trajectory! So, how long has GAMA been running now?</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>Three years. We started in late 2019.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>Could you also give me a little more information on the creation of GAMA and why the company started.</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>So, the guy who called me is named Louis de Gouyon-Matignon. And, he had the idea to make solar sails actually work. So, he paired up with one of his friends who is a French angel investor, and they just started trying to make something work. They found my friend and I because of that student project. I think they were just searching on LinkedIn and found that we had worked on that project. Initially, it was just two of my friends in a garage for two weeks trying different designs and back-of-the-envelope calculations just trying to get the correct orders of magnitude in their heads.They quickly got their first office, which was at the top of Montparnasse Tower in Paris, which is actually one of the highest towers in Paris. A lot of people say it’s the best view in Paris, because you don’t have to see the Montparnasse Tower! The tower itself is not the greatest to look at. But, having the office space was great. So, that was all three years ago. We started building our first project, GAMA Alpha, there. We also quickly hired some people to build the teams and found some commercial partners.</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>To summarize what we are doing, GAMA Space is creating an innovative space propulsion technology called solar sails, which allow for infinite, propellantless thrust within the solar system. Solar sails are a very well known idea. The idea has been around for almost a hundred years, but we’re actually making it work now.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>Yeah, I knew about solar sails, but I didn’t necessarily know about any companies that were working on them. Was there something specific that happened that made them suddenly viable?</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>So, there has been a lot of preceding work on solar sails. NASA has been working on solar sails for a while. JAXA, the Japanese agency, has also been working on solar sails for a while. Those projects have done a very good job of demonstrating that the technology works. We just need to make it commercially viable. So, after that precedent had been set, some companies emerged that actually started manufacturing solar sail materials and had some knowledge about things like how to fold a solar sail. Those are typically U.S. companies, and usually only work with NASA on noncommercial missions. But, the findings from this previous work are what allow us to exist. Specifically, the discovery of the right material for this application is key. So, we are basing our commercial strategies around learning how to master that material.</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>Of course, cheaper launching has also helped a lot. That allows us to run multiple test missions. It’s a lot easier now to launch near-Earth missions to test a bunch of technologies before running long-term, deep space missions.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>What’s the material that you mentioned?</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>It’s a polymer, so basically a plastic. But, this plastic can be made in very thin sheets of about 2-5 micrometers. So, that’s about the width of our first sail. So, it’s extremely thin but highly resistant, and it can be coated to make it reflective for the solar sail. Solar sails are basically just large mirrors in space. So, we essentially need something to hold them, and something that reflects light. So, the strength and thinness of this material make it the key material for solar sailing. It’s also used for other technologies. The James Webb Space Telescope actually uses the same material for heat insulation. But, it’s really difficult to handle. It’s horrible. Having assembled a solar sail recently, I can tell you, it’s almost a full-time job figuring out how to work with this material.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>I guess we breezed over this, but for anyone who might not know, can you just give the basic description of how a solar sail works.</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>Of course! So, as I said, they’re basically large mirrors in space. They work by using the momentum of the sun’s rays. Light is made up of photons, which don’t have mass, but they do have momentum that they can transfer. By reflecting this light, we harvest the momentum twice. We first absorb the photon, and then emit the photon. This gives the solar sail twice this specific photon’s momentum. This is the rough outline. There are actually multiple ways to reflect a photon. Those are diffuse and specular reflections, which work in slightly different ways. But, basically solar sails reflect light. This allows us to orient the sail by pointing it in the right direction, because the thrust is always going to be normal or perpendicular, to the sail. Using that property, we’re able to design optimized trajectories that only solar sailing can do. Since we don’t have any propellant, we have continuous thrust, which allows for really interesting trajectories.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>I guess that brings us to your specific work. Is GNC mostly focused around the tilting or pointing of the sail to create these trajectories?</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>Exactly. The first thing we do is figure out where we want to go. It’s trajectory design. We ask questions like what orbit we’re aiming for, how long we’ll need to get there, and what is the mass of the spacecraft. From there, we can work out the optimal pointing of the solar sail. There are some additional constraints. Mainly, it’s telecommunications. So, there are points when we have to rotate the craft to face Earth in order to communicate. There’s also the question of energy. If we use solar panels to gather energy, we have to make sure they point toward the sun at some point.</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>Once we’ve figured out the optimal pointing of the craft, we need to rotate the sail. So, there’s a whole GNC subsystem where we have some sensors that gather the data to help us understand where we are in space and how we are oriented. Then, we use actuators to actually rotate the sail and achieve the correct pointing. So, that’s basically what we work on everyday. And, we’re constantly iterating on this in order to make it actually work in our first spacecraft, GAMA Alpha.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>You mentioned GAMA Alpha. Can you explain the roadmap a bit as far as GAMA Alpha, GAMA Beta, and so forth?</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>GAMA Alpha is a very simple, near-Earth mission. Or, at least it’s very near-Earth compared to the other projects we’ve talked about. This mission is basically a test bench, and the goal is to show that we can master the opening of the sail. With GAMA Beta, we want to prove the performance of the sail. We want to show that we can actually achieve the thrust we expect in flight. For example, if we want to sail a mission to Mars, or an asteroid, the client is going to want some sort of proof that we can make it. So, with GAMA Beta, we want to prove with very high fidelity that what we expect is true. We’ll also try some new technologies on that mission that will be more useful when we get further from Earth. Then, GAMA Gama is the first commercial mission. We are in discussions with a lot of commercial and scientific partners to figure out what this mission will be, so we still don’t know exactly where it will be going.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>One of the things I’m curious about is the continuous acceleration that’s possible with solar sails. On a theoretical basis, that should allow you to achieve really high velocity. What is the actual acceleration?</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>It is pretty slow. So, taking the example of our first mission, we have seventy-five square meters of solar sail, and a twelve kilogram satellite. The acceleration is five to ten micrometers per second squared. So, in one day, we gain one meter per second. In one year, it’s about 400 meters per second. The speed we can gain is theoretically infinite if we assume no degradation, but of course the solar sail degrades slowly. So, what’s interesting with those kinds of things is that the amount of thrust is very dependent on our proximity to the sun. Getting a little bit closer to the sun gets us a lot more thrust. It’s also very sensitive to the mass of the spacecraft. So, the higher the mass, the lower the acceleration. Then, the level of reflectivity also plays an important role. So, we’re working very hard on improving reflectivity.</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>It’s really hard to make sense of these numbers, so we look a lot at the ratio of area over mass. So, the larger the area the better, and the lower the mass the better. The lightness number is the inverse of that. If you look at solar sail papers, you see the lightness number everywhere. GAMA Alpha works out to about a six for the lightness number, and we’re aiming for about twenty for commercial missions. I think this might be a little bit pessimistic. I think we can do better.</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>Aside from everything I’ve mentioned, we can also improve the trajectories. A better trajectory will get us to our destination in less time. Also, a better pointing strategy can help us get to our destination faster.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>As you’re talking about all of this, I’m thinking about the fact that you can’t just test sails all the time. So, do you have different simulators and things that you use to refine your approach?</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>Yeah, we have some very rough, simple simulators where we can do two dimensional simulation. We use that just to try out rough ideas. Then, we have successively more sophisticated simulations all the way up to full, hardware-in-the-loop simulations where we can try the whole mission. So, yes we have a lot of simulation capabilities, and we’re always developing and improving them. Modeling the uncertainties in the simulations is very hard. And, we’re always trying to keep track of how different the simulation may be from real life.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>Have you built these simulators in-house, or are they things that you’ve licensed, or is it some combination?</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>It’s partially in-house. Nothing exists on the commercial market for simulating solar sails, but we can use known frameworks and packages and implement things in Simulink to help us speed up development. We also have some simulators of our own written in Rust.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>Since you mentioned Rust, what are the different technologies that you use? Is it mostly Rust?</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>Rust is used everywhere across GAMA today, but we do use other technologies as well. We still have some C libraries. For the flight software, we still have real time operating systems  that are written in C. We have high confidence in these components, so we don’t feel a need to rewrite them. Also, for networking, we have some existing C libraries that we use. And, as I mentioned, we use Matlab/Simulink mostly for simulation and fast-iteration around different algorithms. It really allows us to try a bunch of things quickly, and then we convert that into Rust.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>What made you settle on Rust as your primary tool?</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>First, since we’re doing embedded development, I really appreciate the fact that cross-compilation is so easy in Rust. In C and C++, cross-compilation is a nightmare. With Rust, it’s like two lines to install the toolchain, one line in a config file, and we’re ready to go. The Rust safety features speed up development a lot as well. Basically, as long as we don’t use unsafe, we know we’re not going to break the satellite. Right now, most of our issues with debugging are actually coming from C code. We’ve really written a large amount of Rust. But, some of those C libraries that I said we trusted, did have some bugs. So, we have to fix those. If they had been written in Rust, I don’t think we would have those problems.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>Since you mentioned breaking the satellite, I’m curious whether you have over-the-air update capabilities if something went wrong during a flight?</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>There’s actually a really interesting anecdote about this. I wrote the bootloaders on the current satellite in a few weeks. So, we’re moving very fast. And, indeed those have self-update capabilities. Since it was developed quickly, we’re still fighting with it a little bit, but it works. It’s easy to update software, but where you’re constrained is the hardware. We’re using off-the-shelf hardware, so we have to work with what we have.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>You talked so much about mass. Does the size of the hardware that you use constrain the size of the programs that you can write?</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>Yeah, so we have three layers of software involved. There’s two bootloader layers and one application layer. Because we’re subject to radiation we have backups and what’s called a golden image. The golden image is basically the factory reset capability. This all quickly takes up a lot of room, so we have been quite tight in terms of code space with the bootloaders. That hasn’t been so much of a problem with the flight software yet. But, we’re still iterating on the flight software. We’re currently sending a lot of updates to the spacecraft. Overall, right now we’re not too constrained, but I’m sure the time will come.</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>There are some good tools we use to help us with code size. And, all of the tools that can be applied to C binaries can be used on Rust binaries. The space efficiency of Rust is really not too bad.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>Are there any specific problems or challenges that you’ve seen lately?</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>So, we already discussed the bootloaders. Radiation tolerance is another one we mentioned. On top of the backups I mentioned, we also had to implement memory scrubbing to look for errors in memory and correct them. We can also reduce the sensitivity of the hardware to radiation by carefully choosing when to turn it on and off. So, radiation is a big problem.</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>I briefly mentioned before that there are multiple ways to open a sail. The simple and easy way is with structural booms that are basically masts that extend and hold the sail. But, that’s not what we’re using. We use spin-stabilization. So, the whole spacecraft rotates. We have small masses at the edge of the sail and the centrifugal force on those masses keeps the sail tight. This means that the sail is flexible, and everything on the craft vibrates. This creates lots of interesting problems. We have to avoid things like having the sail refold on itself, which would be a nightmare.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>I’d be interested to learn a little more about GAMA as a company. How big is the company right now by headcount?</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>There are about twenty employees right now. There are two leaders of the company and eighteen to twenty engineers. Interns come and go. The engineering school I came from is like two kilometers from here, so we get great interns who come from there.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>How many of the employees are in embedded and GNC?</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>The whole team is about ten people. I’ve been managing that team for like two and a half years. It started with just me, but I hired a bunch of people. So, the team is quite large. I spend my time managing the team and still doing some technical work. It’s a real challenge balancing the two.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>Yeah, that’s always a challenge. What have you learned in that process?</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>So much! For example, the team is really great at finding stuff we want to work on, but the things we need to work on for the mission can sometimes be harder to identify. I spend a lot of time thinking about what is the minimum viable solution for the mission so that we don’t waste time building other stuff.</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>It’s also been a difficult balance to figure out the right amount of pressure to put on the team so that they can deliver. Too much pressure makes things break, but too little pressure makes it so things never get done. It’s been a real human experience figuring out this balance.</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>Also, hiring is hard. I had never hired anyone before. I’ve probably done almost a hundred interviews now. And, it’s really hard to figure out how to evaluate people. A lot of engineers are very nice and enthusiastic, but sometimes enthusiasm doesn’t translate into actual results. There’s also the challenge of creating a team that is coherent as a group. I think we have a very good team dynamic, and everyone wants to keep that.</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>I think that covers all of my questions. Is there anything else that you’d like to share with our audience?</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>Well, we’re a startup, and we’re hiring! So, if you think you are very prolific, high initiative, and you like companies that move fast, drop me an email (chris@gamaspace.com), and/or set up your filtra so I can reach you there. Just as a small anecdote, when everyone leaves for a couple of weeks on holiday over the summer, they get back and everything has changed. That’s how fast the company moves. It’s very exciting! So, if you like speed, please reach out!</Paragraph>
                    <Paragraph><Speaker>Drew: </Speaker>That is exciting, and I think our audience will be really excited to learn about GAMA. Thanks so much Chris!</Paragraph>
                    <Paragraph><Speaker>Chris: </Speaker>No problem!</Paragraph>
                </ContentContainer>
            </Container>
            <Container>
            <OrangeLink
                to={'/'} 
            >
                get rust jobs on filtra 
            </OrangeLink>
            </Container>
        </Background>
}

export default RustGAMASep23 