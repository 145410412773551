import React from 'react';

import NumJobsPng from './numjobs.png';
import NumCompanies from './numcompanies.png';
import LargeHirers from './largehirers.png';
import OtherHirers from './otherhirers.png';
import LargeIndustries from './largeindustries.png';
import OtherIndustries from './otherindustries.png';
import Levels from './levels.png';

import RustReport from '../RustReport';


const RustReportApr23 = () => {
    return <RustReport 
        title={'Rust Jobs Report: April 2023'}
        subtitle={'Welcome to the April 2023 Rust Jobs Report. We maintain an index of companies hiring for Rust roles. Filtra has received an exciting level of support from the Rust community. As a thank you, we have elected to share this data publicly.'}
        titleNote={'To add your or another company to the index, please email filtra@filtra.io with the subject line "Rust Index"'}
        openPositionsPng={NumJobsPng}
        openPositionsDesc={"April was a banner month for the report. We added more than 100 jobs to the total."}
        numCompaniesPng={NumCompanies}
        numCompaniesDesc={'Thanks to some thoughtful community contributions, we uncovered new pools of jobs at big name companies like Apple and SpaceX.'}
        largeHirersPng={LargeHirers}
        largeHirersDesc={"Back from a lull in March, Cloudflare is leading the way again. This month's leaderboard also includes large newcomers like Amazon, Apple, and SpaceX."}
        topTenCompanies={[
            'Cloudflare',
            'Amazon',
            'Apple',
            'SpaceX',
            'Workato',
            'Crypto.com',
            'Ditto',
            'Google',
            'DataDog',
            'Stockly',
        ]}
        otherHirersPng={OtherHirers}
        otherHirersDesc={"The list of other hirers continues to grow. If you don't see your company here, let us know at filtra@filtra.io."}
        topTenIndustriesPng={LargeIndustries}
        topTenIndustriesDesc={""}
        topTenIndustries={[
            'cloud/infrastructure',
            'crypto',
            'security',
            'productivity',
            'aerospace',
            'browser',
            'monitoring/reliability',
            'messaging/notifications',
            'systems/hardware',
            'marketplace',
        ]}
        otherIndustriesPng={OtherIndustries}
        otherIndustriesDesc={''}
        seniorityPng={Levels}
        seniorityDesc={"It remains challenging for early-career engineers to find jobs writing Rust."}
    />
}

export default RustReportApr23 