
import React from 'react';

import NumJobsPng from './numjobs.png';
import NumCompanies from './numcompanies.png';
import LargeHirers from './largehirers.png';
import OtherHirers from './otherhirers.png';
import LargeIndustries from './largeindustries.png';
import OtherIndustries from './otherindustries.png';

import RustReport from '../RustReport';


const RustReportFeb23 = () => {
    return <RustReport 
        title={'Rust Jobs Report: February 2023'}
        subtitle={'Welcome to the February 2023 Rust Jobs Report. We maintain an index of companies hiring for Rust roles. Filtra has received an exciting level of support from the Rust community. As a thank you, we have elected to share this data publicly.'}
        titleNote={'To add your or another company to the index, please email filtra@filtra.io with the subject line "Rust Index"'}
        openPositionsPng={NumJobsPng}
        openPositionsDesc={"The most notable trend for February was a decrease in the number of available jobs. It would seem that even Rust cannot defy the gravity of a slowing economy :( "}
        numCompaniesPng={NumCompanies}
        numCompaniesDesc={'While the overall number of jobs decreased, the number of hiring companies shrank only a small amount. Perhaps smaller companies are "buying the dip," hiring a handful of roles while the talent competition is less fierce.'}
        largeHirersPng={LargeHirers}
        largeHirersDesc={"Syndica is the only company making their debut on the leaderboard this month, but Fly.io also had a shoutout-worthy performance, significantly growing their open roles."}
        topTenCompanies={[
            'Cloudflare',
            'Ditto',
            'Matter Labs',
            'Fortanix',
            'Fly.io',
            'DataDog',
            'Heliax',
            'Embark',
            'Parity',
            'Syndica',
        ]}
        otherHirersPng={OtherHirers}
        otherHirersDesc={"We had a hard time finding new companies to add to the index this month. If you know a company that is hiring Rust engineers and isn't represented here, don't forget to ping us. (filtra@filtra.io)"}
        topTenIndustriesPng={LargeIndustries}
        topTenIndustriesDesc={"We were excited to see some cool industries like gaming make strong showings this month."}
        topTenIndustries={[
            'cloud/infrastructure',
            'security',
            'crypto',
            'monitoring/reliability',
            'gaming',
            'systems/hardware',
            'messaging/notifications',
            'iot/robotics/automotive',
            'fintech',
            'browser',
        ]}
        otherIndustriesPng={OtherIndustries}
        otherIndustriesDesc={'The list of other industries contains all the usual suspects.'}
    />
}

export default RustReportFeb23 