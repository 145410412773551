import React from "react";
import LoginButton from '../LoginButton';
import LogoutButton from '../LogoutButton';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from "react-router-dom";
import LogoImg from '../../assets/filtra_logo.png'
import { Container, MenuLink, MenuLinkContainer, LinkBar, ButtonContainer, LinkBarContainer, Logo, HomeButtonContainer, NavButton, NavDropDown, NavDropItem } from "./RecruiterHeader.styles";
import { useState } from "react";

const RecruiterHeader = ({ userExists, useBackground=true, hideCenter=false }) => {
    const { isAuthenticated } = useAuth0();
    const location = useLocation()
    const navigate = useNavigate()
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    return <Container style={{background: useBackground ? 'var(--grey-200)' : 'none'}}>
        <ButtonContainer>
            <HomeButtonContainer>
                <NavButton
                    onClick={() => { 
                        if (isAuthenticated) {
                            setIsMenuOpen(!isMenuOpen)
                        } else {
                            navigate('/')
                        }
                    }} 
                >
                    <Logo src={LogoImg} />
                </NavButton>
                {isMenuOpen ?
                    <NavDropDown>
                        <NavDropItem 
                            onClick={() => navigate('/recruiters/profile')}
                            style={{color: location.pathname === '/recruiters/profile' ? 'var(--orange)' : 'var(--grey-500)'}}
                        >
                            profile
                        </NavDropItem>
                        <NavDropItem 
                            onClick={() => navigate('/recruiters/companies')}
                            style={{color: location.pathname === '/recruiters/companies' ? 'var(--orange)' : 'var(--grey-500)'}}
                        >
                            co's & jobs
                        </NavDropItem>
                        <NavDropItem 
                            onClick={() => navigate('/recruiters/contacted')}
                            style={{color: location.pathname === '/recruiters/contacted' ? 'var(--orange)' : 'var(--grey-500)'}}
                        >
                            contacted
                        </NavDropItem>
                    </NavDropDown>
                : null}
            </HomeButtonContainer>
        </ButtonContainer>
        <LinkBarContainer>
            {isAuthenticated && !hideCenter ? 
                    <LinkBar>
                        <>
                            <MenuLinkContainer>
                                <MenuLink 
                                    to={'/recruiters/profile'} 
                                    style={{color: location.pathname === '/recruiters/profile' ? 'var(--orange)' : 'var(--grey-500)'}}
                                >profile</MenuLink>
                            </MenuLinkContainer>
                            {userExists ? 
                                <>
                                    <MenuLinkContainer>
                                        <MenuLink 
                                            to={'/recruiters/companies'} 
                                            style={{color: location.pathname === '/recruiters/companies' ? 'var(--orange)' : 'var(--grey-500)'}}
                                        >companies & jobs</MenuLink>
                                    </MenuLinkContainer>
                                </>
                            : null}
                            {userExists ? 
                                <>
                                    <MenuLinkContainer>
                                        <MenuLink 
                                            to={'/recruiters/contacted'} 
                                            style={{color: location.pathname === '/recruiters/contacted' ? 'var(--orange)' : 'var(--grey-500)'}}
                                        >contacted</MenuLink>
                                    </MenuLinkContainer>
                                </>
                            : null}
                        </>
                    </LinkBar>
            : null }
        </LinkBarContainer>
        <ButtonContainer>
            {isAuthenticated ? <LogoutButton /> : <LoginButton /> }
        </ButtonContainer>
    </Container>
}

export default RecruiterHeader;
