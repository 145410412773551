import { BarContainer, BarLabel, BarLabelValue, RowContainer, LabelContainer, Container } from './BarChart.styles.js';

const BarChart = ({data}) => {

    const ValueBar = ({label, value, max}) => {
        return (
            <RowContainer>
                <LabelContainer>
                    <BarLabel>{label}</BarLabel>
                    <BarLabelValue>{value}</BarLabelValue>
                </LabelContainer>
                <BarContainer>
                    <progress max={max} value={value}/>
                </BarContainer>
            </RowContainer>
        )
    }

    const max = Math.max(...data.map(([_, value]) => value));

    return (
        <Container>
            {data.map((item, index) => (
                <ValueBar key={index} max={max} value={item[1]} label={item[0]}/>  
            ))}
        </Container>
    )
}

export default BarChart;