import React from 'react';

import RustReportV2Ad from '../RustReportV2Ad';

const RustReportJun24 = () => {
    const NUM_JOBS = 776
    const NUM_COMPANIES = 108
    const LARGE_HIRERS = [
        ['Amazon', 186],
        ['Microsoft', 91],
        ['DataDog', 47],
        ['Apple', 35],
        ['Cloudflare', 29],
        ['Canonical', 26],
        ['SpaceX', 18],
        ['Crypto.com', 18],
        ['IBM', 14],
        ['Worldcoin', 14],
    ]
    const OTHER_HIRERS = [
        ['Figma', 12],
        ['Google ', 10],
        ['HelsingAI', 10],
        ['IOHK', 9],
        ['Discord', 9],
        ['KeyRock', 8],
        ['Parity', 8],
        ['Mozilla', 8],
        ['Open Cosmos', 8],
        ['Syndica', 7],
        ['Matter Labs', 7],
        ['Smarkets', 6],
        ['Deliveroo', 6],
        ['Scaleway', 6],
        ['HyperExponential', 6],
        ['Ditto', 6],
        ['Fortanix', 6],
        ['Deep Fence', 5],
        ['Osmosis Labs', 5],
        ['Zoo', 5],
        ['Volume Finance', 5],
        ['SmartThings', 5],
        ['Ardan Labs', 5],
        ['Ellipsis Labs', 4],
        ['Tesla', 4],
        ['Okta', 4],
        ['Magic Eden', 3],
        ['Heliax', 3],
        ['Svix', 3],
        ['OneSignal', 3],
        ['ApolloGraphQL', 3],
        ['InfinyOn', 3],
        ['Stockly', 3],
        ['HealPay', 3],
        ['Ledger', 3],
        ['PingCAP', 3],
        ['Conduit', 3],
        ['ZORA', 3],
        ['Axiom', 3],
        ['GitHub', 3],
        ['Aztec', 3],
        ['informal', 3],
        ['Akamai', 3],
        ['OpenTalk', 2],
        ['Zama', 2],
        ['Railway', 2],
        ['Activision', 2],
        ['Scythe Robotics', 2],
        ['Matic', 2],
        ['Sentry', 2],
        ['Qovery', 2],
        ['Qdrant', 2],
        ['Rapid7', 2],
        ['Tabnine', 2],
        ['Prisma', 2],
        ['KION', 2],
        ['Imperva', 2],
        ['10X Genomics', 2],
        ['OpenAI', 2],
        ['Uniswap Labs', 2],
        ['Embark', 2],
        ['Tonari', 1],
        ['360dialog', 1],
        ['Blowfish', 1],
        ['Shadow', 1],
        ['ReadySet', 1],
        ['Argent', 1],
        ['StarkWare', 1],
        ['amo', 1],
        ['Fireblocks', 1],
        ['Materialize', 1],
        ['Volka', 1],
        ['AllSpice', 1],
        ['FP Complete', 1],
        ['Meilisearch', 1],
        ['1Password', 1],
        ['arm', 1],
        ['Grafbase', 1],
        ['Swift Navigation', 1],
        ['Threema', 1],
        ['Qwiet AI', 1],
        ['Astropad', 1],
        ['Fullstory', 1],
        ['Immunant', 1],
        ['Star Lab', 1],
        ['LINE', 1],
        ['Cymo', 1],
        ['Clever Cloud', 1],
        ['shuttle', 1],
        ['Holo', 1],
        ['Liquid Analytics', 1],
        ['UXStream', 1],
        ['Matchday', 1],
        ['DSCVR', 1],
        ['Chorus One', 1],
        ['Qumulo', 1],
        ['Meta', 1],
        ['Zed Industries', 1],
    ]
    const LARGE_INDUSTRIES = [
        ['cloud/infrastructure', 238],
        ['crypto', 116],
        ['productivity', 112],
        ['systems/hardware', 64],
        ['monitoring/reliability', 49],
        ['security', 30],
        ['aerospace', 26],
        ['data science', 23],
        ['consultancy', 21],
        ['browser', 18],
    ]
    const OTHER_INDUSTRIES = [
        ['messaging/notifications', 16],
        ['iot/robotics/automotive', 15],
        ['fintech', 15],
        ['databases', 10],
        ['marketplace', 9],
        ['gaming', 6],
        ['social media', 2],
        ['animation', 2],
        ['health/biotech', 2],
        ['martech', 1],
        ['networking', 1],
    ]
    const LEVELS = [
        ['junior', 7],
        ['mid', 423],
        ['senior', 346],
    ]
    return <RustReportV2Ad
        title={'Rust Jobs Report: June 2024'}
        subtitle={'Welcome to the June 2024 Rust Jobs Report by filtra. Please enjoy our overview of the Rust job market.'}
        titleNote={'To add your or another company to the filtra Rust jobs index, please email filtra@filtra.io with the subject line "Rust Index"'}
        openPositions={NUM_JOBS}
        openPositionsDesc={'June brought a bit of a slowdown, with the total number of postings found dropping to 776 from 815.'}
        numCompanies={NUM_COMPANIES}
        numCompaniesDesc={'Despite the slight drop in total postings, the number of hirers stayed exactly the same at 108.'}
        largeHirers={LARGE_HIRERS}
        largeHirersDesc={'Most of the top hirers have held firm for the last several months. However, it is interesting to note the continued drop in postings at Cloudflare that\'s been playing out over a few months.'}
        otherHirers={OTHER_HIRERS}
        otherHirersDesc={"We're always adding new companies to the list of hirers. One notable newcomer this month is Zed Industries! If you know of a company that should be on this list but isn't, let us know: filtra@filtra.io."}
        topTenIndustries={LARGE_INDUSTRIES}
        topTenIndustriesDesc={'The consistency in the list of top hirers translates to consistency in the list of top industries. However, it\'s worth noting that a lot of the top hirers like Amazon and Microsoft have very diverse interests.'}
        otherIndustries={OTHER_INDUSTRIES}
        otherIndustriesDesc={'Though most usage is concentrated in a few industries, Rust is being used almost everywhere to some degree.'}
        seniority={LEVELS}
        seniorityDesc={"Rust continues to be used almost exclusively in jobs for more experienced programmers."}
    />
}

export default RustReportJun24 