import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ButtonContainer, Column, Container } from './UseLinkDialog.styles';
import { InfoBox, SmallButton } from '../../GlobalStyle';
import ShortBasicInput from '../ShortBasicInput';

const UseLinkDialog = ({ closer }) => {
    const { user } = useAuth0();
    const id = user.sub.split('|')[1];

    return (
        <Container>
            <Column>
                <InfoBox>
                    Congrats! You're done. Now all you have to do is
                    post this link where recruiters are finding you.
                    (LinkedIn Contacts, LinkedIn About, Website, Blog, etc.)
                    We also sent you a test email so you know what 
                    a filtra opportunity looks like. Make sure it's not in spam!
                </InfoBox>
                <ShortBasicInput
                    label={'filtra link'}
                    placeholder={'link is missing'}
                    val={`https://filtra.io/filters/${id}`}
                    readOnly={true}
                />
                <ButtonContainer>
                    <SmallButton
                        onClick={() => { 
                            navigator.clipboard.writeText(`https://filtra.io/filters/${id}`)
                            closer(false)                            
                        }} 
                    >copy link</SmallButton>
                </ButtonContainer>
            </Column>
        </Container>
    );
};

export default UseLinkDialog

