import React from 'react';

import RustReportV2Ad from '../RustReportV2Ad';

const RustReportMay24 = () => {
    const NUM_JOBS = 815
    const NUM_COMPANIES = 108
    const LARGE_HIRERS = [
        ['Amazon', 163],
        ['Microsoft', 112],
        ['DataDog', 44],
        ['Cloudflare', 37],
        ['Apple', 33],
        ['Meta', 30],
        ['SpaceX', 27],
        ['Canonical', 27],
        ['Worldcoin', 18],
        ['Google ', 13],
    ]
    const OTHER_HIRERS = [
        ['IOHK', 11],
        ['Crypto.com', 11],
        ['HelsingAI', 11],
        ['Figma', 10],
        ['1Password', 9],
        ['Syndica', 9],
        ['Discord', 9],
        ['Fortanix', 9],
        ['KeyRock', 8],
        ['Matter Labs', 7],
        ['Mozilla', 6],
        ['Activision', 5],
        ['Deep Fence', 5],
        ['Volume Finance', 5],
        ['Smarkets', 5],
        ['Zoo', 5],
        ['Ardan Labs', 5],
        ['Sentry', 5],
        ['HyperExponential', 5],
        ['Osmosis Labs', 5],
        ['SmartThings', 5],
        ['Deliveroo', 5],
        ['Okta', 5],
        ['Ditto', 4],
        ['Stockly', 4],
        ['Akamai', 4],
        ['Ellipsis Labs', 4],
        ['Magic Eden', 4],
        ['Scaleway', 4],
        ['OneSignal', 4],
        ['Parity', 4],
        ['Conduit', 3],
        ['HealPay', 3],
        ['Open Cosmos', 3],
        ['informal', 3],
        ['ZORA', 3],
        ['Scythe Robotics', 3],
        ['Aztec', 3],
        ['InfinyOn', 3],
        ['Axiom', 3],
        ['PingCAP', 3],
        ['Quadratic', 3],
        ['Imperva', 3],
        ['Heliax', 3],
        ['GitHub', 3],
        ['Rapid7', 3],
        ['IBM', 3],
        ['Ledger', 3],
        ['OpenTalk', 2],
        ['Matic', 2],
        ['OpenAI', 2],
        ['Swift Navigation', 2],
        ['Aleph Alpha', 2],
        ['Embark', 2],
        ['Railway', 2],
        ['Grafbase', 2],
        ['ApolloGraphQL', 2],
        ['Uniswap Labs', 2],
        ['Svix', 2],
        ['arm', 2],
        ['10X Genomics', 2],
        ['Tabnine', 2],
        ['Fullstory', 2],
        ['Qovery', 2],
        ['KION', 2],
        ['Qdrant', 2],
        ['shuttle', 1],
        ['Adobe', 1],
        ['Star Lab', 1],
        ['Blowfish', 1],
        ['Clever Cloud', 1],
        ['Chorus One', 1],
        ['Tesla', 1],
        ['Fireblocks', 1],
        ['Argent', 1],
        ['Shadow', 1],
        ['Dish', 1],
        ['Tonari', 1],
        ['StarkWare', 1],
        ['Chef', 1],
        ['Volka', 1],
        ['amo', 1],
        ['Zama', 1],
        ['Threema', 1],
        ['Cymo', 1],
        ['DSCVR', 1],
        ['FP Complete', 1],
        ['Toyota Connected', 1],
        ['AllSpice', 1],
        ['Meilisearch', 1],
        ['Liquid Analytics', 1],
        ['UXStream', 1],
        ['Astropad', 1],
        ['TrueLayer', 1],
        ['AppSignal', 1],
        ['SurrealDB', 1],
        ['ReadySet', 1],
        ['Prisma', 1],
    ]
    const LARGE_INDUSTRIES = [
        ['cloud/infrastructure', 219],
        ['productivity', 131],
        ['crypto', 113],
        ['systems/hardware', 64],
        ['monitoring/reliability', 50],
        ['security', 43],
        ['social media', 31],
        ['data science', 30],
        ['aerospace', 30],
        ['browser', 19],
    ]
    const OTHER_INDUSTRIES = [
        ['messaging/notifications', 16],
        ['fintech', 14],
        ['iot/robotics/automotive', 14],
        ['consultancy', 9],
        ['marketplace', 9],
        ['gaming', 8],
        ['databases', 8],
        ['animation', 2],
        ['networking', 2],
        ['health/biotech', 2],
        ['streaming', 1],
    ]
    const LEVELS = [
        ['junior', 17],
        ['mid', 419],
        ['senior', 379],
    ]
    return <RustReportV2Ad
        title={'Rust Jobs Report: May 2024'}
        subtitle={'Welcome to the May 2024 Rust Jobs Report by filtra. Please enjoy our overview of the Rust job market.'}
        titleNote={'To add your or another company to the filtra Rust jobs index, please email filtra@filtra.io with the subject line "Rust Index"'}
        openPositions={NUM_JOBS}
        openPositionsDesc={'Despite the usual summer slowdown in hiring, the number of Rust jobs we found ticked up slightly month over month, reaching a total of 815!'}
        numCompanies={NUM_COMPANIES}
        numCompaniesDesc={'The overall number of hirers also moved up month over month from 103 to 108. And remember, these are just the companies that are CURRENTLY HIRING, not all companies using Rust.'}
        largeHirers={LARGE_HIRERS}
        largeHirersDesc={'The list of top Rust hirers has been mostly stable for several months now. However, this month saw IBM drop from the leader board. Google is the new number 10.'}
        otherHirers={OTHER_HIRERS}
        otherHirersDesc={"The list of other hirers continues to grow, featuring giants and startups alike. One giant making its first appearance this month is Tesla. As for an interesting new entrant on the startup side, checkout Quadratic. They're building spreadsheets with WASM + WebGL! If we missed a company on this list, don't hesitate to point it out: filtra@filtra.io."}
        topTenIndustries={LARGE_INDUSTRIES}
        topTenIndustriesDesc={'The prominence of Rust in the "cloud/infrastructure" and "productivity" categories is largely due to a few huge hirers. On the other hand, the "crypto" category represents the aggregate of tons of small companies.'}
        otherIndustries={OTHER_INDUSTRIES}
        otherIndustriesDesc={'Rust has reached a point in it\'s maturity where it\'s pretty much being used for everything you can think of.'}
        seniority={LEVELS}
        seniorityDesc={"Rust jobs aren't only for senior level engineers. In fact, there are slightly more jobs available at the mid level. But, there is a paucity of entry-level Rust jobs. New programmers looking for a job in Rust might consider getting their first job in a different tech stack. Diehards should look at Cloudflare's internship program, 1Password's internship program, or entry level jobs at companies like HealPay and Stockly."}
    />
}

export default RustReportMay24