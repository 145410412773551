import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 10%;
    top: 25%;
    border: var(--border);
    background-color: var(--white);
    color: var(--black);
    width: 80%;
    height: 50%;
    @media (min-width: 1000px) {
        left: 20%;
        width: 60%;
    }
`

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
`
export const ButtonContainer = styled.div`
    align-self: flex-end;
`