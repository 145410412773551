import styled, { createGlobalStyle } from 'styled-components';
import BackgroundImage from './assets/coloringbookbackground1.png';

export const GlobalStyle = createGlobalStyle`
    :root {
        --black: #2f332eff;
        --white: #f7fff7ff;
        --orange: #c2380eff;
        --navy: #051075ff;
        --blue: #2535c2ff;
        --azul: #1363f0ff;
        --green: #667100ff;
        --border: 6px solid var(--black);
        --medBorder: 4.5px solid var(--black);
        --smallBorder: 3px solid var(--black);
        --orangeBorder: 6px solid var(--orange);
        --grey-100: #adbaa9ff;
        --grey-200: #97a395ff;
        --grey-300: #828c80ff;
        --grey-400: #6a7368ff;
        --grey-500: #555c54ff;
        --grey-600: #424741ff;
        --grey-700: #2f332eff;
    }

    .txt-white {
      color: var(--white)
    }

`;

export const SmallButton = styled.button`
  color: var(--black);
  background-color: var(--white);
  font-family: 'Noto Sans';
  font-size: 1em;
  font-weight: 600;
  margin: 0.5em 0 0.5em 0;
  padding: 0 1em 0 1em;
  border: var(--medBorder);
  height: 2em;
  cursor: pointer;
  &:hover {
    background-color: var(--grey-600);
    color: var(--white);
    font-weight: 500;
  }
  &:focus {
    background-color: var(--grey-600);
    color: var(--white);
    font-weight: 500;
    outline: none;
  }
`;

export const MedButton = styled.button`
  color: var(--black);
  background-color: var(--white);
  font-family: 'Noto Sans';
  font-size: 1.5em;
  font-weight: 600;
  margin: 1em 0 1em 0;
  border: var(--medBorder);
  padding: 0 1em 0 1em;
  cursor: pointer;
  &:hover {
    background-color: var(--grey-600);
    color: var(--white);
    font-weight: 500;
  }
  &:focus {
    background-color: var(--grey-600);
    color: var(--white);
    font-weight: 500;
    outline: none;
  }
`;

export const Background = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${BackgroundImage});
  background-size: cover;
  width: 100vw;
  min-height: 100vh;
`;

export const BackgroundPlain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--grey-100);
  width: 100vw;
  min-height: 100vh;
`;

export const SectionHeader = styled.h2`
  color: var(--white);
  background-color: var(--black);
  padding: 0.3em;
  font-family: 'Noto Sans';
  font-size: 2em;
  font-weight: normal;
  margin-bottom: 0.5rem;
`;

export const SubsectionHeaderBorder = styled.div`
  border-bottom: 5px solid var(${(props) => props.borderColor});
`

export const SubsectionHeader = styled.h3`
  color: var(--white);
  background-color: var(--black);
  padding: 0.5em;
  font-family: 'Noto Sans';
  font-size: 1.75em;
  font-weight: 500;
  margin: 0 0 0 0;
`;

export const InfoBox = styled.p`
  color: var(--black);
  font-family: 'Noto Sans';
  font-weight: 500;
  font-size: 0.85em;
  padding: 0 0.5em 0 0.5em;
  margin: 1rem 0 1rem 0;
`;

export const InfoInline = styled.span`
  color: var(--grey-400);
  font-family: 'Noto Sans';
  font-size: 0.85em;
  padding: 0 0.5em 0 0.5em;
`;

export const Required = styled.span`
  color: var(${(props) => props.highlightColor});
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 80vw;
  min-width: 350px;
  @media (min-width: 600px) {
    width: 60vw;
  }
  @media (min-width: 1000px) {
    width: 25vw;
  }
  margin: 5rem 0 3rem 0;
`;

export const ButtonContainer = styled.div`
  align-self: flex-end;
`;

export const SectionContainer = styled.div`
    margin: 0 0 5rem 0;
`
