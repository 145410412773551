import React from 'react';
import { Container, Input, Label, LabelError } from './CheckBoxInput.styles';

const CheckBoxInput = ({ label, checked, onChange, accentColor, error }) => {
    return <Container>
        <Label>
            {label}
            <LabelError>{error}</LabelError>
        </Label>
        <Input
            accentColor={accentColor}
            type='checkbox'
            checked={checked}
            onChange={({ target }) => {
                onChange(target.checked)
            }}
        />
    </Container>
}

export default CheckBoxInput;
