import React, { useEffect } from 'react';
import { Container, BackgroundOrange, ValuePropContainer, Tagline, ValueProp, ScrollButton, DemoLink} from './RecruiterLandingPage.styles.js';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import RecruiterHeader from '../RecruiterHeader/index.js';

const RecruiterLandingPage = () => {
    const { isAuthenticated } = useAuth0();
    const navigate = useNavigate();
    
    if (isAuthenticated) {
        navigate('/profile')
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return <BackgroundOrange>
            <RecruiterHeader hideCenter={true} useBackground={false} />
            <Container>
                <ValuePropContainer>
                    <Tagline>
                        The new protocol for tech recruiting!
                    </Tagline>
                    <ValueProp>
                        Filtra is setting the standard for a more productive tech recruiting future. Every connection curated.  Every outreach endorsed.
                    </ValueProp>
                    <DemoLink href={'http://calendly.com/filtra-demo'}>book a demo</DemoLink>
                </ValuePropContainer>
            </Container>
            <Container>
                <ValuePropContainer>
                    <Tagline>
                        Curated Connections
                    </Tagline>
                    <ValueProp>
                        Skip cold outreach by matching your open positions to candidates who actually want them. We ask engineers about the things they care about so we can create a curated match.
                    </ValueProp>
                    <DemoLink href={'http://calendly.com/filtra-demo'}>book a demo</DemoLink>
                </ValuePropContainer>
            </Container>
            <Container>
                <ValuePropContainer>
                    <Tagline>
                        Endorsed Outreach
                    </Tagline>
                    <ValueProp>
                        Developers trust the filtra brand to deliver only the most relevant opportunities. Let us pave the way for a productive conversation.
                    </ValueProp>
                    <DemoLink href={'http://calendly.com/filtra-demo'}>book a demo</DemoLink>
                </ValuePropContainer>
            </Container>
            <ScrollButton
                onClick={() => { 
                    if ((window.innerHeight + window.scrollY + 200) >= document.body.scrollHeight) {
                        window.scrollTo({top: 0, behavior: 'smooth'})
                    } else {
                        window.scrollBy({top: window.innerHeight - window.innerHeight * 0.1, behavior: 'smooth'})
                    }
                }} 
            >
                &#8595;
            </ScrollButton>
        </BackgroundOrange>
}

export default RecruiterLandingPage
