import React, { useState, useEffect } from 'react';
import Header from '../Header';
import {
    SubsectionHeader,
    Form,
    ButtonContainer,
    SectionContainer,
    MedButton,
    BackgroundPlain,
    InfoBox,
    Required,
} from '../../GlobalStyle';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../Loading';
import CheckBoxInput from '../CheckBoxInput';
import ShortBasicInput from '../ShortBasicInput';
import MultipleOptionInput from '../MultipleOptionInput';
import PanelInput from '../PanelInput';
import api from '../../api';
import handleAPIError from '../../api/error';
import UseLinkDialog from '../UseLinkDialog';
import { CopyButton, CopyIcon, LinkContainer } from './FilterConfig.styles';
import CopyIconImage from '../../assets/copy_icon.png';
import { useNavigate } from 'react-router-dom';
import SignupProgress from '../SignupProgess';
import { LabelError } from '../ShortBasicInput/ShortBasicInput.styles';

const FilterConfig = () => {
    const navigate = useNavigate()
    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const id = user.sub.split('|')[1];

    const [isLoading, setIsLoading] = useState(false);
    const [exists, setExists] = useState(true);
    const [showLinkDialog, setShowLinkDialog] = useState(false)
    const [minSalary, setMinSalary] = useState('');
    const [stockOrEquity, setStockOrEquity] = useState(false);
    const [paidTimeOff, setPaidTimeOff] = useState('')
    const [parentalLeave, setParentalLeave] = useState('')
    const [health, setHealth] = useState(false);
    const [life, setLife] = useState(false);
    const [dental, setDental] = useState(false);
    const [retirement, setRetirement] = useState(false);
    const [vision, setVision] = useState(false);
    const [tuition, setTuition] = useState(false);
    const [workType, setWorkType] = useState([]);
    const [skills, setSkills] = useState([]);
    const [workPlace, setWorkPlace] = useState([]);
    const [locations, setLocations] = useState([]);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const getPreferences = async () => {
            setIsLoading(true);
            setErrors({});
            try {
                const claims = await getIdTokenClaims()
                if (claims['https://filtra.io/roles'].includes('Recruiter')) {
                    navigate('/recruiters/profile')
                } else {
                    const token = await getAccessTokenSilently();
                    const response = await api.get(
                        `/people/${id}/filter/configuration`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    setMinSalary(response.data.minSalary);
                    setStockOrEquity(response.data.stockOrEquity);
                    setPaidTimeOff(response.data.paidTimeOff);
                    setParentalLeave(response.data.parentalLeave);
                    setHealth(response.data.health);
                    setLife(response.data.life);
                    setDental(response.data.dental);
                    setRetirement(response.data.retirement);
                    setVision(response.data.vision);
                    setTuition(response.data.tuition);
                    setWorkType(response.data.workType);
                    if (response.data.skills !== undefined) {
                        setSkills(response.data.skills);
                    }
                    setWorkPlace(response.data.workPlace);
                    if (response.data.locations !== undefined) {
                        setLocations(response.data.locations);
                    }
                }
            } catch (err) {
                if (err.response.data.status === 404) {
                    setExists(false);
                } else {
                    handleAPIError(err);
                }
            }
            setIsLoading(false);
        };
        getPreferences();
    }, [id, getAccessTokenSilently, getIdTokenClaims, navigate]);

    const postPreferences = async (body) => {
        setIsLoading(true);
        setErrors({});
        try {
            const token = await getAccessTokenSilently();
            await api.post(`/people/${id}/filter/configuration`, body, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setExists(true);
            setShowLinkDialog(true)
        } catch (err) {
            const errorMsgs = handleAPIError(err);
            if (errorMsgs) setErrors(errorMsgs);
        }
        setIsLoading(false);
    };

    const patchPreferences = async (body) => {
        setIsLoading(true);
        setErrors({});
        try {
            const token = await getAccessTokenSilently();
            await api.patch(`/people/${id}/filter/configuration`, body, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        } catch (err) {
            const errorMsgs = handleAPIError(err);
            if (errorMsgs) setErrors(errorMsgs);
        }
        setIsLoading(false);
    };

    const getSubmittableSkills = () => {
        const submittableSkills = skills.filter(
            (skill) => skill.title.length > 0
        );
        setSkills(submittableSkills);
        return submittableSkills;
    };

    const getSubmittableLocations = () => {
        const submittableLocations = locations.filter(
            (location) => location.name.length > 0
        );
        setLocations(submittableLocations);
        return submittableLocations;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const preferences = {
            ...(minSalary && !isNaN(minSalary) && { minSalary: parseInt(minSalary) }),
            stockOrEquity: stockOrEquity,
            ...(paidTimeOff && !isNaN(paidTimeOff) && { paidTimeOff: parseInt(paidTimeOff) }),
            ...(parentalLeave && !isNaN(parentalLeave) && { parentalLeave: parseInt(parentalLeave) }),
            health: health,
            life: life,
            dental: dental,
            retirement: retirement,
            vision: vision,
            tuition: tuition,
            workType: workType,
            skills: getSubmittableSkills(),
            workPlace: workPlace,
            locations: getSubmittableLocations(),
        };
        if (exists) {
            patchPreferences(preferences);
        } else {
            postPreferences(preferences);
        }
    };

    const addSkillPanel = () => {
        if (skills.filter((skill) => skill.title === '').length === 0) {
            const newSkills = skills.concat([
                { title: '', skillLevel: 'interested' },
            ]);
            setSkills(newSkills);
        }
    };

    const deleteSkillPanel = (index) => {
        const newSkills = skills.filter((skill, idx) => {
            return idx !== index;
        });
        setSkills(newSkills);
    };

    const addLocationPanel = () => {
        if (locations.filter((location) => location.name === '').length === 0) {
            const newLocations = locations.concat([
                { name: '', radius: 50 },
            ]);
            setLocations(newLocations);
        }
    };

    const deleteLocationPanel = (index) => {
        const newLocations = locations.filter((location, idx) => {
            return idx !== index;
        });
        setLocations(newLocations);
    };

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <BackgroundPlain>
                    <Header showFiltraConfig={true} showJobs={exists} />
                    <Form onSubmit={handleSubmit}>
                        {exists ? null :
                            <SignupProgress
                                value={66}
                                text={'page 3/3'}
                            />
                        }
                        <SectionContainer>
                            <SubsectionHeader borderColor={'--orange'}>
                                skills
                                <LabelError fontSize='0.5em'>{errors['skills']}</LabelError>
                            </SubsectionHeader>
                            <InfoBox>
                                List only the tech skills you want to exercise
                                at your next job. Rate your ability on the scale
                                of interested to expert.
                                <Required highlightColor={'--orange'}> *required</Required>
                            </InfoBox>
                            <PanelInput
                                accentColor={'--orange'}
                                isEnum={true}
                                vals={skills}
                                enumOptions={[
                                    'interested',
                                    'beginner',
                                    'intermediate',
                                    'proficient',
                                    'expert',
                                ]}
                                onAddPanel={addSkillPanel}
                                placeHolder={'skill'}
                                fieldNames={['title', 'skillLevel']}
                                onDeletePanel={deleteSkillPanel}
                                onChange={setSkills}
                                panelType={'skill'}
                            />
                        </SectionContainer>
                        <SectionContainer>
                            <SubsectionHeader borderColor={'--navy'}>
                                compensation
                            </SubsectionHeader>
                            <InfoBox>
                                What's the min. you require? (We'll
                                never share.)
                                <Required highlightColor={'--orange'}> *required</Required>
                            </InfoBox>
                            <ShortBasicInput
                                type={'number'}
                                borderColor={'--navy'}
                                label={'min. salary ($)'}
                                placeholder={'the min. salary you would accept'}
                                val={minSalary}
                                onChange={setMinSalary}
                                error={errors['minSalary']}
                            />
                            <InfoBox>
                                Check only if you require ownership as part of your compensation.
                            </InfoBox>
                            <CheckBoxInput
                                accentColor={'--navy'}
                                label={'stock or equity'}
                                checked={stockOrEquity}
                                onChange={setStockOrEquity}
                                error={errors['stockOrEquity']}
                            />
                        </SectionContainer>
                        <SectionContainer>
                            <SubsectionHeader borderColor={'--green'}>
                                benefits
                            </SubsectionHeader>
                            <InfoBox>
                                List your time off expectations.
                            </InfoBox>
                            <ShortBasicInput
                                borderColor={'--green'}
                                label={'paid time off (wks)'}
                                type={'number'}
                                placeholder={'the minimum paid weeks off you would accept'}
                                val={paidTimeOff}
                                onChange={setPaidTimeOff}
                                error={errors['paidTimeOff']}
                            />
                            <ShortBasicInput
                                borderColor={'--green'}
                                label={'parental leave (wks)'}
                                type={'number'}
                                placeholder={'the minimum paid weeks you would accept'}
                                val={parentalLeave}
                                onChange={setParentalLeave}
                                error={errors['parentalLeave']}
                            />
                            <InfoBox>
                                Mark those you consider essential.
                            </InfoBox>
                            <CheckBoxInput
                                accentColor={'--green'}
                                label={'health'}
                                checked={health}
                                onChange={setHealth}
                                error={errors['health']}
                            />
                            <CheckBoxInput
                                accentColor={'--green'}
                                label={'life'}
                                checked={life}
                                onChange={setLife}
                                error={errors['life']}
                            />
                            <CheckBoxInput
                                accentColor={'--green'}
                                label={'dental'}
                                checked={dental}
                                onChange={setDental}
                                error={errors['dental']}
                            />
                            <CheckBoxInput
                                accentColor={'--green'}
                                label={'retirement'}
                                checked={retirement}
                                onChange={setRetirement}
                                error={errors['retirement']}
                            />
                            <CheckBoxInput
                                accentColor={'--green'}
                                label={'vision'}
                                checked={vision}
                                onChange={setVision}
                                error={errors['vision']}
                            />
                            <CheckBoxInput
                                accentColor={'--green'}
                                label={'tuition'}
                                checked={tuition}
                                onChange={setTuition}
                                error={errors['tuition']}
                            />
                        </SectionContainer>
                        <SectionContainer>
                            <SubsectionHeader borderColor={'--orange'}>
                                work type
                            </SubsectionHeader>
                            <InfoBox>
                                Select each type of work you would consider.
                                <Required highlightColor={'--orange'}> *required</Required>
                            </InfoBox>
                            <MultipleOptionInput
                                accentColor={'--orange'}
                                label={'work type'}
                                val={workType}
                                options={['fullTime', 'partTime', 'volunteer']}
                                onChange={setWorkType}
                                error={errors['workType']}
                            />
                        </SectionContainer>
                        <SectionContainer>
                            <SubsectionHeader borderColor={'--navy'}>
                                work place
                            </SubsectionHeader>
                            <InfoBox>
                                What workplace would you accept? Check
                                all that apply.
                                <Required highlightColor={'--orange'}> *required</Required>
                            </InfoBox>
                            <MultipleOptionInput
                                accentColor={'--navy'}
                                label={'workPlace'}
                                val={workPlace}
                                options={['inPerson', 'hybrid', 'remote']}
                                onChange={setWorkPlace}
                                error={errors['workPlace']}
                            />
                        </SectionContainer>
                        {workPlace.includes('inPerson') ||
                            workPlace.includes('hybrid') ? (
                            <SectionContainer>
                                <SubsectionHeader borderColor={'--orange'}>
                                    locations
                                    <LabelError fontSize='0.5em'>{errors['locations']}</LabelError>
                                </SubsectionHeader>
                                <InfoBox>
                                    Where do you want to be located? List all
                                    locations you would consider and a search
                                    radius for each.
                                    <Required highlightColor={'--orange'}> *required</Required>
                                </InfoBox>
                                <PanelInput
                                    accentColor={'--orange'}
                                    isEnum={false}
                                    vals={locations}
                                    discreteRange={300}
                                    onAddPanel={addLocationPanel}
                                    placeHolder={{
                                        name: 'city',
                                    }}
                                    fieldNames={[
                                        'name',
                                        'radius',
                                        'lat',
                                        'lng',
                                    ]}
                                    onDeletePanel={deleteLocationPanel}
                                    onChange={setLocations}
                                    showRadius={true}
                                />
                            </SectionContainer>
                        ) : null}
                        <ButtonContainer>
                            <MedButton>save</MedButton>
                        </ButtonContainer>
                        {exists ?
                            <>
                                <InfoBox>
                                    Post this link where recruiters are finding you.
                                    (LinkedIn, Website, Blog, etc.)
                                </InfoBox>
                                <LinkContainer>
                                    <ShortBasicInput
                                        label={'filtra link'}
                                        placeholder={'link is missing'}
                                        val={`https://filtra.io/filters/${id}`}
                                        readOnly={true}
                                    />
                                    <CopyButton
                                        onClick={(e) => {
                                            e.preventDefault()
                                            navigator.clipboard.writeText(`https://filtra.io/filters/${id}`)
                                        }}
                                    >
                                        <CopyIcon src={CopyIconImage} />
                                    </CopyButton>
                                </LinkContainer>
                            </>
                            : null}
                    </Form>
                </BackgroundPlain>
            )
            }
            {
                showLinkDialog ?
                    <UseLinkDialog closer={setShowLinkDialog} />
                    : null
            }
        </>
    );
};

export default withAuthenticationRequired(FilterConfig, {
    onRedirecting: () => <Loading />,
});
