import React, { useState, useEffect } from 'react';
import {
    SmallButton,
} from '../../GlobalStyle';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from '../Loading';
import { useNavigate, useSearchParams } from 'react-router-dom';
import handleAPIError from '../../api/error';
import api from '../../api';
import { GridContainer, PageButtonsContainer, ResultsContainer } from './SearchResultsTable.styles';
import CheckBoxInput from '../CheckBoxInput';
import SearchResult from './SearchResult';

const SearchResultsTable = () => {
    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0()
    const recruiterId = user.sub.split('|')[1];
    const [searchParams, setSearchParams] = useSearchParams();
    const jobId = searchParams.get('j');
    const companyId = searchParams.get('c');
    const page = parseInt(searchParams.get('p'));
    const includeContacted = searchParams.get('ic') === 'true';
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [results, setResults] = useState({});

    useEffect(() => {
        const getResults = async () => {
            setIsLoading(true);
            try {
                const token = await getAccessTokenSilently();
                const response = await api.get(
                    `/recruiters/search/${recruiterId}/${companyId}/${jobId}?page=${page}&includeContacted=${includeContacted}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setResults(response.data)
            } catch (err) {
                handleAPIError(err);
            }
            setIsLoading(false);
        }
        getResults();
    }, [recruiterId, companyId, jobId, page, includeContacted, getIdTokenClaims, getAccessTokenSilently, navigate, searchParams]);

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <ResultsContainer>
                    <CheckBoxInput
                        label={'Include contacted candidates (*)'}
                        checked={includeContacted}
                        onChange={() => {
                            const currentParams = new URLSearchParams(searchParams);
                            if (includeContacted) {
                                currentParams.set('ic', 'false');
                            } else {
                                currentParams.set('ic', 'true');
                            }
                            setSearchParams(currentParams)
                        }}>
                    </CheckBoxInput>
                    <GridContainer>
                        {results?.people?.map(result => 
                            <SearchResult 
                                key={result.id} 
                                result={result} 
                                showContactButton={true} 
                                jobId={jobId} 
                                companyId={companyId} 
                            />
                        )}
                    </GridContainer>
                    <PageButtonsContainer>
                        {page > 0 ? 
                            <SmallButton
                                onClick={() => {
                                    const currentParams = new URLSearchParams(searchParams);
                                    currentParams.set('p', `${page - 1}`);
                                    setSearchParams(currentParams)
                                }}  
                            >back</SmallButton>
                        : null}
                        {results?.people?.length >= 10 ?
                            <SmallButton
                                onClick={() => {
                                    const currentParams = new URLSearchParams(searchParams);
                                    currentParams.set('p', `${page + 1}`);
                                    setSearchParams(currentParams)
                                }}  
                            >next</SmallButton>
                        : null}
                    </PageButtonsContainer>
                </ResultsContainer>
            )}
        </>
    );
};

export default SearchResultsTable
