import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import handleAPIError from '../../api/error';
import Loading from '../Loading';
import { BackgroundPlain, Form, ButtonContainer, MedButton } from '../../GlobalStyle';
import RecruiterHeader from '../RecruiterHeader';
import { LogoImageContainer, LogoImage } from './CompanyProfile.styles';
import ShortBasicInput from '../ShortBasicInput';
import PhotoDialog from '../PhotoDialog';
import CharLimitInput from '../CharLimitInput';
import api from '../../api';

const CompanyProfile = () => {
    const navigate = useNavigate()
    const id = useParams()['*']
    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const recruiterId = user.sub.split('|')[1];

    const PLACEHOLDER_IMAGE_URL = 'https://storage.googleapis.com/filtra-photos-prod/placeholder_image.png'
    const [isLoading, setIsLoading] = useState(false);
    const [showPhotoDialog, setShowPhotoDialog] = useState(false)
    const [exists, setExists] = useState(true);
    const [photoUrl, setPhotoUrl] = useState(PLACEHOLDER_IMAGE_URL);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [changedPhoto, setChangedPhoto] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const getCompany = async () => {
            setIsLoading(true);
            try {
                const claims = await getIdTokenClaims()
                if (!claims['https://filtra.io/roles'].includes('Recruiter')) {
                    navigate('/profile') 
                } else {
                    const token = await getAccessTokenSilently();
                    const response = await api.get(`/recruiters/${recruiterId}/companies/${id}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    setPhotoUrl(response.data.photoUrl);
                    setName(response.data.name);
                    setDescription(response.data.description);
                }
            } catch (err) {
                if (err.response.data.status === 404) {
                    setExists(false);
                } else {
                    handleAPIError(err);
                }
            }
            setIsLoading(false);
        }
        getCompany();
    }, [id, recruiterId, getAccessTokenSilently, getIdTokenClaims, navigate]);

    useEffect(() => {
        // do nothing - just updates the photo without reloading config (which is outdated url)
        // wait for the user to save, if they don't the photo won't be updated (as expected)
    }, [changedPhoto]);

    const postCompany = async (body) => {
        setIsLoading(true);
        setErrors({});
        try {
            const token = await getAccessTokenSilently()
            await api.post(`/recruiters/${recruiterId}/companies`, body, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            setExists(true)
            navigate('/recruiters/companies')
        } catch (err) {
            const errorMsgs = handleAPIError(err);
            if (errorMsgs) setErrors(errorMsgs);
        }
        setIsLoading(false);
    };

    const patchCompany = async (body) => {
        setIsLoading(true);
        setErrors({});
        try {
            const token = await getAccessTokenSilently()
            await api.patch(`/recruiters/${recruiterId}/companies/${id}`, body, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        } catch (err) {
            const errorMsgs = handleAPIError(err);
            if (errorMsgs) setErrors(errorMsgs);
        }
        setIsLoading(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const company = { id, name, description, photoUrl };
        if (exists) {
            patchCompany(company);
        } else {
            postCompany(company);
        }
    };
    
    const updatePhotoUrl = (url) => {
        setPhotoUrl(url);
        setChangedPhoto(!changedPhoto);
        if (exists) {
            const company = { id, name, description, photoUrl: url };
            patchCompany(company)
        }
    };

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <BackgroundPlain>
                    <RecruiterHeader userExists={exists}/>
                    <Form onSubmit={handleSubmit}>
                        <LogoImageContainer
                            onClick={() => setShowPhotoDialog(true)} 
                        >
                            <LogoImage src={photoUrl} key={photoUrl}/>
                        </LogoImageContainer>
                        <ShortBasicInput
                            borderColor={'--orange'}
                            label={'name'}
                            placeholder={'the company\'s name'}
                            val={name}
                            onChange={setName}
                            error={errors['name']}
                        />
                        <CharLimitInput 
                            borderColor={'--orange'} 
                            label={'description'}
                            placeholder={'describe how the product makes this company exciting'}
                            val={description}
                            onChange={setDescription}
                            charLimit={128}
                            error={errors['description']}
                        />
                        <ButtonContainer>
                            <MedButton>save</MedButton>
                        </ButtonContainer>
                    </Form>
                </BackgroundPlain>
            )}
            {showPhotoDialog ? 
                <PhotoDialog 
                    closer={setShowPhotoDialog}
                    setPhotoUrl={updatePhotoUrl}
                    identifier={id}
                    placeholderPhotoUrl={PLACEHOLDER_IMAGE_URL}
                    photoUrl={photoUrl}
                    isCompany={true}
                    exists={exists}
                />
            : null}
        </>
    );
};

export default withAuthenticationRequired(CompanyProfile, {
    onRedirecting: () => <Loading />,
});
