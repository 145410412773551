import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    @media (min-width: 1000px) {
        width: 60%;
    }
    margin: 0 0 0.75rem 0;
`

export const RowContainer = styled.div`
    width: 100%;
`

export const BarContainer = styled.div`
    margin: 0 0 1.25rem 0;
    width: 100%;
    progress[value] {
        width: 100%;
        appearance: none;
        ::-webkit-progress-bar {
            background-color: transparent;
        }
        ::-webkit-progress-value {
            background-color: var(--orange);
        }
        ::-moz-progress-bar {
            background-color: var(--orange);
        }
    }
`

export const LabelContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 0.25rem 0;
    color: var(--black);
    font-family: 'Noto Sans Mono', monospace; 
    font-weight: 500;
    font-size: 1em;
`

export const BarLabel = styled.label``

export const BarLabelValue = styled.span``