import { Table, TableRow, TableBody, TableData } from "./TableChart.styles.js"

const TableChart = ({data}) => {
    return (
        <Table>
            <TableBody>
                {data.map((item, index) => (
                    <TableRow>
                        <TableData>{ item[0] }</TableData>
                        <TableData>{ item[1] }</TableData>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

export default TableChart;